/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-undef */
import PropTypes from 'prop-types';

import DownArrowComponent from 'Component/DownArrow';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import { ChildrenType, MixType, ModsType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';

import './ExpandableContent.override.style';
/** @namespace myApp/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        isContentExpanded: PropTypes.bool,
        isArrow: PropTypes.bool,
        heading: PropTypes.string,
        isIcon: PropTypes.string,
        children: ChildrenType,
        mix: MixType.isRequired,
        mods: ModsType,
        device: DeviceType.isRequired,
        onClick: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'function') {
                return;
            }

            throw new Error(`${componentName} only accepts null or string`);
        }
    };

    static defaultProps = {
        isIcon: '',
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: false,
        mods: {}
    };

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <DownArrowComponent />;
        }

        return <DownArrowComponent />;
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading,
            mix,
            isIcon,
            device: { isMobile }
        } = this.props;

        // const icon = isMobile ? isIcon : null;

        const icon = isIcon && isMobile ? (
                <div block="icon" mods={ { isMobile } }>
                    <Image src={ isIcon } />
                </div>
        ) : (
            ''
        );

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              isIcon={ icon }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <div block="ExpandableContent" elem="Heading" mix={ { ...mix, elem: 'ExpandableContentHeading' } }>
                    { /* <div block="icon" mods={ { isMobile } }> */ }
                    { icon }
                    { /* </div> */ }
                    { typeof heading === 'string' ? <TextPlaceholder content={ heading } length="medium" /> : heading }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }
}

export default ExpandableContentComponent;
