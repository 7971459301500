/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import TextPlaceholder from 'Component/TextPlaceholder';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import OrderDispatcher from 'Store/Order/Order.dispatcher';
import { OrdersListType } from 'Type/Order.type';
import { LocationType } from 'Type/Router.type';
import { getQueryParam } from 'Util/Url';

// import { ReturnDispatcher } from '../../store/Return';
import { MY_RETURN, NEW_RETURN, RETURN_DETAILS } from '../../util/Rma/Rma';
import getActivePage from '../../util/Url/ReturnUrl';
import MyAccountNewReturn from '../MyAccountNewReturn';
import MyAccountReturnDetails from '../MyAccountReturnDetails';
import MyAccountMyReturns from './MyAccountMyReturns.component';
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const HEADER_MY_RETURN = 'my-returns';

export const ReturnDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../../../rma/src/store/Return/Return.dispatcher'
);

/** @namespace Rma/Component/MyAccountMyReturns/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    orderList: state.OrderReducer.orderList,
    areOrdersLoading: state.OrderReducer.isLoading,
    returnList: state.ReturnReducer.returnList,
    pageInfo: state.ReturnReducer.pageInfo,
    areReturnsLoading: state.ReturnReducer.isLoading
});

/** @namespace Rma/Component/MyAccountMyReturns/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getOrderList: () => OrderDispatcher.requestOrders(dispatch),
    getReturnList: (page) => ReturnDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestReturns(dispatch, page)
    ),
    setPageInfo: () => ReturnDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestReturns(dispatch)
    ),
    // getReturnList: () => ReturnDispatcher.requestReturns(dispatch),
    // setPageInfo: () => ReturnDispatcher.requestReturns(dispatch),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Rma/Component/MyAccountMyReturns/Container */
export class MyAccountMyReturnsContainer extends PureComponent {
    static propTypes = {
        orderList: OrdersListType.isRequired,
        getOrderList: PropTypes.func.isRequired,
        returnList: PropTypes.array.isRequired,
        pageInfo: PropTypes.array.isRequired,
        getReturnList: PropTypes.func.isRequired,
        setPageInfo: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        location: LocationType.isRequired
    };

    state = {
        activePage: this.getActivePage()
    };

    renderMap = {
        [MY_RETURN]: {
            component: MyAccountMyReturns,
            title: __('Returns'),
            icon: <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.16128 9.63808C7.05608 9.79586 6.99996 9.98125 7 10.1709V10.6C7 10.6636 7.02529 10.7247 7.07029 10.7697C7.1153 10.8147 7.17635 10.84 7.24 10.84H13.72V7H9.4336C9.27558 6.99998 9.12002 7.03896 8.98069 7.11348C8.84136 7.188 8.72258 7.29576 8.63488 7.4272L7.16128 9.63808Z" fill="#0B1F3F" />
                  <path d="M15.6396 7V10.84H22.1196C22.1833 10.84 22.2444 10.8147 22.2894 10.7697C22.3344 10.7247 22.3596 10.6636 22.3596 10.6V10.1709C22.3597 9.98125 22.3036 9.79586 22.1984 9.63808L20.7248 7.4272C20.6371 7.29576 20.5183 7.188 20.379 7.11348C20.2396 7.03896 20.0841 6.99998 19.926 7H15.6396Z" fill="#0B1F3F" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M22.12 12.76H7.24C7.17635 12.76 7.1153 12.7853 7.07029 12.8303C7.02529 12.8753 7 12.9364 7 13V20.44C7 20.9492 7.20228 21.4376 7.56236 21.7977C7.92243 22.1577 8.41078 22.36 8.92 22.36H20.44C20.9492 22.36 21.4376 22.1577 21.7976 21.7977C22.1577 21.4376 22.36 20.9492 22.36 20.44V13C22.36 12.9364 22.3347 12.8753 22.2897 12.8303C22.2447 12.7853 22.1836 12.76 22.12 12.76ZM14.368 20.3949L10.84 17.56L14.368 14.7251C14.3962 14.7025 14.4303 14.6884 14.4662 14.6842C14.5021 14.6801 14.5385 14.6863 14.5711 14.7019C14.6037 14.7176 14.6312 14.7421 14.6505 14.7727C14.6697 14.8033 14.68 14.8387 14.68 14.8749V16.3408C14.68 16.3917 14.7002 16.4406 14.7362 16.4766C14.7722 16.5126 14.8211 16.5328 14.872 16.5328H18.04C18.1673 16.5328 18.2894 16.5834 18.3794 16.6734C18.4694 16.7634 18.52 16.8855 18.52 17.0128V18.1082C18.52 18.2355 18.4694 18.3576 18.3794 18.4476C18.2894 18.5376 18.1673 18.5882 18.04 18.5882H14.872C14.7664 18.5882 14.68 18.6746 14.68 18.7802V20.2451C14.68 20.2813 14.6697 20.3167 14.6505 20.3473C14.6312 20.3779 14.6037 20.4025 14.5711 20.4181C14.5385 20.4337 14.5021 20.4399 14.4662 20.4358C14.4303 20.4317 14.3962 20.4175 14.368 20.3949Z" fill="#0B1F3F" />
                  <circle cx="15" cy="15" r="14.5" stroke="#0B1F3F" />
                  </svg>

        },
        [NEW_RETURN]: {
            component: MyAccountNewReturn,
            title: __('Order #')
        },
        [RETURN_DETAILS]: {
            component: MyAccountReturnDetails,
            title: __('Return #')
        }
    };

    containerFunctions = {
        setChosenOrderId: this.setChosenOrderId.bind(this),
        handleReturnClick: this.handleReturnClick.bind(this),
        handleReturnItemClick: this.handleReturnItemClick.bind(this)
    };

    componentDidMount() {
        this.toggleDefaultHeaderTitle(false);

        const { getOrderList, getReturnList, setPageInfo } = this.props;

        getReturnList(this._getPageFromUrl());

        getOrderList();
        // getReturnList();
        setPageInfo();

        this.handleFirstLoadHeaderChange();
    }

    componentDidUpdate(prevProps) {
        const { getReturnList } = this.props;

        const { location: prevLocation } = prevProps;

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (currentPage !== prevPage) {
            getReturnList(this._getPageFromUrl());
        }

        // if (prevActivePage !== activePage) {
        //     this.handlePageChange(prevActivePage);
        // }
    }

    componentWillUnmount() {
        this.toggleDefaultHeaderTitle(true);
    }

    _getPageFromUrl(url) {
        const { location: currentLocation } = this.props;
        const location = url || currentLocation;

        return +(getQueryParam('page', location) || 1);
    }

    toggleDefaultHeaderTitle = (show) => {
        const defaultHeaderTitle = document.getElementsByClassName('MyAccount-Heading');

        if (defaultHeaderTitle.length) {
            defaultHeaderTitle[0].style.display = show ? 'block' : 'none';
        }
    };

    getActivePage() {
        const { history: { location: { pathname } } } = this.props;

        return getActivePage(pathname);
    }

    setChosenOrderId(id) {
        this.chosenOrderId = id;
    }

    handleFirstLoadHeaderChange() {
        const activePage = this.getActivePage();

        if (activePage === NEW_RETURN || activePage === RETURN_DETAILS) {
            setTimeout(() => this.changeHeaderState(), 1);
        }
    }

    handleReturnListReload(prevActivePage, activePage) {
        const { getReturnList, setPageInfo } = this.props;

        if (activePage !== MY_RETURN || prevActivePage === MY_RETURN) {
            return;
        }

        getReturnList();
        setPageInfo();
    }

    handlePageChange(prevActivePage) {
        const activePage = this.getActivePage();

        if (activePage === NEW_RETURN || activePage === RETURN_DETAILS) {
            this.changeHeaderState();
        }

        this.handleReturnListReload(prevActivePage, activePage);
        this.setState({ activePage });
    }

    handleReturnClick(selectedOrderId) {
        const { history } = this.props;

        history.push({ pathname: `/my-returns/new-return/${ selectedOrderId }` });
        this.handlePageChange();
    }

    handleReturnItemClick(entityId) {
        const { history } = this.props;

        history.push({ pathname: `/my-returns/return-details/${ entityId }` });
        this.handlePageChange();
    }

    changeHeaderState() {
        const {
            changeHeaderState,
            history
        } = this.props;

        changeHeaderState({
            name: HEADER_MY_RETURN,
            title: __('Returns'),
            onBackClick: () => history.goBack()
        });
    }

    renderPageTitle = (customValue) => {
        const { activePage } = this.state;

        const { title } = this.renderMap[activePage];

        const id = customValue !== undefined
            ? <TextPlaceholder content={ customValue } length="short" />
            : '';

        return (
            <h2
              block="MyAccount"
              elem="Heading"
            >
                <span>{ title }</span>
                { id }
            </h2>
        );
    };

    render() {
        const { activePage } = this.state;
        const { component: Page } = this.renderMap[activePage];

        return (
            <Page
              { ...this.props }
              { ...this.containerFunctions }
              renderPageTitle={ this.renderPageTitle }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountMyReturnsContainer));
