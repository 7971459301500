import { Field } from 'Util/Query';

/** @namespace Ambab/Webengage/Query/NewsletterEmailExists/Query */
export class NewsletterEmailExistsQuery {
    getNewsLetterEmailCustomerIdQuery(email) {
        const query = new Field('isEmailExist')
            .addArgument('email', 'String!', email)
            .addFieldList([
                'is_email_exist'
            ]);

        return query;
    }
}

export default new NewsletterEmailExistsQuery();
