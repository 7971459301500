/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
// import PropTypes from 'prop-types';

import Loader from 'Component/Loader/Loader.component';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace myApp/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    renderButtonChange() {
        const { isAdding } = this.props;
        if (isAdding) {
            return (
                <Loader isAdding={ isAdding } />
            );
        }

        return (
            <span>{ __('Add to Bag') }</span>
        );
    }

    render() {
        const {
            mix,
            addProductToCart,
            layout,
            isDisabled,
            isAdding

        } = this.props;

        return (
            <button
              onClick={ addProductToCart }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                { this.renderButtonChange() }
            </button>
        );
    }
}

export default AddToCartComponent;
