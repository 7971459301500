/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ClickOutside from 'Component/ClickOutside';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';

import { ESCAPE_KEY } from './Popup.config';

import './Popup.override.style';
/** @namespace myApp/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    hidePopupAndGoBack = () => {
        this.hidePopUp();
    };

    handleClickOutside = () => {
        const { clickOutside } = this.props;
        if (!clickOutside) {
            return;
        }
        this.hidePopupAndGoBack();
    };

    handleKeyDown = (e) => {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopupAndGoBack();
            break;
        default:
            break;
        }
    };

    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        onVisible();
    }

    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside>
                <div block="Popup" elem="mainWrapper">
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        <header block="Popup" elem="Header">
                            { this.renderTitle() }
                            { this.renderCloseButton() }
                        </header>
                        { this.renderNotifications() }
                        { children }
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
