/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { REMOVE_CANCEL_ORDER_ITEM_POPUP_ID } from '../MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.config';

import './CancelOrderPopup.style';

/** @namespace myApp/Component/CancelOrderPopup/Component */
export class CancelOrderPopupComponent extends PureComponent {
    static propTypes = {
        cancelOrderItemConfirm: PropTypes.func.isRequired,
        onCloseOrderClick: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    renderContent() {
        const {
            cancelOrderItemConfirm, onCloseOrderClick
        } = this.props;

        return (
            <div>
                <p>{ __('Are you sure you want to Cancel this order product item ?') }</p>
                <div block="Button" elem="Group">
                    <button
                      block="Button"
                      onClick={ onCloseOrderClick }
                      mods={ { isHollow: true } }
                    >
                        { __('No') }

                    </button>
                    <button
                      block="Button"
                      onClick={ cancelOrderItemConfirm }
                    >
                        { __('Yes') }
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <Popup
              id={ REMOVE_CANCEL_ORDER_ITEM_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'CancelOrderPopup' } }
            >
               <div block="CancelItemRemovePopup" elem="Content">
                <Loader isLoading={ isLoading } />
                   { this.renderContent() }
               </div>
            </Popup>
        );
    }
}

export default CancelOrderPopupComponent;
