import { SIGNED_UP_FOR_NEWSLETTER } from '../config';
import { eventTrack, setAttribute, trackUserLogin } from '../helper/WebEngage/Event';

export const onFormSubmit = async (args, callback, instance) => {
    callback(...args);
    const [, fields] = args;
    const {
        allowGuestSubscribe,
        isSignedIn
    } = instance.props;
    const {
        value: email
    } = fields.find(({ name }) => name === 'newsletterEmail') || {};

    if (!allowGuestSubscribe && !isSignedIn) {
        return;
    }
    trackUserLogin(email);

    eventTrack(SIGNED_UP_FOR_NEWSLETTER, {
        Email: email
    });
    setAttribute('email', email);
};

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit
        }
    }
};
