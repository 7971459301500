import { UPDATE_PARTICIPANT_FORM } from './ParticipantForm.action';

export const initialState = {
    isLoading: false
};

/** @namespace myApp/Store/ParticipantForm/Reducer/ParticipantFormReducer */
export const ParticipantFormReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
    case UPDATE_PARTICIPANT_FORM:
        return { ...state, ...data };
    default:
        return state;
    }
};

export default ParticipantFormReducer;
