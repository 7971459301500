/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader/Loader.component';

import './ApplyGiftCart.style';

/** @namespace GiftCard/Component/ApplyGiftCart/Component */
export class ApplyGiftCartComponent extends PureComponent {
    static propTypes = {
        handleApplyGiftCardSubmit: PropTypes.func.isRequired,
        handleGiftCardStatus: PropTypes.func.isRequired,
        handleRemoveAppliedGiftCard: PropTypes.func.isRequired,
        handleAppliedGiftCardStatus: PropTypes.func.isRequired,
        appliedGiftCard: PropTypes.isRequired,
        giftStatus: PropTypes.isRequired,
        isApplied: PropTypes.isRequired,
        isStatus: PropTypes.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        enteredGiftCardCode: ''
    };

    handleGiftCardCodeChange = this.handleGiftCardCodeChange.bind(this);

    handleActiveFormSubmit = this.handleActiveFormSubmit.bind(this);

    handleGiftStatusFormSubmit = this.handleGiftStatusFormSubmit.bind(this);

    handleRemoveAppliedGiftCardBtn = this.handleRemoveAppliedGiftCardBtn.bind(this);

    componentDidMount() {
        const { handleAppliedGiftCardStatus } = this.props;
        handleAppliedGiftCardStatus();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleGiftCardCodeChange(event, field) {
        const { value = '' } = field;

        this.setState({
            enteredGiftCardCode: value
        });
    }

    renderGidtCartStatus() {
        const { isStatus, isApplied } = this.props;
        if (!isStatus || isApplied) {
            return null;
        }
        const { giftStatus: { mwGiftCardInfo: { status, valid_till, balance: { label } } } } = this.props;
        return (
            <div block="ApplyGiftCart" elem="Status">
                { /* <p>
                    <strong>Status: </strong>
                    { status }
                </p> */ }
                <p>
                    <strong>Current Balance: </strong>
                   { label }
                </p>
                <p>
                    <strong>Valid till: </strong>
                    { valid_till }
                </p>
            </div>
        );
    }

    handleActiveFormSubmit() {
        const { handleApplyGiftCardSubmit } = this.props;
        const { enteredGiftCardCode } = this.state;
        handleApplyGiftCardSubmit(enteredGiftCardCode);
    }

    handleGiftStatusFormSubmit() {
        const { enteredGiftCardCode } = this.state;
        const { handleGiftCardStatus } = this.props;
        handleGiftCardStatus(enteredGiftCardCode);
    }

    handleRemoveAppliedGiftCardBtn() {
        const { handleRemoveAppliedGiftCard, appliedGiftCard: { code } } = this.props;

        handleRemoveAppliedGiftCard(code);
    }

    renderApplyGiftForm() {
        const { enteredGiftCardCode } = this.state;
        const { isApplied } = this.props;

        if (isApplied) {
            return null;
        }

        return (
        <Form
          key="ApplyGiftCard"
        >
            <Field
              type={ FIELD_TYPE.text }
              attr={ {
                  id: 'giftcardApply',
                  name: 'giftcard',
                  placeholder: __('Enter Gift Card'),
                  defaultValue: enteredGiftCardCode
              } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  isRequired: true
              } }
              events={ {
                  onChange: this.handleGiftCardCodeChange
              } }
              addRequiredTag
            />
            <button
              block="Button Giftapply"
              name="Active"
              disabled={ !enteredGiftCardCode }
              onClick={ this.handleActiveFormSubmit }
            >
            { __('Apply') }
            </button>
            <button
              block="Button Giftapply"
              onClick={ this.handleGiftStatusFormSubmit }
              disabled={ !enteredGiftCardCode }
            >
            { __('Check Balance') }
            </button>
        </Form>
        );
    }

    renderAppliedGiftStatus() {
        const { appliedGiftCard: { applied, remaining, code }, isApplied } = this.props;
        if (!isApplied) {
            return null;
        }

        return (
            <div block="ApplyGiftCart" elem="AppliedStatus">
                <p>
                    { `${applied.label} Applied from gift card ${code}.` }
                </p>
                <p>
                    { `Remaining card balance: ${remaining.label}.` }
                    <button
                      block="ApplyGiftCart"
                      elem="Removebtn"
                      onClick={ this.handleRemoveAppliedGiftCardBtn }
                    >
                    ( Remove )
                    </button>
                </p>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="ApplyGiftCart">
                <Loader isLoading={ isLoading } />
                <ExpandableContent
                  heading={ __('Gift Card') }
                  mix={ { block: 'GiftCart', elem: 'Apply' } }
                  isArrow
                >
                 { this.renderApplyGiftForm() }
                 { this.renderAppliedGiftStatus() }
                 { this.renderGidtCartStatus() }
                </ExpandableContent>

            </div>
        );
    }
}

export default ApplyGiftCartComponent;
