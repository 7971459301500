/* eslint-disable no-magic-numbers */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Slider from 'react-slick';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './SlickSlider.style';
import './MyAccountNewReturnItemSelect.style';

/** @namespace Rma/Component/MyAccountNewReturnItemSelect/Component */
export class MyAccountNewReturnItemSelectComponent extends PureComponent {
     static propTypes = {
         items: PropTypes.arrayOf().isRequired,
         onItemChange: PropTypes.func.isRequired,
         reasonData: PropTypes.objectOf().isRequired,
         // eslint-disable-next-line react/boolean-prop-naming
         hasError: PropTypes.bool.isRequired
     };

     state = {
         selectedItems: {},
         size: {},
         returnResolution: null,
         itemId: null,
         selectedColor: null,
         selectedSize: null
     };

     componentDidUpdate() {
         setTimeout(() => {
             { this.handleItemSelect(); }
             // eslint-disable-next-line no-magic-numbers
         }, 2000);
     }

     handleItemSelect = (e, isChecked, id) => {
         const { onItemChange, items: orderItems } = this.props;
         const { selectedItems: items } = this.state;
         // eslint-disable-next-line fp/no-let
         let selectedItems = { ...items };

         if (isChecked) {
             selectedItems = omit(selectedItems, id);
         } else {
             selectedItems = orderItems.reduce((acc, item) => {
                 if (!acc.hasOwnProperty(item.order_item_id)) {
                     acc[item.order_item_id] = {
                         order_item_id: item.order_item_id,
                         isCheckedBox: true,
                         product_sku: item.product_sku,
                         qty_requested: 0,
                         reason_id: null,
                         condition_id: null,
                         resolution_id: this.state.returnResolution
                     };
                 }

                 if (id === item.order_item_id) {
                     acc[item.order_item_id].isCheckedBox = !isChecked;
                 }

                 return acc;
             }, { ...items });
         }

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     };

     handleReasonBlockSelect(blockId, key, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [id]: {
                 ...selectedItem,
                 [key]: blockId
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     handleReasonBlockSelectReason(blockId, options, key, id) {
         // const { onItemChange } = this.props;
         const targetValue = blockId;
         const matchingValue = options.find((item) => item.value === targetValue);
         const currentSelectedColor = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.selected_options[0].optionId);
         const currentSelectedSize = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.selected_options[1].value);

         if (matchingValue.label === 'Product Defective') {
             const { onItemChange } = this.props;
             const {
                 selectedItems: items,
                 selectedItems: { [id]: selectedItem }
             } = this.state;

             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId,
                     exchange_color: currentSelectedColor[0],
                     exchange_size: currentSelectedSize[0]
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }

         if (matchingValue.label === 'Incorrect Color') {
             const { onItemChange } = this.props;
             const {
                 selectedItems: items,
                 selectedItems: { [id]: selectedItem }
             } = this.state;

             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId,
                     exchange_color: null,
                     exchange_size: currentSelectedSize[0]
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }

         if (matchingValue.label === 'Incorrect Size') {
             const { onItemChange } = this.props;
             const {
                 selectedItems: items,
                 selectedItems: { [id]: selectedItem }
             } = this.state;

             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId,
                     exchange_color: currentSelectedColor[0],
                     exchange_size: null
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }

         if (matchingValue.label === 'Incorrect Color & Size') {
             const { onItemChange } = this.props;
             const {
                 selectedItems: items,
                 selectedItems: { [id]: selectedItem }
             } = this.state;

             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId,
                     exchange_color: null,
                     exchange_size: null
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }

         if (matchingValue.label === "Didn't like Product" || matchingValue.label === 'Other') {
             const { onItemChange } = this.props;
             const {
                 selectedItems: items,
                 selectedItems: { [id]: selectedItem }
             } = this.state;

             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }

         //  const {
         //      selectedItems: items,
         //      selectedItems: { [id]: selectedItem }
         //  } = this.state;

         //  const selectedItems = {
         //      ...items,
         //      [id]: {
         //          ...selectedItem,
         //          [key]: blockId
         //      }
         //  };

         //  this.setState({ selectedItems });
         //  onItemChange(selectedItems);

         this.setState({ resasonLabel: matchingValue.label });
     }

     handleQtyChange(qty, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [id]: {
                 ...selectedItem,
                 qty_requested: qty
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     //  handleBankTransferFieldChange(value, key, id) {
     //      const { onItemChange } = this.props;
     //      const {
     //          selectedItems: items,
     //          selectedItems: { [id]: selectedItem }
     //      } = this.state;

     //      const selectedItems = {
     //          ...items,
     //          [id]: {
     //              ...selectedItem,
     //              [key]: value
     //          }
     //      };

     //      this.setState({ selectedItems });
     //      onItemChange(selectedItems);
     //  }

     renderImage({ name, product_image: thumbnailUrl, product_image: small_imageUrl }) {
         return (
             <>
                 <Image
                   src={ thumbnailUrl }
                   mix={ {
                       block: 'ReturnItem',
                       elem: 'Picture'
                   } }
                   ratio="custom"
                   alt={ `Product ${ name } thumbnail.` }
                 />
                 <img
                   style={ { display: 'none' } }
                   alt={ name }
                   src={ small_imageUrl }
                   itemProp="image"
                 />
             </>
         );
     }

     renderSelectedOptionAttribute(selected_options) {
         return selected_options.map((option) => (
             <div block={ option.label } key={ option.label }>
                 <span>
                 { `${ option.label }: ${ option.value }` }
                 </span>
             </div>
         ));
     }

     renderItemDetails(item) {
         const { product_name, selected_options } = item;

         return (
             <div
               block="ReturnItem"
               elem="Details"
             >
                 <p
                   block="ReturnItem"
                   elem="Heading"
                 >
                     { product_name }
                 </p>
                 <div
                   block="ReturnItem"
                   elem="Attributes"
                 >
                     { this.renderSelectedOptionAttribute(selected_options) }
                 </div>
             </div>
         );
     }

     /**
      * Render attributes
      * @param attributes
      * @returns {*}
      */
     renderAttributes(attributes) {
         const configurable_options = ['color', 'size'];

         const configurableAttributes = Object.values(attributes)
             .filter((attr) => configurable_options.includes(attr.attribute_code));

         return configurableAttributes.map((attribute) => attribute.attribute_value && this.renderAttribute(attribute));
     }

     /**
      * Render attribute row
      * @param attribute_value
      * @param label
      * @param attribute_options
      * @returns {null|*}
      */
     renderAttribute({ attribute_value, attribute_label: label, attribute_options }) {
         if (!attribute_value) {
             return null;
         }

         return (
             <div block={ label } key={ label }>
                 <span>
                     { `${ label }: ${ attribute_options.length ? attribute_options[0].label : attribute_value }` }
                 </span>
             </div>
         );
     }

     renderReasonBlockSelect(title, options, key, id) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: item } } = this.state;
         const value = item[key] || '';
         const errorMessage = (hasError && !value) ? __('%s is required', title) : '';

         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="SelectWrapper"
             >

                 <Field
                   id={ title }
                   name={ title }
                   label={ title }
                   type={ FIELD_TYPE.select }
                   attr={ {
                       id: 'order-to-return',
                       name: 'order-to-return',
                       placeholder: __('Choose %s', title.toLowerCase()),
                       value
                   } }
                   mix={ {
                       block: 'MyAccountNewReturnItemSelect',
                       elem: 'SelectInput'
                   } }
                   options={ options }
                   events={ {
                       onChange: (blockId) => this.handleReasonBlockSelect(blockId, key, id)
                   } }
                 />
                 { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
             </div>
         );
     }

     renderReasonBlockSelectReason(title, options, key, id) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: item } } = this.state;
         const value = item[key] || '';
         const errorMessage = (hasError && !value) ? __('%s is required', title) : '';

         return (
            <div
              block="MyAccountNewReturnItemSelect"
              elem="SelectWrapper"
            >

                <Field
                  id={ title }
                  name={ title }
                  label={ title }
                  type={ FIELD_TYPE.select }
                  attr={ {
                      id: 'order-to-return',
                      name: 'order-to-return',
                      placeholder: __('Choose %s', title.toLowerCase()),
                      value
                  } }
                  mix={ {
                      block: 'MyAccountNewReturnItemSelect',
                      elem: 'SelectInput'
                  } }
                  options={ options }
                  events={ {
                      onChange: (blockId) => this.handleReasonBlockSelectReason(blockId, options, key, id)
                  } }
                />
                { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
            </div>
         );
     }

     renderReasonBlockQty(id, orderedQty) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: { qty_requested } } } = this.state;
         const errorMessage = (hasError && !qty_requested) ? __('Choose qty!') : '';

         return (
             <div
               key={ id }
               block="MyAccountNewReturnItemSelect"
               elem="QtyBlockWrapper"
               mods={ { isDisabled: !orderedQty < 0 } }
             >
                     <span>
                         { __('Quantity to Return :') }
                         &nbsp;
                     </span>
                     <div block="Input" elem="Wrapper">
                     <Field
                       id="item_qty"
                       name="item_qty"
                       type={ FIELD_TYPE.number }
                       validateOn={ ['onChange'] }
                       attr={ {
                           defaultValue: qty_requested,
                           min: 0,
                           max: orderedQty
                       } }
                       validationRule={ {
                           inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                           isRequired: true,
                           range: {
                               min: 0,
                               max: orderedQty
                           }
                       } }
                       events={ {
                           onChange: (qty) => this.handleQtyChange(qty, id)
                       } }
                     />
                     <span block="TotalQty">{ ` / ${ orderedQty }` }</span>
                     </div>
                     { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
             </div>
         );
     }

     renderReasonBlockRules() {
         return (
             <span
               block="MyAccountNewReturnItemSelect"
               elem="ReasonBlockRuleTitle"
             >
                 { __('The return for this product can’t be processed.') }
             </span>
         );
     }

     renderReasonBlockInputs(id, qty_available_to_return) {
         const { reasonData: { reasons, conditions } } = this.props;
         const { returnResolution } = this.state;

         const conditions1 = [];
         const reasons1 = [];

         const isActiveConditions = conditions.map((elem) => {
             if (elem.is_active) {
                 conditions1.push(elem);
             }
         });

         if (returnResolution === 1) {
             const isActiveReasons = reasons.map((elem) => {
                 if (elem.is_active && elem.is_refund_exchange) {
                     reasons1.push(elem);
                 }
             });
         } else {
             const isActiveReasons = reasons.map((elem) => {
                 if (elem.is_active && !elem.is_refund_exchange) {
                     reasons1.push(elem);
                 }
             });
         }

         return (
             <div key={ id }>
                 { this.renderReasonBlockQty(id, qty_available_to_return) }
                 { this.renderReasonBlockSelectReason(__('Return Reason'), reasons1, 'reason_id', id) }
                 { this.renderReasonBlockSelect(__('Item Condition'), conditions1, 'condition_id', id) }
                 { /* { this.isDisplayBankTransferBlock(id) && this.renderBankTransferBlock(id) } */ }
                 { this.isDisplayExchangeBlock(id) }
             </div>
         );
     }

     isReturnResolutionBlock(title, options, key, id) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: item } } = this.state;
         const value = item[key] || '';
         const errorMessage = (hasError && !value) ? __('%s is required', title) : '';

         return (
            <div
              block="MyAccountNewReturnItemSelect"
              elem="SelectWrapper"
            >
                <Field
                  id={ title }
                  name={ title }
                  label={ title }
                  type={ FIELD_TYPE.select }
                  attr={ {
                      id: 'order-to-return',
                      name: 'order-to-return',
                      placeholder: __('Choose %s', title.toLowerCase()),
                      value
                  } }
                  mix={ {
                      block: 'MyAccountNewReturnItemSelect',
                      elem: 'SelectInput'
                  } }
                  options={ options }
                  events={ {
                      onChange: (blockId) => this.handleResolutionBlockSelect(blockId, key, id)
                  } }
                />
                { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
            </div>
         );
     }

     handleResolutionBlockSelect(blockId, key, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         if (blockId === 1) {
             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId,
                     exchange_color: null,
                     exchange_size: null
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         } else {
             const selectedItems = {
                 ...items,
                 [id]: {
                     ...selectedItem,
                     [key]: blockId
                 }
             };

             this.setState({ selectedItems });
             onItemChange(selectedItems);
         }
     }

     handleColorClick(color, val, id) {
         this.setState({ selectedColor: val });
         const { itemId } = this.state;

         const exchangeSizeData = color.filter((item) => item.exchange_id === val).map((item) => item.sizes);
         this.setState({
             size: exchangeSizeData[0].length > 0 ? exchangeSizeData[0] : {}
         });

         const exchangeImage = color.filter((item) => item.exchange_id === val).map((item) => item.exchange_color_media);
         this.setState({
             image: exchangeImage[0].length > 0 ? exchangeImage[0] : {}
         });

         const exchangeSelectedColorData = color.filter((item) => item.exchange_id === val);

         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [itemId]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [itemId]: {
                 ...selectedItem,
                 exchange_color: exchangeSelectedColorData[0].exchange_id
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     handleSizeClick(val, id) {
         this.setState({ selectedSize: id });
         const { itemId } = this.state;

         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [itemId]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [itemId]: {
                 ...selectedItem,
                 exchange_size: id
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     isDisplayExchangeBlock(id) {
         const { returnResolution } = this.state;
         const { resasonLabel } = this.state;
         if (returnResolution === 2) {
             return null;
         }

         if (resasonLabel === 'Incorrect Color') {
             const { selectedItems: { [id]: { exchange_color } } } = this.state;
             const { hasError } = this.props;
             const colorErrorMessage = (hasError && !exchange_color) ? __('Exchange Color is required') : '';
             const exchangeData = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.exchange_details);
             const selectColorOptions = [];
             const exchangeColorData = exchangeData[0].map((item) => {
                 selectColorOptions.push({
                     label: item.exchange_lable, id: item.exchange_id, value: item.exchange_id, type: item.exchange_type, swatch_value: item.exchange_label_value
                 });

                 return item;
             });
             const { selectedColor } = this.state;

             return (
                 <>
                <div
                  block="MyAccountNewReturnItemSelect"
                  elem="ColorSizeSwatchContainer"
                >
                <h4>Exchange Color</h4>
                <div
                  block="MyAccountNewReturnItemSelect"
                  elem="Swatchwrapper"
                >

                        { selectColorOptions.map((color) => (
                            <div
                              key={ color.id }
                              className={ `color-swatch ${color.type === 3 ? 'square' : ''} ${selectedColor === color.id ? 'selected' : ''}` }
                              onClick={ (e) => this.handleColorClick(exchangeColorData, color.id, e) }
                            >
                                { color.type === 3 && (
                                    <img src={ color.swatch_value } title={ color.label } />
                                ) }

                                { color.type === 2 && (
                                    <img src={ color.swatch_value } title={ color.label } />
                                ) }

                                { color.type === 1 && (
                                    <span title={ color.label } style={ { backgroundColor: color.swatch_value } } />
                                ) }
                            </div>
                        )) }
                </div>
                </div>
                  { colorErrorMessage && <p className="Field-Message">{ colorErrorMessage }</p> }
                 </>
             );
         }

         if (resasonLabel === 'Incorrect Size') {
             const { selectedItems: { [id]: { exchange_size } } } = this.state;
             const exchangeData = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.exchange_details);
             const currentColor = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.selected_options[0].optionId);
             const sizeObject = exchangeData[0].find((size) => size.exchange_id === currentColor[0]);

             const { hasError } = this.props;
             const sizeErrorMessage = (hasError && !exchange_size) ? __('Exchange Size is required') : '';

             const selectSizeOptions = [];

             const { selectedSize } = this.state;

             if (!sizeObject) {
                 return (
                    <div
                      block="MyAccountNewReturnItemSelect"
                      elem="ColorSizeSwatchContainer"
                    >
                    <h4>Exchange Size Unavailable: Kindly Select an Incorrect Size and Color Option for an Exchange with Different Color and Size.</h4>
                    </div>
                 );
             } else {
                 const exchangeSizeData = sizeObject.sizes.map((item) => {
                     selectSizeOptions.push({
                         label: item.exchange_lable, id: item.exchange_id, value: item.exchange_id, type: item.exchange_type, swatch_value: item.exchange_label_value
                     });

                     return item;
                 });

                 return (
                     <>
                        <div
                          block="MyAccountNewReturnItemSelect"
                          elem="ColorSizeSwatchContainer"
                        >
                            <h4>Exchange Size</h4>
                            <div
                              block="MyAccountNewReturnItemSelect"
                              elem="Swatchwrapper"
                            >

                            { selectSizeOptions.map((size) => (
                                <div
                                  key={ size.id }
                                  className={ `size-swatch ${selectedSize === size.id ? 'selected' : ''}` }
                                  onClick={ (e) => this.handleSizeClick(exchangeSizeData, size.id, e) }
                                >
                                    { size.label }
                                </div>
                            )) }
                            </div>
                        </div>
                        { sizeErrorMessage && <p className="Field-Message">{ sizeErrorMessage }</p> }
                     </>
                 );
             }
         }

         if (resasonLabel === 'Incorrect Color & Size') {
             const { selectedItems: { [id]: { exchange_color, exchange_size } } } = this.state;
             const { hasError } = this.props;
             const colorErrorMessage = (hasError && !exchange_color) ? __('Exchange Color is required') : '';
             const sizeErrorMessage = (hasError && !exchange_size) ? __('Exchange Size is required') : '';
             const exchangeData = this.props.items.filter((item) => item.order_item_id === id).map((item) => item.exchange_details);
             const selectColorOptions = [];
             const exchangeColorData = exchangeData[0].map((item) => {
                 selectColorOptions.push({
                     label: item.exchange_lable, id: item.exchange_id, value: item.exchange_id, type: item.exchange_type, swatch_value: item.exchange_label_value
                 });

                 return item;
             });
             const exchangeSizeData = this.state.size;
             const selectSizeOptions = [];
             const { selectedColor, selectedSize } = this.state;
             if (exchangeSizeData.length > 0) {
                 exchangeSizeData.forEach((item) => {
                     selectSizeOptions.push({ label: item.exchange_lable, id: item.exchange_id, value: item.exchange_id });
                 });
             }

             return (
                 <>
                 <div
                   block="MyAccountNewReturnItemSelect"
                   elem="ColorSizeSwatchContainer"
                 >
                 <h4>Exchange Color</h4>
                 <div
                   block="MyAccountNewReturnItemSelect"
                   elem="Swatchwrapper"
                 >

                         { selectColorOptions.map((color) => (
                             <div
                               key={ color.id }
                               className={ `color-swatch ${color.type === 3 ? 'square' : ''} ${selectedColor === color.id ? 'selected' : ''}` }
                               onClick={ (e) => this.handleColorClick(exchangeColorData, color.id, e) }
                             >
                                { color.type === 3 && (
                                    <img src={ color.swatch_value } title={ color.label } />
                                ) }

                                 { color.type === 2 && (
                                     <img src={ color.swatch_value } title={ color.label } />
                                 ) }

                                 { color.type === 1 && (
                                     <span title={ color.label } style={ { backgroundColor: color.swatch_value } } />
                                 ) }
                             </div>
                         )) }
                 </div>
                 </div>
                   { colorErrorMessage && <p className="Field-Message">{ colorErrorMessage }</p> }

                    { selectSizeOptions.length > 0
                    && (
                        <>
                        <div
                          block="MyAccountNewReturnItemSelect"
                          elem="ColorSizeSwatchContainer"
                        >
                         <h4>Exchange Size</h4>
                         <div
                           block="MyAccountNewReturnItemSelect"
                           elem="Swatchwrapper"
                         >
                         { selectSizeOptions.map((size) => (
                             <div
                               key={ size.id }
                               className={ `size-swatch ${selectedSize === size.id ? 'selected' : ''}` }
                               onClick={ (e) => this.handleSizeClick(exchangeColorData, size.id, e) }
                             >
                                 { size.label }
                             </div>
                         )) }
                         </div>
                        </div>
                        { sizeErrorMessage && <p className="Field-Message">{ sizeErrorMessage }</p> }
                        </>
                    ) }

                 </>
             );
         }

         return null;

         //  return (
         //      <>
         //         <div
         //           block="MyAccountNewReturnItemSelect"
         //           elem="ColorSizeSwatchContainer"
         //         >
         //         <h4>Exchange Color</h4>
         //         <div
         //           block="MyAccountNewReturnItemSelect"
         //           elem="Swatchwrapper"
         //         >

         //             { selectColorOptions.map((color) => (
         //                 <div
         //                   key={ color.id }
         //                   className={ `color-swatch ${selectedColor === color.id ? 'selected' : ''}` }
         //                   onClick={ (e) => this.handleColorClick(exchangeColorData, color.id, e) }
         //                 >
         //                     { color.type === 2 && (
         //                         <img src={ color.swatch_value } title={ color.label } />
         //                     ) }

         //                     { color.type === 1 && (
         //                         <span title={ color.label } style={ { backgroundColor: color.swatch_value } } />
         //                     ) }
         //                 </div>
         //             )) }
         //         </div>
         //         </div>
         //       { colorErrorMessage && <p className="Field-Message">{ colorErrorMessage }</p> }

         //        { selectSizeOptions.length > 0
         //        && (
         //            <>
         //            <div
         //              block="MyAccountNewReturnItemSelect"
         //              elem="ColorSizeSwatchContainer"
         //            >
         //             <h4>Exchange Size</h4>
         //             <div
         //               block="MyAccountNewReturnItemSelect"
         //               elem="Swatchwrapper"
         //             >
         //             { selectSizeOptions.map((size) => (
         //                 <div
         //                   key={ size.id }
         //                   className={ `size-swatch ${selectedSize === size.id ? 'selected' : ''}` }
         //                   onClick={ (e) => this.handleSizeClick(exchangeColorData, size.id, e) }
         //                 >
         //                     { size.label }
         //                 </div>
         //             )) }
         //             </div>
         //            </div>
         //            { sizeErrorMessage && <p className="Field-Message">{ sizeErrorMessage }</p> }
         //            </>
         //        ) }

         //      </>
         //  );
     }

     imageGallery() {
         const { image, resasonLabel } = this.state;

         const settings = {
             dots: false,
             arrows: true,
             infinite: true,
             speed: 500,
             slidesToShow: 1,
             slidesToScroll: 1
         };

         if (resasonLabel === 'Product Defective') {
             return null;
         }

         if (resasonLabel === 'Incorrect Size') {
             return null;
         }

         return (
             <>
               { typeof image !== 'undefined'
               && (
                   <div
                     block="MyAccountNewReturnItemSelect"
                     elem="ImageGallery"
                   >
                       <Slider { ...settings }>
                           { image.map((items) => (
                               <Image src={ items.file } />
                           )) }
                       </Slider>

                   </div>
               ) }
             </>
         );
     }

     renderReasonBlock(item, id, isChecked, isDisabled) {
         const { id: itemId, qty_available_to_return } = item;

         if (!isChecked && !isDisabled) {
             return null;
         }

         return (
             <>
                <div
                  key={ itemId }
                  block="MyAccountNewReturnItemSelect"
                  elem="ReasonBlockWrapper"
                  mods={ { isRulesBlock: isDisabled } }
                >
                { isDisabled
                    ? this.renderReasonBlockRules()
                    : this.renderReasonBlockInputs(id, qty_available_to_return, item) }
                </div>
                { this.imageGallery() }
             </>
         );
     }

     renderItemField(item, id, isChecked, isDisabled) {
         return (
            <div
              key={ id }
              block="MyAccountNewReturnItemSelect"
              elem="ItemInnerWrapper"
            >
                <figure block="ReturnItem" elem="Wrapper">
                    { this.renderImage(item) }
                    { this.renderItemDetails(item) }
                </figure>
                { item.can_return ? this.renderItemCheckbox(id, isChecked, isDisabled) : null }
            </div>
         );
     }

     renderItemCheckbox(id, isChecked, isDisabled) {
         if (isDisabled) {
             return null;
         }

         return (
             <Field
               id={ `${ id }` }
               name={ `${ id }` }
               type={ FIELD_TYPE.checkbox }
               mix={ {
                   block: 'MyAccountNewReturnItemSelect',
                   elem: 'ItemField',
                   mods: { isDisabled }
               } }
               attr={ {
                   value: id,
                   checked: isChecked
               } }
               isDisabled={ isDisabled }
               events={ {
                   onChange: (e) => this.handleItemSelect(e, isChecked, id, isDisabled)
               } }
             />
         );
     }

     renderItem = (item, index) => {
         const { selectedItems } = this.state;
         const { order_item_id, qty_available_to_return } = item;

         const id = parseInt(order_item_id, 10);
         const isChecked = !!selectedItems[id];
         const isDisabled = qty_available_to_return === 0;

         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="ItemWrapper"
               key={ index }
             >
                 { this.renderItemField(item, id, isChecked, isDisabled) }
                 { this.renderReasonBlock(item, id, isChecked, isDisabled) }
             </div>
         );
     };

     renderItems() {
         const { items } = this.props;

         if (!items.length) {
             return (
                 <>
                     <TextPlaceholder
                       mix={ {
                           block: 'MyAccountNewReturnItemSelect',
                           elem: 'ItemImagePlaceholder'
                       } }
                     />
                     <TextPlaceholder />
                 </>
             );
         }

         return (
             <div>
                 { items.map((item, index) => this.renderItem(item, index)) }
             </div>
         );
     }

     renderTitle() {
         return (
             <h4
               block="MyAccountNewReturnItemSelect"
               elem="Title"
             >
                 { __('Choose Products From Your Order to Return And Exchange') }
             </h4>
         );
     }

     render() {
         this.setState({
             itemId: history.state.state.itemid,
             returnResolution: history.state.state.returnresolution
         });

         return (
             <div block="MyAccountNewReturnItemSelect">
                 { this.renderTitle() }
                 { this.renderItems() }
             </div>
         );
     }
}

export default MyAccountNewReturnItemSelectComponent;
