import randomstring from 'randomstring';

/** @namespace PageBuilder/Helper/Functions/escapeHtml */
export const escapeHtml = (unsafe) => unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

/** @namespace PageBuilder/Helper/Functions/isNotEmptyArr */
export const isNotEmptyArr = (arr) => Array.isArray(arr) && arr.length;
/** @namespace PageBuilder/Helper/Functions/isEmptyArr */
export const isEmptyArr = (arr) => !isNotEmptyArr(arr);

/** @namespace PageBuilder/Helper/Functions/makeId */
export const makeId = (length) => randomstring.generate({ length, charset: 'alphanumeric' });

/** @namespace PageBuilder/Helper/Functions/isString */
export const isString = (item) => typeof item === 'string' || item instanceof String;
