/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, fetchQuery } from 'Util/Request';

import CustomerRmaQuery from '../../query/CustomerRma.query';
import ProductReturnQuery from '../../query/ProductReturn.query';
import { getReturnList, setLoading, setPageInfo } from './Return.action';

/** @namespace Rma/Store/Return/Dispatcher */
export class ReturnDispatcher {
    /**
     * Request returns
     * @param dispatch
     * @param currentPage
     * @param pageSize
     * @returns {*}
     */

    requestReturns = (dispatch, currentPage = 1, pageSize = 5) => {
        const query = CustomerRmaQuery.getRmaList(currentPage, pageSize);
        dispatch(setLoading(true));

        return fetchQuery(query).then(
            /** @namespace Rma/Store/Return/Dispatcher/ReturnDispatcher/fetchQuery/then */
            ({ customerRmas }) => {
                customerRmas.items.reverse();
                dispatch(setLoading(false));

                // Dispatch the return list and total count
                dispatch(getReturnList({
                    items: customerRmas.items,
                    total_count: customerRmas.total_count
                }));

                // Calculate total_pages from total_count and page_size
                const total_pages = Math.ceil(customerRmas.total_count / pageSize);

                dispatch(setPageInfo({
                    total_pages,
                    current_page: currentPage,
                    page_size: pageSize
                }));
            },
            /** @namespace Rma/Store/Return/Dispatcher/ReturnDispatcher/fetchQuery/then/catch */
            (error) => {
                dispatch(setLoading(false));
                dispatch(showNotification('error', error[0].message));
            }
        );
    };

    /**
     * Send comment
     * @param request_id
     * @param comment
     * @returns {*}
     */
    sendComment(request_id, comment = '') {
        const mutation = ProductReturnQuery.sendRmaComment({ request_id, comment });

        return fetchMutation(mutation);
    }
}

export default new ReturnDispatcher();
