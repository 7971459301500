import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

import ProductLinks from './ProductLinks.component';
/** @namespace myApp/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/ProductLinks/Container */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static propTypes = {
        ...this.propTypes,
        isMobileOnethird: PropTypes.bool,
        numberOfProductsToDisplayInMobile: PropTypes.number
    };

    static defaultProps = {
        numberOfProductsToDisplay: 4,
        areDetailsLoaded: true,
        isMobileOnethird: false,
        numberOfProductsToDisplayInMobile: ''

    };

    containerProps() {
        const { isMobile, isMobileOnethird, numberOfProductsToDisplayInMobile } = this.props;

        return {
            ...super.containerProps(),
            isMobileOnethird,
            isMobile,
            numberOfProductsToDisplayInMobile
        };
    }

    render() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items = [] } = {} }
        } = this.props;

        if (items.length === 0) {
            return null;
        }

        return <ProductLinks { ...this.containerProps() } />;
    }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
