/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.override.style';
/** @namespace myApp/Component/Field/Component */
export class FieldComponent extends SourceField {
    // #region INPUT TYPE RENDER
    renderDefaultInput() {
        const {
            type, setRef, attr, events, isDisabled
        } = this.props;

        if (attr.prepone) {
            return (
                <div className="input-group">
                    <span className="input-prepone">{ attr.prepone }</span>
                    <input
                      ref={ (elem) => setRef(elem) }
                      disabled={ isDisabled }
                      type={ type }
                      { ...attr }
                      { ...events }
                    />
                </div>
            );
        }

        return (
            <input
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              type={ type }
              { ...attr }
              { ...events }
            />
        );
    }
}

export default FieldComponent;
