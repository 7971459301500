import { ORDER_CANCELLED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const getProductDetails = (products) => products.map((product) => ({
    'Product SKU': product.product_sku,
    'Product Name': product.product_name,
    price: product.row_subtotal.value,
    quantity: product.quantity_ordered,
    image: product.product_image,
    Color: product.selected_options[0].label === 'Color' ? product.selected_options[0].value : '',
    size: product.selected_options[1].label === 'Size' ? product.selected_options[1].value : ''
}));

export const getProductsSkuString = (items) => {
    // eslint-disable-next-line fp/no-let
    let sku = '';
    items.forEach((product) => {
        sku = sku.concat(sku.length > 0 ? ', ' : '', product.product_sku);
    });

    return sku;
};

export const cancelOrder = (args, callback, instance) => {
    callback(...args).then(() => {
        const { order: { items = [], increment_id, total: { subtotal: { value } } } } = instance.state;
        eventTrack(ORDER_CANCELLED, {
            'Order ID': increment_id,
            'Order Amount': value,
            'Product SKU': getProductsSkuString(items),
            'Product Details': getProductDetails(items)
        });
    });
};

export default {
    'Component/MyAccountOrder/Container': {
        'member-function': {
            cancelOrder
        }
    }
};
