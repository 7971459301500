import { handleGAEventsTracker } from 'Util/Helper';

/** @namespace Ambab/AddtoCartPopup/Plugin/Product/Container/addToCart */
export const addToCart = async (args, callback, instance) => {
    if (await callback(...args)) {
        const { quantity, parameters } = instance.state;
        const { selectedProduct: selectProduct } = instance.state;
        const {
            product, product: {
                sku,
                name,
                sport,
                product_type_filter,
                product_category,
                collection_name,
                gender,
                price_range: {
                    minimum_price: {
                        final_price: { value: price, currency }
                    }
                }
            }
        } = instance.props;

        const skuProduct = selectProduct ? selectProduct.sku : sku;

        instance.props.showPopup({
            title: `(${quantity}) ${ __('Item Added To Bag') }`,
            selectProduct,
            product,
            quantity,
            parameters
        });

        handleGAEventsTracker('event', 'add_to_cart', {
            currency,
            value: price * quantity,
            items: [{
                item_id: skuProduct,
                item_name: name,
                item_category: collection_name,
                item_category2: sport,
                item_category3: product_category,
                item_category4: product_type_filter,
                item_category5: gender,
                index: 1,
                currency,
                price: price * quantity,
                quantity
            }]
        });
    }
};

export default {
    'Component/Product/Container': {
        'member-function': {
            addToCart
        }
    }
};
