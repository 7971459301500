/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './MyAccountDropdown.style';

/** @namespace Ambab/myAccountDropdown/Component/MyAccountDropdown/Component */
export class MyAccountDropdownComponent extends PureComponent {
    render() {
        const { menuTabMap } = this.props;

        return (
            <>
            <h4>My Account</h4>
            <ul>
               { menuTabMap.map((item) => (item.onClick ? (
                    <li><Link onClick={ item.onClick }>{ item.tabName }</Link></li>
               ) : (
                    <li><Link to={ item.url } onClick={ this.renderItemClick }>{ item.tabName }</Link></li>
               ))) }
            </ul>
            </>
        );
    }
}

export default MyAccountDropdownComponent;
