import { ORDER_SHIPMENTS } from 'Component/MyAccountOrder/MyAccountOrder.config';
import MyAccountOrderItemsTableRow from 'Component/MyAccountOrderItemsTableRow';
import MyAccountOrderTotals from 'Component/MyAccountOrderTotals';
import {
    MyAccountOrderItemsTable as SourceMyAccountOrderItemsTable
} from 'SourceComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.component';
import { getProductFromOrder } from 'Util/Orders';

import './MyAccountOrderItemsTable.override.style';

/** @namespace myApp/Component/MyAccountOrderItemsTable/Component */
export class MyAccountOrderItemsTableComponent extends SourceMyAccountOrderItemsTable {
    renderItemRow(product, i) {
        const { activeTab, allOrderItems, order } = this.props;
        const { product_sku } = product;

        const {
            entered_options = [],
            selected_options = []
        } = getProductFromOrder(allOrderItems, product_sku) || {};

        return (
            <MyAccountOrderItemsTableRow
              product={ product }
              selectedOptions={ selected_options }
              enteredOptions={ entered_options }
              key={ i }
              activeTab={ activeTab }
              order={ order }
            />
        );
    }

    renderTotals() {
        const { total, activeTab, order } = this.props;

        if (activeTab === ORDER_SHIPMENTS) {
            return null;
        }

        return <MyAccountOrderTotals activeTab={ activeTab } total={ total } order={ order } />;
    }

    renderDesktopTable() {
        return (
            <div block="MyAccountOrderItemsTable" elem="ProductsWrapper">
                <div
                  block="MyAccountOrderItemsTable"
                  elem="Products"
                >
                    <ul
                      block="MyAccountOrderItemsTable"
                      elem="TableRows"
                    >
                        { this.renderItems() }
                    </ul>
                    { this.renderTotals() }
                </div>
            </div>
        );
    }

    renderMobileTable() {
        return (
            <div block="MyAccountOrderItemsTable" elem="ProductsWrapper">
                <div
                  block="MyAccountOrderItemsTable"
                  elem="Products"
                >
                    <ul
                      block="MyAccountOrderItemsTable"
                      elem="TableRows"
                    >
                        { this.renderItems() }
                    </ul>
                    { this.renderTotals() }
                </div>
            </div>
        );
    }
}

export default MyAccountOrderItemsTableComponent;
