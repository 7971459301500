import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductSizeChartPopup from 'Component/ProductSizeChartPopup';
import { ProductType } from 'Type/ProductList.type';

import './SizeGuideButton.style';

/** @namespace myApp/Component/SizeGuideButton/Component */
export class SizeGuideButtonComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        showProductSizeChart: PropTypes.func.isRequired,
        isReady: PropTypes.bool.isRequired,
        product: ProductType.isRequired
    };

    onInputSizeChart = (e) => {
        e.preventDefault();
        const { showProductSizeChart } = this.props;

        showProductSizeChart();
    };

    renderSizeGuide() {
        const { product: { size_chart_guide } } = this.props;

        if (!size_chart_guide) {
            return null;
        }

        return <button onClick={ this.onInputSizeChart }>Size & Fit Guide</button>;
    }

    render() {
        const { product, isReady } = this.props;
        return (
            <div block="SizeGuideButton">
                { this.renderSizeGuide() }
                <ProductSizeChartPopup product={ product } isReady={ isReady } />
            </div>
        );
    }
}

export default SizeGuideButtonComponent;
