/* eslint-disable react/no-unused-state */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import PreferencesQuery from 'Query/Preferences.query';
import { fetchQuery } from 'Util/Request';

import MyAccountPreferenceForm from './MyAccountPreferenceForm.component';

/** @namespace myApp/Component/MyAccountPreferenceForm/Container */
export class MyAccountPreferenceFormContainer extends PureComponent {
    static propTypes = {
        handleUpdatePreferences: PropTypes.func.isRequired
    };

    state = {
        shopFors: [],
        youIntos: [],
        customerShopFor: [],
        customerYouInto: [],
        isLoading: true
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        this.getpreferenceFor();
        this.getcustomerPreferenceFor();
    }

    // componentDidUpdate() {
    //     this.getcustomerPreferenceFor();
    // }

    containerProps() {
        const {
            shopFors, youIntos, customerShopFor, customerYouInto, isLoading
        } = this.state;
        const { handleUpdatePreferences } = this.props;

        return {
            shopFors,
            youIntos,
            customerShopFor,
            customerYouInto,
            handleUpdatePreferences,
            isLoading
        };
    }

    getpreferenceFor() {
        fetchQuery(PreferencesQuery.getQuerypreferenceFor())
            .then(
                /** @namespace myApp/Component/MyAccountPreferenceForm/Container/MyAccountPreferenceFormContainer/getpreferenceFor/fetchQuery/then */
                ({ preferenceFor: { shop_for, you_into } }) => {
                    this.setState({ shopFors: shop_for.split(','), youIntos: you_into.split(','), isLoading: false });
                }
            );
    }

    getcustomerPreferenceFor() {
        fetchQuery(PreferencesQuery.getQuerycustomerPreferenceFor())
            .then(
                /** @namespace myApp/Component/MyAccountPreferenceForm/Container/MyAccountPreferenceFormContainer/getcustomerPreferenceFor/fetchQuery/then */
                ({ customerPreferenceFor: { shop_for, you_into } }) => {
                    if (shop_for) {
                        this.setState({
                            customerShopFor: shop_for.split(','),
                            isLoading: false
                        });
                    }
                    if (you_into) {
                        this.setState({
                            customerYouInto: you_into.split(','),
                            isLoading: false
                        });
                    }
                }
            );
    }

    render() {
        return (
            <MyAccountPreferenceForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default MyAccountPreferenceFormContainer;
