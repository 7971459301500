/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, _instance) => Array.from(callback(...args));

// const _getStoreConfigFieldsAction = (args, callback, _instance) => [
//     ...callback(...args),
//     new Field('google_analytics_account'),
//     new Field('google_adwords_account'),
//     new Field('google_adwords_conversion_label')
// ];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: {
                position: 100,
                implementation: _getStoreConfigFieldsAction
            }
        }
    }
};
