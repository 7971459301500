import getStore from 'Util/Store';

import { FILTER_APPLIED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const updateFilter = (args, callback, instance) => {
    callback(...args);
    const [filterName, filterArray] = args;

    if (filterArray.length) {
        setTimeout(() => {
            const { availableFilters } = instance.props;

            const state = getStore().getState();
            const { category: { name } } = state.CategoryReducer;
            const { totalItems } = state.ProductListReducer;

            const filterValue = availableFilters[filterName].attribute_options[filterArray[0]].label;
            eventTrack(FILTER_APPLIED, {
                'Filter Name': filterName,
                'Filter Value': filterValue,
                'Item Count': totalItems,
                'Shopping Page': name
            });
        // eslint-disable-next-line no-magic-numbers
        }, 4000);
    }
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            updateFilter
        }
    }
};
