/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace myApp/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    getOrderItemCancelQuery(order) {
        const { orderId, itemId, qty } = order;

        return new Field('CancelItem')
            .addArgument('orderId', 'Int!', orderId)
            .addArgument('itemId', 'Int!', itemId)
            .addArgument('qty', 'Int!', qty)
            .addFieldList(['message']);
    }

    getOrderCancelQuery(orderNumber) {
        return new Field('cancelOrder')
            .addArgument('orderNumber', 'String!', orderNumber)
            .addFieldList(['message']);
    }

    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            'can_reorder',
            'rss_link',
            this._getOrderItemTotalField(),
            this._getOrderItemsProductsFieldForListing()
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return basicFields;
    }

    _getSingleOrderFields() {
        return [
            'carrier',
            this._getOrderShipmentsField(),
            this._getOrderItemsProductsField(),
            this._getOrderInvoicesField(),
            this._getOrderRefundsField(),
            this._getOrderShippingAddressField(),
            this._getOrderBillingAddressField(),
            this._getOrderPaymentMethodsField(),
            this._getOrderShippingMethodField(),
            this._getOrderCommentsField()
        ];
    }

    _getOrderItemsProductsFieldForListing() {
        return new Field('items')
            .addFieldList(this._getOrderItemProductsFieldsForListing());
    }

    _getOrderItemProductsFieldsForListing() {
        return [
            'product_url_key',
            'product_image',
            'orignal_price',
            'special_price',
            'special_price_flag'
        ];
    }

    _getOrderProductParameters() {
        return new Field('parameters')
            .addFieldList(['attribute_code', 'attribute_value', 'attribute_id']);
    }

    _getExchangeProductParameters() {
        return new Field('exchange_details')
            .addFieldList(['exchange_lable', 'exchange_id', 'exchange_value', 'exchange_type', 'exchange_label_value', this._getExchangeSize(), this._getMedia()]);
    }

    _getExchangeSize() {
        return new Field('sizes').addFieldList(['exchange_lable', 'exchange_id', 'exchange_value']);
    }

    _getMedia() {
        return new Field('exchange_color_media').addFieldList(['file']);
    }

    _getOrderItemProductsFields() {
        return [
            'product_url_key',
            'product_image',
            'product_url',
            'quantity_ordered',
            'quantity_shipped',
            'quantity_refunded',
            'quantity_canceled',
            'qty_available_to_cancel',
            'config_sku',
            'product_type',
            'orignal_price',
            'special_price',
            'special_price_flag',
            // 'exchange_details',
            this._getExchangeProductParameters(),
            this._getOrderProductParameters(),
            this._getOrderProductEnteredOptionsField(),
            this._getOrderProductSelectedOptionsField(),
            this._getOrderProductRowSubtotalField(),
            ...this._getBaseOrderItemProductsFields()
        ];
    }

    _getOrdersField(options) {
        const { orderId, page = 1, pageSize = 5 } = options || {};

        if (orderId) {
            const ordersField = new Field('orders');
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
                .addFieldList(this._getOrdersFields(true));
        }

        if (!orderId) {
            const ordersField = new Field('ordersHistory');
            return ordersField
                .addArgument('currentPage', 'Int', page)
                .addArgument('pageSize', 'Int', pageSize)
                .addFieldList(this._getListOrdersFields());
        }

        return null;
    }

    _getListOrdersFields(isSingleOrder = false) {
        return [
            'total_count',
            this._getListOrderItemsField(isSingleOrder),
            this._getSearchResultPageInfoField()
        ];
    }

    _getListOrderItemsField() {
        return new Field('items')
            .addFieldList(this._getListOrderItemsFields());
    }

    _getListOrderItemsFields() {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            this._getOrderGrandTotalField(),
            this._getListOrderItemsProductsFieldForListing()
        ];

        return basicFields;
    }

    // _getOrderItemTotalFieldList() {
    //     return new Field('grand_total')
    //         .addFieldList(this._getOrderItemTotalFieldsList());
    // }

    // _getOrderItemTotalFieldsList() {
    //     return [
    //         this._getOrderGrandTotalField(),
    //         this._getOrderDiscountsField(),
    //         this._getOrderBaseGrantTotalField(),
    //         this._getOrderSubtotalField(),
    //         this._getOrderTotalShippingField(),
    //         this._getOrderTotalTaxField(),
    //         this._getOrderShippingHandlingField(),
    //         this._getOrderTaxesField()
    //     ];
    // }

    _getListOrderItemsProductsFieldForListing() {
        return new Field('items')
            .addFieldList(this._getListOrderItemProductsFieldsForListing());
    }

    _getListOrderItemProductsFieldsForListing() {
        return [
            'product_image'
        ];
    }

    _getOrdersFields(isSingleOrder = false) {
        return [
            'total_count',
            this._getOrderItemsField(isSingleOrder),
            this._getSearchResultPageInfoField()
        ];
    }

    _getOrderItemsField(isSingleOrder) {
        return new Field('items')
            .addFieldList(this._getOrderItemsFields(isSingleOrder));
    }

    _getOrderPriceFields() {
        return [
            'value',
            'currency'
        ];
    }

    _getOrderProductSelectedOptionsField() {
        return new Field('selected_options')
            .addFieldList(this._getOrderProductOptionsFields());
    }

    _getOrderProductOptionsFields() {
        return [
            'label',
            'value',
            this._getOrderProductBundleOptionItemsField(),
            'linkItems',
            'optionId'
        ];
    }
}

export default new OrderQuery();
