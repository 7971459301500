/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderItemsTableRowContainer as SourceMyAccountOrderItemsTableRowContainer
} from 'SourceComponent/MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { OrderType } from 'Type/Order.type';
import history from 'Util/History';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

import MyAccountOrderItemsTableRow from './MyAccountOrderItemsTableRow.component';
import { REMOVE_CANCEL_ORDER_ITEM_POPUP_ID } from './MyAccountOrderItemsTableRow.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace myApp/Component/MyAccountOrderItemsTableRow/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartId: state.CartReducer.id
    // TODO extend mapStateToPropsH
});

/** @namespace myApp/Component/MyAccountOrderItemsTableRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    showCancelOrderItemRemovePopup: (payload) => dispatch(showPopup(REMOVE_CANCEL_ORDER_ITEM_POPUP_ID, payload)),
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCartForCartPage(dispatch, options)
    )
});

/** @namespace myApp/Component/MyAccountOrderItemsTableRow/Container */
export class MyAccountOrderItemsTableRowContainer extends SourceMyAccountOrderItemsTableRowContainer {
    static propTypes = {
        ...this.propTypes,
        order: OrderType.isRequired,
        fallbackAddToCart: PropTypes.func.isRequired,
        cartId: PropTypes.string

    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        cancelOrderItemPopup: this.cancelOrderItemPopup.bind(this),
        addProductToCart: this.addProductToCart.bind(this)
    };

    cancelOrderItemPopup(payload) {
        const { showCancelOrderItemRemovePopup } = this.props;
        showCancelOrderItemRemovePopup(payload);
    }

    // eslint-disable-next-line max-len
    getParameters = (parameters = []) => parameters.map((parameter) => btoa(`configurable/${parameter.attribute_id}/${parameter.attribute_value}`));

    async addProductToCart() {
        const {
            fallbackAddToCart, product: {
                quantity_ordered: quantity,
                selected_options,
                config_sku,
                parameters,
                product_url
            }, cartId
        } = this.props;

        const product = {
            sku: config_sku,
            selected_options,
            parameters,
            quantity
        };

        const selectedOptions = this.getParameters(parameters);

        this.setState({
            isLoading: true
        });

        const magentoProduct = magentoProductTransform(
            ADD_TO_CART, product, quantity, [], selectedOptions
        );

        try {
            await fallbackAddToCart({
                products: magentoProduct,
                cartId
            });
        } catch (error) {
            history.push({ pathname: appendWithStoreCode(product_url) });
        }

        this.setState({ isLoading: false });
    }

    containerProps() {
        const { order } = this.props;
        const { isLoading } = this.state;

        return {
            ...super.containerProps(),
            order,
            isLoading
        };
    }

    render() {
        return (
            <MyAccountOrderItemsTableRow
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderItemsTableRowContainer);
