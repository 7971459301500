import Popup from 'Component/Popup';

export const CART_ITEM_EDIT_POPUP_ID = 'CartItemEditPopupId';

export class CartItemEditPopupComponentPlugin {
    _aroundRender(_args, _callback, instance) {
        return (
            <div block="CartItemEditPopup">
                <Popup
                  id={ CART_ITEM_EDIT_POPUP_ID }
                  clickOutside={ false }
                  mix={ { block: 'CartItemEditPopup' } }
                >
                <div block="CartItemEditPopup" elem="Content">
                   { instance.renderContent() }
                </div>
                </Popup>
            </div>
        );
    }
}

const { _aroundRender } = new CartItemEditPopupComponentPlugin();

export default {
    'myApp/Component/CartItemEditPopup/Component': {
        'member-function': {
            render: _aroundRender
        }
    }
};
