import NewsletterEmailExistsQuery from '@ambab/webengage/src/query/NewsletterEmailExists.query';

import { isSignedIn } from 'Util/Auth';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchQuery } from 'Util/Request';

import { CONTACT_US, PAGE_VIEWED } from '../config';
import { eventTrack, trackUserLogin } from '../helper/WebEngage/Event';

const handleContactUsSubmit = (args, callback) => {
    callback(...args).then(async () => {
        if (!isSignedIn()) {
            const [, fields] = args;
            const transformFieldsVerify = transformToNameValuePair(fields);
            const result = await fetchQuery(
                NewsletterEmailExistsQuery.getNewsLetterEmailCustomerIdQuery(transformFieldsVerify.email)
            );

            const { isEmailExist: { is_email_exist } } = result;

            if (is_email_exist) {
                trackUserLogin(transformFieldsVerify.email);
            }
        }
        eventTrack(CONTACT_US, {});
    });
};

const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [, prevState] = args;

    if (prevState.activeTab && prevState.activeTab.name !== instance.state.activeTab.name) {
        const { location: { pathname } } = instance.props;

        eventTrack(PAGE_VIEWED, {
            'Page Name': instance.state.activeTab.tabName.value,
            'Page Path': pathname
        });
    }
};

const componentDidMount = (args, callback, instance) => {
    callback(...args);
    setTimeout(() => {
        const { location: { pathname } } = instance.props;

        if (instance.state.activeTab) {
            eventTrack(PAGE_VIEWED, {
                'Page Name': instance.state.activeTab.tabName.value,
                'Page Path': pathname
            });
        }
    // eslint-disable-next-line no-magic-numbers
    }, 3000);
};

export default {
    'CustomerServices/Route/CustomerServicePage/Container': {
        'member-function': {
            handleContactUsSubmit, componentDidUpdate, componentDidMount
        }
    }
};
