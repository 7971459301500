import {
    PaginationLink as SourcePaginationLink
} from 'SourceComponent/PaginationLink/PaginationLink.component';

import './PaginationLink.style';

/** @namespace myApp/Component/PaginationLink/Component */
export class PaginationLinkComponent extends SourcePaginationLink {
    // TODO implement logic
}

export default PaginationLinkComponent;
