/* eslint-disable fp/no-let */

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsDetailsInArray */
export const getCartProductsDetailsInArray = (items) => Object.entries(items).map(([, val]) => {
    const { product, qty, price } = val;

    const [variant] = product.variants;

    return {
        quantity: qty,
        price,
        'Product Name': product.name,
        image: variant
            ? variant.thumbnail.url : product.thumbnail.url,
        'Product SKU': variant ? variant.sku : product.sku
    };
});

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsNameString */
export const getCartProductsNameString = (items) => {
    let productsName = '';
    items.forEach(({ product }) => {
        productsName = productsName.concat(productsName.length > 0 ? ', ' : '', product.name);
    });

    return productsName;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsSkuString */
export const getCartProductsSkuString = (items) => {
    let sku = '';
    items.forEach(({ product }) => {
        sku = sku.concat(sku.length > 0 ? ', ' : '', product.sku);
    });

    return sku;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsImagesArray */
export const getCartProductsImagesArray = (items) => {
    const img = [];
    items.forEach(({ product }) => {
        img.push(product.variants[0]
            ? product.variants[0].thumbnail.url : product.thumbnail.url);
    });

    return img;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsQtysString */
export const getCartProductsQtysString = (items) => {
    let qtys = '';
    items.forEach(({ qty }) => {
        qtys = qtys.concat(qtys.length > 0 ? ', ' : '', qty);
    });

    return qtys;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsColorsString */
export const getCartProductsColorsString = (items) => {
    let colors = '';
    const COLOR = 'color';
    items.forEach(({ product: { attributes, variants } }) => {
        const colorValue = variants[0]?.attributes[COLOR].attribute_value;
        const color = attributes[COLOR]?.attribute_options[colorValue];

        colors = colors.concat(colors.length > 0 ? ', ' : '', color ? color.label : '');
    });

    return colors;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsSizesString */
export const getCartProductsSizesString = (items) => {
    let sizes = '';
    const SIZE = 'size';
    items.forEach(({ product: { attributes, variants } }) => {
        const sizeValue = variants[0]?.attributes[SIZE].attribute_value;
        const size = attributes[SIZE]?.attribute_options[sizeValue];

        sizes = sizes.concat(sizes.length > 0 ? ', ' : '', size ? size.label : '');
    });

    return sizes;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsGenderString */
export const getCartProductsGenderString = (items) => {
    let gender = '';
    items.forEach(({ product }) => {
        gender = gender.concat(gender.length > 0 ? ', ' : '', product.gender);
    });

    return gender;
};
