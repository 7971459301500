/* eslint-disable implicit-arrow-linebreak */

import AddToCartPopup from '../component/AddToCartPopup';

const render = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <AddToCartPopup />
    </>
);

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render
        }
    }
};
