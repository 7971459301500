import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderTotalsContainer as SourceMyAccountOrderTotalsContainer
} from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.container';

import MyAccountOrderTotals from './MyAccountOrderTotals.component';

/** @namespace myApp/Component/MyAccountOrderTotals/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace myApp/Component/MyAccountOrderTotals/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/MyAccountOrderTotals/Container */
export class MyAccountOrderTotalsContainer extends SourceMyAccountOrderTotalsContainer {
    containerProps() {
        const { order } = this.props;

        return {
            ...super.containerProps(),
            order
        };
    }

    render() {
        return (
            <MyAccountOrderTotals
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderTotalsContainer);
