/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { CartIcon as SourceCartIcon } from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.override.style';

/** @namespace myApp/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIcon {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="0.5" y="5.11279" width="15" height="11.3871" rx="0.5" stroke="#949494" />
                <path
                  d="M4.80078 8.74194V3.58065C4.97856 2.72043 5.86745 1 8.00078 1C10.1341 1 11.023 2.72043 11.2008 3.58065V8.74194"
                  stroke="#949494"
                />
            </svg>
        );
    }
}

export default CartIconComponent;
