import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { MYACCOUNT_TABLIST_OVERLAY_ID } from 'Component/MyAccountTabListOverlay/MyAccountTabListOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountContainer as SourceMyAccountContainer
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import {
    ACCOUNT_INFORMATION,
    ADDRESS_BOOK,
    CustomerType, FIRST_SECTION,
    MY_ACCOUNT, MY_ORDERS,
    MY_WISHLIST,
    SECOND_SECTION
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode, replace } from 'Util/Url';

import MyAccount from './MyAccount.component';
import { ACCOUNT_LOGIN_URL, ACCOUNT_URL } from './MyAccount.config';

/** @namespace myApp/Route/MyAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer
});

/** @namespace myApp/Route/MyAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key))
});

/** @namespace myApp/Route/MyAccount/Container */
export class MyAccountContainer extends SourceMyAccountContainer {
    static propTypes = {
        customer: CustomerType.isRequired,
        hideActiveOverlay: PropTypes.isRequired
    };

    static tabMap = {
        [MY_ACCOUNT]: {
            url: '',
            tabName: __('My Dashboard'),
            section: FIRST_SECTION
        },
        [ACCOUNT_INFORMATION]: {
            url: '/edit',
            tabName: __('Profile'),
            title: __('Profile'),
            section: SECOND_SECTION
        },
        [MY_ORDERS]: {
            url: '/sales/order/history',
            tabName: __('Order History'),
            section: FIRST_SECTION,
            isFullUrl: true
        },
        [MY_WISHLIST]: {
            url: '/customer/saved-items',
            tabName: __('Saved Items'),
            section: FIRST_SECTION,
            isFullUrl: true
        },
        [ADDRESS_BOOK]: {
            url: '/customer/address',
            tabName: __('Addresses'),
            section: SECOND_SECTION,
            isFullUrl: true
        }
    };

    tabsFilterEnabled() {
        return Object.fromEntries(Object.entries(MyAccountContainer.tabMap)
            .filter(([tabName]) => MyAccountContainer.isTabEnabled(this.props, tabName)));
    }

    containerProps() {
        const { customer, isMobile, toggleOverlayByKey } = this.props;

        return {
            ...super.containerProps(),
            customer,
            isMobile,
            toggleOverlayByKey
        };
    }

    containerFunctions = {

        ...this.containerFunctions,
        onMyAccountTabListOverlayClick: this.onMyAccountTabListOverlayClick.bind(this)
    };

    getTabName() {
        const { location: { pathname } } = this.props;
        const { tabName: stateTabName, activeTab } = this.state;
        const { tabName, url } = MyAccountContainer.tabMap[activeTab];

        if (!pathname.includes(url)) {
            return stateTabName;
        }

        return tabName;
    }

    onMyAccountTabListOverlayClick() {
        const { toggleOverlayByKey } = this.props;

        toggleOverlayByKey(MYACCOUNT_TABLIST_OVERLAY_ID);
    }

    changeActiveTab(activeTab) {
        const { hideActiveOverlay } = this.props;
        const {
            [activeTab]: {
                url,
                isFullUrl = false
            }
        } = this.tabsFilterEnabled(MyAccountContainer.tabMap);

        hideActiveOverlay();

        if (isFullUrl) {
            history.push(appendWithStoreCode(url));
        } else {
            history.push(appendWithStoreCode(`${ ACCOUNT_URL }${ url }`));
        }

        this.changeMyAccountHeaderState();
    }

    redirectIfNotSignedIn() {
        const {
            isMobile,
            baseLinkUrl
        } = this.props;

        if (isSignedIn()) { // do nothing for signed-in users
            return;
        }

        if (isMobile) { // do not redirect on mobile
            return;
        }

        if (this.handleCheckIfSelectedTab()) { // do redirect if it is customer url
            history.replace({ pathname: ACCOUNT_LOGIN_URL });
        }

        const path = baseLinkUrl
            ? appendWithStoreCode(ACCOUNT_LOGIN_URL)
            : replace(/\/customer\/account\/?.*/i, ACCOUNT_LOGIN_URL);

        history.replace({ pathname: path });
        // showNotification('info', __('Please, sign in to access this page contents!'));
    }

    render() {
        return (
            <MyAccount
              { ...this.containerProps() }
              { ...this.containerFunctions }
              tabMap={ this.tabsFilterEnabled(MyAccountContainer.tabMap) }
            />
        );
    }
}

export default withRouter(withReducers({
    OrderReducer
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer)));
