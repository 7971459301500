/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { MyAccountForgotPassword as SourceMyAccountForgotPassword }
    from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import { SignInStateType } from 'Type/Account.type';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './MyAccountForgotPassword.style';

/** @namespace myApp/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPassword {
    // TODO implement logic
    static propTypes = {
        state: SignInStateType.isRequired,
        onForgotIsEmailExists: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool.isRequired
    };

    renderForgotPasswordForm() {
        const {
            onForgotIsEmailExists, onFormError, errorMessage, isEmailExist, onHandleChange
        } = this.props;

        return (
            <Form key="forgot-password" onSubmit={ onForgotIsEmailExists } onError={ onFormError }>
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      class: 'ForgotPassword-Input_type_email',
                      placeholder: __('Your email address'),
                      autocomplete: 'email'
                  } }
                  events={ { onChange: onHandleChange } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                { !isEmailExist ? errorMessage && <div block="Field" elem="ErrorMessages">{ errorMessage }</div> : null }
                <div block="MyAccountOverlay" elem="Buttons">
                    <button block="Button" type="submit" mix={ { block: 'MyAccountOverlay', elem: 'ResetPassword' } }>
                        { __('Send ') }
                    </button>
                </div>
            </Form>
        );
    }
}

export default MyAccountForgotPasswordComponent;
