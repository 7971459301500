/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { noopFn } from 'Util/Common';
import history from 'Util/History';
import { getNewParameters } from 'Util/Product';
import getStore from 'Util/Store';

/** @namespace myApp/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    secureBaseMediaUrl: state.ConfigReducer.secure_base_media_url
});

/** @namespace myApp/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...this.propTypes,
        getLink: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        getLink: noopFn
    };

    state = {
        ...this.state,
        hoverAttributeVariant: null,
        hoverAttributeConfiguable: null
    };

    containerFunctions = {
        ...this.containerFunctions,
        updateConfigurableVariantMouseEvent: this.updateConfigurableVariantMouseEvent.bind(this),
        updateConfigurableMouseEventForPlp: this.updateConfigurableMouseEventForPlp.bind(this)
    };

    containerProps() {
        const { hoverAttributeVariant, hoverAttributeConfiguable } = this.state;
        const { secureBaseMediaUrl } = this.props;

        const state = getStore().getState();

        const category = state && state.CategoryReducer && state.CategoryReducer.category;

        const categoryUrl = category && category.url;

        return {
            ...super.containerProps(),
            hoverAttributeVariant,
            hoverAttributeConfiguable,
            secureBaseMediaUrl,
            categoryUrl,
            plpColorSwatch: this.swatchListForPlp()
        };
    }

    swatchListForPlp() {
        const { product } = this.props;
        if (product.color_swatch_list) {
            const plpColorSwatch = JSON.parse(JSON.parse(JSON.stringify(product?.color_swatch_list)));

            return plpColorSwatch;
        }

        return null;
    }

    updateConfigurableMouseEventForPlp(option) {
        if (option) {
            const plpColorSwatch = this.swatchListForPlp();
            const swatches = plpColorSwatch[0];

            const swatch = swatches.attribute_options.find((swatch) => {
                if (swatch.value === option.attribute_value.value) {
                    return true;
                }

                return false;
            });

            this.setState({ hoverAttributeConfiguable: swatch });
        } else {
            this.setState({ hoverAttributeConfiguable: null });
        }
    }

    updateConfigurableVariantMouseEvent(option) {
        const {
            product: { variants }
        } = this.props;

        if (option) {
            const variant = Object.values(variants).find((variant) => {
                if (!variant.attributes[option.attribute_code]) {
                    return false;
                }
                const { attribute_value } = variant.attributes[option.attribute_code];
                return option.attribute_value === attribute_value;
            });

            this.setState({ hoverAttributeVariant: variant });
        } else {
            this.setState({ hoverAttributeVariant: null });
        }
    }

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        const { parameters: prevParameters } = this.state;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        /* redirect on product attribute value change */
        this.setState({ parameters }, () => {
            history.push(this.getLinkTo());
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
