/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

export const MyAccountNewReturn = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountNewReturn'));

export const MyAccountReturnDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountReturnDetails'));

export const RMA_LIST = 'rma_list';

export const RMA_DETAIL = 'rma_detail';

export const RMA_LIST_DETAIL = 'rma_list_detail';

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/my-returns/return-details/:entityId') } render={ (props) => <MyAccountReturnDetails { ...props } /> } />,
            position: 199,
            name: RMA_LIST_DETAIL
        },
        {
            component: <Route path={ withStoreRegex('/my-returns/new-return/:orderId') } render={ (props) => <MyAccountNewReturn { ...props } /> } />,
            position: 200,
            name: RMA_DETAIL
        },
        {
            component: <Route path={ withStoreRegex('/my-returns') } render={ (props) => <MyAccount { ...props } selectedTab="my-returns" /> } />,
            position: 201,
            name: RMA_LIST
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
