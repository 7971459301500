/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountDropdown from './MyAccountDropdown.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Ambab/myAccountDropdown/Component/MyAccountDropdown/Container */
export class MyAccountDropdownContainer extends PureComponent {
    static propTypes = {
        logoutHandler: PropTypes.func.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired
    };

    menuTabMap = [
        {
            url: '/customer/account',
            tabName: __('My Dashboard')
        },
        {
            url: '/customer/account/edit',
            tabName: __('Profile')
        },
        {
            url: '/sales/order/history',
            tabName: __('Order History')
        },
        {
            url: '/customer/address',
            tabName: __('My Addresses')
        },
        {
            url: '/wishlist',
            tabName: __('Saved Items')
        },
        {
            tabName: __('Logout'),
            onClick: this.handleLogout.bind(this)
        }
    ];

    handleLogout(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const { logoutHandler, toggleOverlayByKey } = this.props;

        logoutHandler();
        toggleOverlayByKey('customer_account');
        history.push(appendWithStoreCode('/'));
    }

    render() {
        return (
            <MyAccountDropdown menuTabMap={ this.menuTabMap } />
        );
    }
}

/** @namespace Ambab/myAccountDropdown/Component/MyAccountDropdown/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Ambab/myAccountDropdown/Component/MyAccountDropdown/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

    logoutHandler: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, true, dispatch)
    ),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key))

});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDropdownContainer);
