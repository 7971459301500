/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { MixType } from 'Type/Common.type';
import { OriginalPriceType, ProductPriceType } from 'Type/Price.type';
import { PriceConfiguration } from 'Type/ProductList.type';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from './ProductPrice.config';

import './ProductPrice.override.style';

/** @namespace myApp/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    static propTypes = {
        price: ProductPriceType,
        priceType: PropTypes.oneOf(Object.values(PRODUCT_TYPE)),
        originalPrice: OriginalPriceType,
        tierPrice: PropTypes.string,
        configuration: PriceConfiguration,
        priceCurrency: PropTypes.string,
        discountPercentage: PropTypes.number,
        isPreview: PropTypes.bool,
        configPrice: PropTypes.bool,
        isSchemaRequired: PropTypes.bool,
        label: PropTypes.string,
        variantsCount: PropTypes.number,
        mix: MixType,
        displayTaxInPrice: PropTypes.string
    };

    static defaultProps = {
        price: {},
        priceType: PRODUCT_TYPE.simple,
        originalPrice: {},
        priceCurrency: 'USD',
        discountPercentage: 0,
        isPreview: false,
        isSchemaRequired: false,
        variantsCount: 0,
        mix: {},
        tierPrice: '',
        label: '',
        configuration: {},
        displayTaxInPrice: DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
    };

    // renderOldPrice() {
    //     const {
    //         price: {
    //             originalPrice: {
    //                 value: originalPriceValue,
    //                 valueFormatted: originalPriceFormatted
    //             } = {}
    //         } = {},
    //         discountPercentage,
    //         isSchemaRequired,
    //         variantsCount,
    //         isPdp
    //     } = this.props;

    //     if (discountPercentage === 0 || originalPriceValue === 0) {
    //         return null;
    //     }

    //     if (isPdp) {
    //         return (
    //             <>
    //             <del
    //               block="ProductPrice"
    //               elem="HighPrice"
    //               aria-label={ __('Old product price') }
    //               itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
    //             >
    //                 { originalPriceFormatted }
    //             </del>
    //             <span
    //               className="ProductDiscount"
    //               aria-label="discount"
    //             >
    //             { Math.round(discountPercentage) }
    //             % Off
    //             </span>
    //             </>
    //         );
    //     }

    //     return (
    //         <>
    //         <del
    //           block="ProductPrice"
    //           elem="HighPrice"
    //           aria-label={ __('Old product price') }
    //           itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
    //         >
    //             { originalPriceFormatted }
    //         </del>

    //         <span
    //           className="ProductDiscount"
    //           aria-label="discount"
    //         >
    //         { Math.round(discountPercentage) }
    //         % Off
    //         </span>
    //         </>
    //     );
    // }

    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { originalPriceFormatted }
            </del>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0
                } = {}
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
            isPdp,
            isConfig
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        if (isConfig === undefined) {
            return (
                <div
                  block="ProductPrice"
                  mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
                  mix={ mix }
                  aria-label={ `Product price: ${finalPriceValue}` }
                >
                    { isPreview && renderer && renderer() }
                    { (!isPreview || !renderer) && this.renderDefaultPrice() }
                    { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }
                    { discountPercentage !== undefined && discountPercentage !== null && discountPercentage !== 0 && (
                                <span
                                  className="ProductDiscount"
                                  aria-label="discount"
                                >
                                { Math.round(discountPercentage) }
                                % Off
                                </span>
                    ) }
                </div>
            );
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        if (isPdp) {
            return (
                <div
                  block="ProductPrice"
                  mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
                  mix={ mix }
                  aria-label={ `Product price: ${finalPriceValue}` }
                >

                    { isConfig.discount.amount_off === 0 ? (
                            <div aria-label="Product price: 16999" className="ProductPrice ProductActions-Price">
                                <span className="ProductPrice-Price">
                                    <span className="ProductPrice-PriceValue">
                                        ₹
                                        { isConfig.final_price.value }
                                    </span>
                                </span>
                            </div>
                    ) : (
                        <>
                            { isPreview && renderer && renderer() }
                            { (!isPreview || !renderer) && this.renderDefaultPrice() }
                            { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }

                            { discountPercentage !== undefined && discountPercentage !== null && discountPercentage !== 0 && (
                                <span
                                  className="ProductDiscount"
                                  aria-label="discount"
                                >
                                { Math.round(discountPercentage) }
                                % Off
                                </span>
                            ) }
                        </>
                    ) }

                </div>
            );
        }

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
