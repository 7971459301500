import CmsPage from 'Route/CmsPage';
import {
    NoMatch as SourceNoMatch
} from 'SourceRoute/NoMatch/NoMatch.component';

import './NoMatch.override.style';

/** @namespace myApp/Route/NoMatch/Component */
export class NoMatchComponent extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                    <CmsPage
                      location
                      pageIdentifiers="no-route"
                    />
            </main>
        );
    }
}

export default NoMatchComponent;
