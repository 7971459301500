import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'Util/Auth';

export const GuestWishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GuestWishlist/GuestWishlist.dispatcher'
);

/** @namespace myApp/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    addProductToGuestWishlist: (magentoProduct) => GuestWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToWishlist(dispatch, magentoProduct)
    ),
    removeProductFromGuestWishlist: (options) => GuestWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    )
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            isAddingWishlistItem,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
            addProductToGuestWishlist,
            removeProductFromGuestWishlist
        } = this.props;

        if (isAddingWishlistItem) {
            return null;
        }

        if (!isSignedIn()) {
            this.setWishlistButtonLoading(true);
            if (add) {
                await addProductToGuestWishlist(magentoProduct);

                return null;
            }

            const wishlistItem = this.getWishlistItem(sku);
            if (!wishlistItem) {
                return null;
            }

            const {
                wishlist: {
                    id: itemId,
                    wishlist_sku
                }
            } = wishlistItem;

            return removeProductFromGuestWishlist({ item_id: itemId, wishlist_sku, sku });
        }

        this.setWishlistButtonLoading(true);

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            });

            return null;
        }

        const wishlistItem = this.getWishlistItem(sku);
        if (!wishlistItem) {
            return null;
        }

        const {
            wishlist: {
                id: itemId
            }
        } = wishlistItem;

        return removeProductFromWishlist({ item_id: itemId });
    }

    getWishlistItem(sku) {
        const { productsInWishlist } = this.props;

        if (!productsInWishlist) {
            return null;
        }

        // TODO: After new graphql will need to check by options
        return Object.values(productsInWishlist).find(
            ({ sku: wishlistSku }) => sku === wishlistSku
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
