export const CONFIGURABLE = 'configurable';

/** @namespace Ambab/Webengage/Helper/Wishlist/Index/getWishlistProduct */
export function getWishlistProduct(product) {
    const {
        type_id, price_range: {
            minimum_price: {
                final_price: { value }
            }
        }
    } = product;

    if (type_id === CONFIGURABLE) {
        return {
            'Product Name': product.name,
            'Product SKU': product.sku,
            Price: value,
            quantity: product.quantity,
            options: product.wishlist.options,
            image: product.small_image.url
        };
    }

    const wishedVariant = product.variants.find(({ sku }) => sku === product.wishlist.sku);

    if (!wishedVariant) {
        return {
            'Product Name': product.name,
            'Product SKU': product.sku,
            Price: value,
            quantity: product.quantity,
            options: product.wishlist.options,
            image: product.small_image.url
        };
    }

    return {
        'Product Name': product.name,
        'Product SKU': product.sku,
        Price: value,
        quantity: product.quantity,
        options: product.wishlist.options,
        image: product.small_image.url
    };
}

/** @namespace Ambab/Webengage/Helper/Wishlist/Index/getWishlistProducts */
export const getWishlistProducts = ([, product]) => getWishlistProduct(product);
