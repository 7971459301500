/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    MY_WISHLIST
} from 'Type/Account.type';

import {
    AFTER_ITEMS_TYPE,
    FOOTER,
    MY_ACCOUNT_WISHLIST,
    SWITCH_ITEMS_TYPE
} from './Router.config';

import './Router.override.style';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex
};
export const CampaigningPage = lazy(() => import('route/CampaigningPage'));

export const CAMPAIGNING_PAGE = 'CAMPAIGNING_PAGE';

/** @namespace myApp/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER
        }
    ];

    [SWITCH_ITEMS_TYPE] = [...this.SWITCH_ITEMS_TYPE,
        {
            // eslint-disable-next-line max-len
            component: <Route path={ withStoreRegex('/customer/saved-items') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
            position: 73,
            name: MY_ACCOUNT_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/neerajchopra/ziddformore') } render={ (props) => <CampaigningPage { ...props } /> } />,
            position: 135,
            name: CAMPAIGNING_PAGE
        }
    ];
}

export default RouterComponent;
