/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { toDateString } from 'Util/Manipulations/Date';

import './MyAccountReturnTableRow.style';

/** @namespace Rma/Component/MyAccountReturnTableRow/Component */
export class MyAccountReturnTableRowComponent extends PureComponent {
    /**
     * Prop types
     * @type {*}
     */
    static propTypes = {
        onViewClick: PropTypes.func.isRequired,
        row: PropTypes.object.isRequired
    };

    /**
     * Render
     * @returns {*}
     */
    render() {
        const {
            row: {
                order_increment_id,
                increment_id,
                created_at,
                status_label
            },
            onViewClick
        } = this.props;

        return (
            <tr onClick={ onViewClick } block="MyAccountReturnTableRow">
                <td data-label="Order #">{ order_increment_id }</td>
                <td data-label="Return #">{ increment_id }</td>
                <td data-label="Return Date">{ toDateString(created_at) }</td>
                <td data-label="Return Status">{ status_label }</td>
            </tr>
        );
    }
}

export default MyAccountReturnTableRowComponent;
