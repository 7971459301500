import { ORDER_TRACKED, PAGE_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const componentDidMount = (args, callback, instance) => {
    callback(...args);
    const {
        match: {
            params: {
                entityId
            }
        }
    } = instance.props;

    eventTrack(ORDER_TRACKED, { 'AWB Number': entityId });
};

const aroundComponentDidMount = (args, callback, instance) => {
    const { location: { pathname } } = instance.props;

    eventTrack(PAGE_VIEWED, {
        'Page Name': 'Track Order',
        'Page Path': pathname
    });
};

export default {
    'TrackOrderPage/Route/TrackOrderStatus/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'TrackOrderPage/Route/TrackOrder/Container': {
        'member-function': {
            componentDidMount: aroundComponentDidMount
        }
    }
};
