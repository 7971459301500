import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderItemsTableContainer as SourceMyAccountOrderItemsTableContainer
} from 'SourceComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.container';
import { OrderType } from 'Type/Order.type';

import MyAccountOrderItemsTable from './MyAccountOrderItemsTable.component';
/** @namespace myApp/Component/MyAccountOrderItemsTable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountOrderItemsTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/MyAccountOrderItemsTable/Container */
export class MyAccountOrderItemsTableContainer extends SourceMyAccountOrderItemsTableContainer {
    static propTypes = {
        ...this.propTypes,
        order: OrderType.isRequired
    };

    containerProps() {
        const { order } = this.props;
        return {
            ...super.containerProps(),
            order
        };
    }

    render() {
        return (
            <MyAccountOrderItemsTable
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderItemsTableContainer);
