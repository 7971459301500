import ProductWishlistButton from 'Component/ProductWishlistButton';

function renderWishlistButton(args, callback, instance) {
    const { magentoProduct, isWishlistEnabled, product } = instance.props;

    if (magentoProduct.length === 0 || !isWishlistEnabled) {
        return null;
    }

    return (
        <ProductWishlistButton
          productWishlist={ product }
          magentoProduct={ magentoProduct }
          mix={ {
              block: instance.className,
              elem: 'WishListButton'
          } }
        />
    );
}

export default {
    'Component/Product/Component': {
        'member-function': {
            renderWishlistButton
        }
    }
};
