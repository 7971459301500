/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const FaqsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "guest-wishlist" */ '../route/FaqsPage'));

export const FAQS_PAGE = 'FAQS_PAGE';

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/faqs') } render={ (props) => <FaqsPage { ...props } /> } />,
            position: 135,
            name: FAQS_PAGE
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
