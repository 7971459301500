import { isSignedIn } from 'Util/Auth';
import getStore from 'Util/Store';

import {
    CHECKOUT_STARTED, CONTINUE_PAYMENT, GUEST_USER_CHECKOUT, PAYMENT_STARTED
} from '../config';
import {
    getCartProductsColorsString,
    getCartProductsGenderString,
    getCartProductsImagesArray,
    getCartProductsNameString, getCartProductsQtysString, getCartProductsSizesString, getCartProductsSkuString
} from '../helper/Cart';
import { getCartProductsDetailsInArray } from '../helper/Checkout';
import { eventTrack, setAttribute, trackUserLogin } from '../helper/WebEngage/Event';

export const onCheckoutButtonClick = (args, callback, instance) => {
    const {
        totals, cartShippingPrice, cartSubtotal
    } = instance.props;

    const { items = [] } = totals;

    if (!instance.hasOutOfStockProductsInCartItems(items)) {
        eventTrack(CHECKOUT_STARTED, {
            'Product Name': getCartProductsNameString(items),
            'Product SKU': getCartProductsSkuString(items),
            'Product Image': getCartProductsImagesArray(items),
            'Product Details': getCartProductsDetailsInArray(items),
            Color: getCartProductsColorsString(items),
            Size: getCartProductsSizesString(items),
            Quantity: getCartProductsQtysString(items),
            Gender: getCartProductsGenderString(items),
            'Estimated Shipping': cartShippingPrice,
            'Sub Total': cartSubtotal
        });
    }
    callback(...args);
};

export const onShippingSuccess = (args, callback, instance) => {
    callback(...args);
    const {
        totals: { items = [], subtotal, shipping_amount }
    } = instance.props;

    if (!isSignedIn()) {
        const state = getStore().getState();

        const { shippingFields, email } = state.CheckoutReducer;

        eventTrack(GUEST_USER_CHECKOUT, {});
        trackUserLogin(email);

        setAttribute('first_name', shippingFields.firstname);
        setAttribute('phone', shippingFields.telephone);
        setAttribute('email', email);
        setAttribute('city', shippingFields.city);
        setAttribute('region', shippingFields.region_string);
        setAttribute('postal_code', shippingFields.postcode);
    }

    eventTrack(CONTINUE_PAYMENT, {
        'Product Name': getCartProductsNameString(items),
        'Product SKU': getCartProductsSkuString(items),
        'Product Image': getCartProductsImagesArray(items),
        'Product Details': getCartProductsDetailsInArray(items),
        Color: getCartProductsColorsString(items),
        Size: getCartProductsSizesString(items),
        Quantity: getCartProductsQtysString(items),
        Gender: getCartProductsGenderString(items),
        'Estimated Shipping': shipping_amount,
        'Sub Total': subtotal
    });
};

export const onBillingSuccess = (args, callback, instance) => {
    callback(...args);
    const {
        totals: { items = [], subtotal, shipping_amount }
    } = instance.props;

    eventTrack(PAYMENT_STARTED, {
        'Product Name': getCartProductsNameString(items),
        'Product SKU': getCartProductsSkuString(items),
        'Product Image': getCartProductsImagesArray(items),
        'Product Details': getCartProductsDetailsInArray(items),
        Color: getCartProductsColorsString(items),
        Size: getCartProductsSizesString(items),
        Quantity: getCartProductsQtysString(items),
        Gender: getCartProductsGenderString(items),
        'Estimated Shipping': shipping_amount,
        'Sub Total': subtotal
    });
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            onCheckoutButtonClick
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess
        }
    }
};
