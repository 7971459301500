/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';
import { scrollToTop } from 'Util/Browser';
import transformToNameValuePair from 'Util/Form/Transform';
import {
    getName, getProductInStock
} from 'Util/Product/Extract';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import GiftCardQuery from '../../query/GiftCard.query';
import { GIFT_CART_POPUP_ID } from '../GiftAddToCartPopup/GiftAddToCartPopup.config';
import GiftCardAction from './GiftCardAction.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace GiftCard/Component/GiftCardAction/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer.cartTotals.id,
    device: state.ConfigReducer.device,
    currenyCode: state.ConfigReducer.default_display_currency_code
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const ADD_TO_CART = 'add-to-cart';

/** @namespace GiftCard/Component/GiftCardAction/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    showGiftCartPopup: (payload) => dispatch(showPopup(GIFT_CART_POPUP_ID, payload)),
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    removeProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeProductFromCart(dispatch, options)
    )
});

/** @namespace GiftCard/Component/GiftCardAction/Container */
export class GiftCardActionContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        device: DeviceType,
        cartId: PropTypes.string,
        currenyCode: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired,
        showGiftCartPopup: PropTypes.func.isRequired,
        payload: PropTypes.objectOf.isRequired,
        isUpdateGiftCard: PropTypes.bool,
        removeProduct: PropTypes.func.isRequired,
        fallbackAddToCart: PropTypes.func.isRequired

    };

    static defaultProps = {
        device: {},
        cartId: '',
        isUpdateGiftCard: false
    };

    containerFunctions = {
        handleGiftCardSubmit: this.handleGiftCardSubmit.bind(this)
    };

    state = {
        isAdding: false,
        formfields: {},
        staticTitle: 'Under Armour India - Gift Card',
        staticDesc: ''
    };

    globalValidationMap = [
        this.validateStock.bind(this)
    ];

    componentDidMount() {
        const { payload } = this.props;

        if (payload) {
            this.getGiftCradProductInfo();
        }
    }

    containerProps() {
        const {
            product, areDetailsLoaded, device, currenyCode, isUpdateGiftCard, payload
        } = this.props;
        const {
            isAdding,
            formfields,
            staticTitle,
            staticDesc
        } = this.state;

        return {
            product,
            areDetailsLoaded,
            device,
            currenyCode,
            isAdding,
            formfields,
            isUpdateGiftCard,
            payload,
            staticTitle,
            staticDesc
        };
    }

    getGiftCradProductInfo() {
        const { payload: { cartItem, cartItem: { price, qty } } } = this.props;
        const giftMail = cartItem.giftcardproductinfo[0];

        const giftField = {
            quantity: qty,
            price,
            mail_from: giftMail.mail_from,
            mail_message: giftMail.mail_message,
            mail_to: giftMail.mail_to,
            mail_to_email: giftMail.mail_to_email

        };

        this.setState({
            formfields: giftField
        });
    }

    async handleGiftCardForCartPage() {
        const {
            payload, removeProduct, isUpdateGiftCard,
            updateInitialCartData
        } = this.props;

        if (payload && isUpdateGiftCard) {
            await updateInitialCartData();
            await removeProduct(payload.itemID);
        }

        await updateInitialCartData();
    }

    async handleGiftCardSubmit(_form, fields) {
        const { showGiftCartPopup } = this.props;
        this.setState({ isAdding: true });
        const giftFieldaddToCart = transformToNameValuePair(fields);

        const {
            product, product: { sku }, cartId, showNotification
        } = this.props;

        const cartItem = [
            {
                data: {
                    // quantity: giftFieldaddToCart.item_qty,
                    quantity: 1,
                    sku
                },
                gift_card_product_options:
                {
                    card_amount: giftFieldaddToCart.amountOption,
                    card_amount_other: giftFieldaddToCart.otherAmount,
                    mail_from: giftFieldaddToCart.fromName,
                    mail_to: giftFieldaddToCart.toName,
                    mail_to_email: giftFieldaddToCart.email,
                    mail_message: giftFieldaddToCart.message
                }
            }
        ];

        const body = {
            cart_id: cartId,
            cart_items: cartItem
        };

        if (!this.validate()) {
            return;
        }

        // if (type_id === 'mageworx_giftcards') {

        // }

        try {
            const mutation = GiftCardQuery.getQuery(body);
            await fetchMutation(mutation);
            await this.handleGiftCardForCartPage();
            this.setState({ isAdding: false });

            showGiftCartPopup({
                title: `(${giftFieldaddToCart.item_qty}) ${ __('Item Added To Bag') }`,
                product,
                quantity: giftFieldaddToCart.item_qty,
                amount: giftFieldaddToCart.amountOption === 'other_amount'
                    ? giftFieldaddToCart.otherAmount : giftFieldaddToCart.amountOption

            });
            scrollToTop();
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        this.setState({ isAdding: false });
    }

    validateStock() {
        const { product, showNotification } = this.props;
        const inStock = getProductInStock(product);
        if (!inStock) {
            const name = getName(product);
            showNotification('info', __('Sorry! The product %s is out of stock!', name));
        }

        return inStock;
    }

    validate() {
        // eslint-disable-next-line fp/no-let
        let isValid = true;
        this.globalValidationMap.forEach((step) => {
            if (!step()) {
                isValid = false;
            }
        });

        return isValid;
    }

    render() {
        console.log(this.state);
        return (
            <GiftCardAction
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardActionContainer);
