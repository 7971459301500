import getStore from 'Util/Store';

import { USER_LOGGED_IN, USER_SIGNED_UP } from '../config';
import {
    eventTrack, setAttribute, trackUserLogin
} from '../helper/WebEngage/Event';

export const signIn = (args, callback) => callback(...args).then(() => {
    const state = getStore().getState();
    const { customer: { email } } = state.MyAccountReducer;

    trackUserLogin(email);
    eventTrack(USER_LOGGED_IN, {
        Mode: 'Email'
    });
});

export const signInOtp = (args, callback) => callback(...args).then(() => {
    const state = getStore().getState();
    const { customer: { email } } = state.MyAccountReducer;
    trackUserLogin(email);
    eventTrack(USER_LOGGED_IN, {
        Mode: 'Phone'
    });
});

export const createAccountVerify = (args, callback) => callback(...args).then(() => {
    const state = getStore().getState();
    const {
        customer: {
            mobilenumber, firstname, email
        }
    } = state.MyAccountReducer;

    eventTrack(USER_SIGNED_UP, {});

    trackUserLogin(email);

    setAttribute('first_name', firstname);
    setAttribute('phone', mobilenumber);
    setAttribute('email', email);
});

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn, signInOtp, createAccountVerify
        }
    }
};
