/* eslint-disable max-len */
import { UserIcon as SourceUserIcon } from 'SourceComponent/UserIcon/UserIcon.component';

import './UserIcon.style';

/** @namespace myApp/Component/UserIcon/Component */
export class UserIconComponent extends SourceUserIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="UserIcon"
              mods={ { isActive } }
              width="19"
              height="19"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="4" r="3.5" stroke="#fff" />
                <path
                  d="M0.587867 13.5C1.25259 10.21 4.07374 7.5 8 7.5C11.9263 7.5
            14.7474 10.21 15.4121 13.5H0.587867Z"
                  stroke="#fff"
                />
            </svg>
        );
    }
}

export default UserIconComponent;
