import { getMobileWithISD } from 'Util/Helper';
import { Field } from 'Util/Query';

/** @namespace myApp/Query/OTP/Query */
export class OTPQuery {
    getQuery() {
        // TODO implement query
    }

    getLoginOTPQuery(fields) {
        const mutation = new Field('loginOTP')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(fields.mobileNumber))
            // .addArgument('websiteId', 'Int!', fields.websiteId)
            // .addArgument('email', 'String!', getMobileWithISD(fields.))
            .addFieldList(['message']);

        return mutation;
    }

    getLoginOTPQueryVerify(fields) {
        const mutation = new Field('loginOTPVerify')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(fields.mobileNumber))
            .addArgument('otp', 'String!', fields.otp)
            // .addArgument('email', 'String!', getMobileWithISD(fields.))
            .addFieldList(['message', 'token']);

        return mutation;
    }

    getCreateAccountOTP(fields) {
        const mutation = new Field('createAccountOTP')
            .addArgument('customer_name', 'String!', fields.firstname)
            .addArgument('mobileNumber', 'String!', getMobileWithISD(fields.mobileNumber))
            .addArgument('email', 'String!', fields.email)
            .addFieldList(['message']);

        return mutation;
    }

    getCreateAccountOTPVerify(fields) {
        const mutation = new Field('createAccountOTPVerify')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(fields.mobileNumber))
            .addArgument('otp', 'String!', fields.otp)
            .addFieldList(['message']);

        return mutation;
    }

    getCreateCustomerAccount(options) {
        const { mobileNumber, otp, ...input } = options;

        const mutation = new Field('createCustomerAccount')
            .addArgument('input', 'CustomerInput!', input)
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addArgument('otp', 'String!', otp)
            .addFieldList(['message', 'status']);

        return mutation;
    }

    getIsEmailExist(email) {
        const query = new Field('isEmailExist')
            .addArgument('email', 'String!', email)
            .addFieldList(['is_email_exist', 'error']);

        return query;
    }
}

export default new OTPQuery();
