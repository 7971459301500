/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
  * Returns customer forms fields
  * @param props
  * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, ...[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}]|*[]]}
  * @namespace myApp/Component/MyAccountCustomerForm/Form/customerInformationFields */
export const customerInformationFields = (props) => {
    const {
        firstname,
        // lastname,
        dob,
        taxvat,
        showTaxVatNumber,
        handleChangeEmailCheckbox,
        handleChangePasswordCheckbox,
        showEmailChangeField,
        showPasswordChangeField,
        vatNumberRequired,
        email,
        gender,
        selectedGender,
        handleSelectedGender,
        emailChangeRef,
        passwordChangeRef
    } = props;

    return [
        {
            type: FIELD_TYPE.text,
            label: __('Full Name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname,
                placeholder: __('Your first name')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            },
            mix: { block: 'fullWidth' }
        },
        // {
        //     type: FIELD_TYPE.text,
        //     label: __('Last Name'),
        //     attr: {
        //         name: 'lastname',
        //         defaultValue: lastname,
        //         placeholder: __('Your last name')
        //     },
        //     addRequiredTag: true,
        //     validateOn: ['onChange'],
        //     validationRule: {
        //         isRequired: true
        //     }
        // },
        {
            type: FIELD_TYPE.date,
            label: __('Date of Birth'),
            attr: {
                name: 'dob',
                defaultValue: dob,
                max: new Date().toISOString().split('T')[0]
            },
            // addRequiredTag: true,
            // validateOn: ['onChange'],
            // validationRule: {
            //     isRequired: true
            // },
            mix: { block: 'fullWidth' }
        },
        {
            type: FIELD_TYPE.select,
            label: __('Gender'),
            attr: {
                id: 'gender',
                name: 'gender',
                value: selectedGender || String(gender),
                placeholder: __('Your gender')
            },
            events: {
                onChange: handleSelectedGender
            },
            options: [
                { label: 'Male', id: 'male', value: '0' },
                { label: 'Female', id: 'female', value: '1' },
                { label: 'Others', id: 'others', value: '2' }
            ],
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            },
            mix: { block: 'fullWidth' }
        },
        ...(showTaxVatNumber ? [
            {
                type: FIELD_TYPE.text,
                label: __('Tax/VAT Number'),
                attr: {
                    name: 'taxvat',
                    defaultValue: taxvat,
                    placeholder: __('Your tax/VAT number')
                },
                addRequiredTag: vatNumberRequired,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: vatNumberRequired
                }
            }
        ] : []),
        {
            type: FIELD_TYPE.email,
            label: __('Email'),
            attr: {
                value: email,
                id: 'currentEmail'
                // defaultChecked: showEmailChangeField
            }
            // events: {
            //     onChange: handleChangeEmailCheckbox
            // }
        },
        (showPasswordChangeField ? '' : {
            type: FIELD_TYPE.checkbox,
            label: __('Change'),
            attr: {
                name: 'showEmailChangeField',
                defaultChecked: showEmailChangeField
            },
            elemRef: emailChangeRef,
            events: {
                onChange: handleChangeEmailCheckbox
            },
            mix: { block: 'changeEmail' }
        }),
        (showEmailChangeField ? '' : {
            type: FIELD_TYPE.checkbox,
            attr: {
                name: 'showPasswordChangeField',
                defaultChecked: showPasswordChangeField
            },
            elemRef: passwordChangeRef,
            events: {
                onChange: handleChangePasswordCheckbox
            },
            label: __('Change Password'),
            mix: { block: 'changePassword', mods: { isdefaultChecked: showPasswordChangeField } }
        })
    ];
};

/**
  * Returns customer email and password forms fields
  * @param props
  * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, ...[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}]|*[]]}
  * @namespace myApp/Component/MyAccountCustomerForm/Form/customerEmailAndPasswordFields */
export const customerEmailAndPasswordFields = (props) => {
    const {
        showEmailChangeField,
        showPasswordChangeField,
        handleEmailInput,
        handlePasswordInput,
        currentPassword
    } = props;

    return [
        ...(showEmailChangeField ? [
            {
                type: FIELD_TYPE.email,
                label: __('New Email'),
                attr: {
                    name: 'email',
                    id: 'newEmail',
                    placeholder: __('Your new email')
                },
                events: {
                    onChange: handleEmailInput
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.email,
                    isRequired: true,
                    match: (value) => {
                        const email = document.getElementById('currentEmail');
                        return value && email.value !== value;
                    },
                    customErrorMessages: {
                        onMatchFail: __('New email can\'t be the same as old email!')
                    }
                }
            },
            {
                type: FIELD_TYPE.email,
                label: __('Confirm New Email'),
                attr: {
                    name: 'confirm new email',
                    placeholder: __('Your new email'),
                    'aria-label': __('Current password')
                },
                events: {
                    onChange: handleEmailInput
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.email,
                    isRequired: true,
                    match: (value) => {
                        const email = document.getElementById('newEmail');
                        return email.value === value;
                    },
                    customErrorMessages: {
                        onMatchFail: __('Emails do not match!')
                    }
                }
            }
        ] : []),
        ...(showPasswordChangeField || showEmailChangeField ? [
            {
                label: __('Current password'),
                type: FIELD_TYPE.password,
                attr: {
                    id: 'currentPassword',
                    name: 'password',
                    placeholder: __('Your current password'),
                    'aria-label': __('Current password'),
                    value: currentPassword
                },
                events: {
                    onChange: handlePasswordInput
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.password,
                    isRequired: true
                }
            }
        ] : []),
        ...(showPasswordChangeField ? [
            {
                label: __('New password'),
                type: FIELD_TYPE.password,
                attr: {
                    id: 'newPassword',
                    name: 'newPassword',
                    placeholder: __('Your new password'),
                    'aria-label': __('New password')
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.password,
                    isRequired: true,
                    match: (value) => {
                        const password = document.getElementById('currentPassword');
                        return value && password.value !== value;
                    },
                    customErrorMessages: {
                        onMatchFail: __('New passwords can\'t be the same as old password!')
                    }
                }
            },
            {
                type: FIELD_TYPE.password,
                label: __('Confirm New Password '),
                attr: {
                    name: 'confirmNewPassword',
                    placeholder: __('Confirm New password'),
                    'aria-label': __('Confirm New password')
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: true,
                    inputType: VALIDATION_INPUT_TYPE.password,
                    match: (value) => {
                        const password = document.getElementById('newPassword');
                        return password.value === value;
                    },
                    customErrorMessages: {
                        onMatchFail: __('Passwords do not match!')
                    }
                }
            }
        ] : [])
    ];
};
