/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const SiteMap = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "site-map" */ '../route/SiteMap'));

export const SITE_MAP_PAGE = 'site-map-page';

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/site-map') } render={ (props) => <SiteMap { ...props } /> } />,
            position: 185,
            name: SITE_MAP_PAGE
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
