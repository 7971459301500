/* eslint-disable max-len */
/* eslint-disable no-empty-pattern */
import CancelOrderPopup from 'Component/CancelOrderPopup';
import Loader from 'Component/Loader';
import MyAccountOrderItemsTable from 'Component/MyAccountOrderItemsTable';
// import MyAccountOrderTabs from 'Component/MyAccountOrderTabs';
import {
    MyAccountOrder as SourceMyAccountOrder
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';
import { toDateString } from 'Util/Manipulations/Date';

import './MyAccountOrder.override.style';
/** @namespace myApp/Component/MyAccountOrder/Component */
export class MyAccountOrderComponent extends SourceMyAccountOrder {
    renderOrderIncrementIdAndStatus() {
        const { order: { status } } = this.props;

        return (
            <h2 block="MyAccountOrder" elem="OrderId">
                <span block="MyAccountOrder" elem="OrderStatus">
                    { status }
                </span>
            </h2>
        );
    }

    renderCancelButton() {
        const {
            order: { status, giftcard_used_status, shipping_address }, cancelOrder
        } = this.props;

        if (!giftcard_used_status) {
            if (shipping_address !== null) {
                if (status === 'In Process' || status === 'Processing') {
                    return <button block="Button" mods={ { isHollow: true } } onClick={ cancelOrder }> Cancel </button>;
                }

                if (
                    status === 'Pickup Scheduled'
                || status === 'Shipped'
                || status === 'Qc Failed'
                || status === 'Delivered'
                || status === 'Out For Delivery'
                || status === 'In Transit'
                || status === 'Partial Delivered'
                || status === 'Partiallly  Shipped'
                || status === 'Ready To Ship'
                || status === 'Out For Pickup'
                || status === 'Pickup Exception'
                || status === 'Undelivered'
                || status === 'Delayed'
                || status === 'Destroyed'
                || status === 'Damaged'
                || status === 'Fulfilled'
                || status === 'Reached at Destination'
                || status === 'Misrouted'
                || status === 'Pending'
                ) {
                    return null;
                }

                return <span block="textred cancelItemMsg">This product has been Cancelled.</span>;
            }

            return null;
        }

        return null;
    }

    renderReturnButton() {
        const { order: { status } } = this.props;
        if (status === 'Complete') {
            return <button block="Button" mods={ { isHollow: true } }> Return </button>;
        }

        return null;
    }

    renderBaseInfo() {
        const { order: { order_date, increment_id } } = this.props;

        return (
            <div block="MyAccountOrder" elem="Topdiv">
                <div block="MyAccountOrder" elem="CreationDate">
                { this.renderOrderIncrementIdAndStatus() }
                <span block="MyAccountOrder" elem="Date">
                { __('Order Placed: %s', toDateString(order_date)) }
                </span>
                <span block="MyAccountOrder" elem="ID">{ __('Order#%s', increment_id) }</span>
                </div>
                { /* { this.renderCancelButton() } */ }
                { /* { this.renderReturnButton() } */ }

            </div>

        );
    }

    renderActions() {
        return (
            <div block="MyAccountOrder" elem="Actions" />
        );
    }

    renderOrderShippingTitle() {
        const { order } = this.props;
        const item = order.items[0];

        const productStatus = item.product_name === 'Diwali Gift card' ? 'Billing' : 'Shipping';
        return (
            <h4 block="MyAccount" elem="SubTitle">
                { productStatus }
            </h4>
        );
    }

    renderContent() {
        const { order: { items } } = this.props;

        if (!items) {
            return null;
        }

        return (
            <>
                { this.renderBaseInfo() }
                { this.renderActions() }
                { this.renderOrderShippingTitle() }
                { this.renderActiveTab() }
                { /* { this.renderOrderInformation() } */ }
            </>
        );
    }

    renderOrderItemsTable(items, index) {
        const { activeTab, order, order: { total: orderTotal, items: allOrderItems, id } } = this.props;
        const { total: itemsTotal } = items;

        return (
            <MyAccountOrderItemsTable
              key={ `${activeTab}-${id}-${index}` }
              activeTab={ activeTab }
              items={ items }
              allOrderItems={ allOrderItems }
              total={ itemsTotal || orderTotal }
              order={ order }
            />
        );
    }

    renderCancelOrderItemPopup() {
        const { afterCancelOrderItem } = this.props;
        return (
            <CancelOrderPopup afterCancel={ afterCancelOrderItem } />
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div block="MyAccountOrder" elem="Detail">
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
                { this.renderCancelOrderItemPopup() }
            </div>
        );
    }
}

export default MyAccountOrderComponent;
