/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Loader from 'Component/Loader';

import './MyAccountPreferenceForm.style';

/** @namespace myApp/Component/MyAccountPreferenceForm/Component */
export class MyAccountPreferenceFormComponent extends PureComponent {
    static propTypes = {
        shopFors: PropTypes.arrayOf.isRequired,
        youIntos: PropTypes.arrayOf.isRequired,
        customerShopFor: PropTypes.arrayOf.isRequired,
        customerYouInto: PropTypes.arrayOf.isRequired,
        handleUpdatePreferences: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        checkedItemsShopFor: [],
        checkedItemsYouInto: []
    };

    componentDidUpdate(prevProps) {
        const { customerShopFor, customerYouInto } = this.props;
        const { customerShopFor: prevCustomerShopFor, customerYouInto: prevCustomerYouInto } = prevProps;

        if (customerShopFor !== prevCustomerShopFor || customerYouInto !== prevCustomerYouInto) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                checkedItemsShopFor: customerShopFor,
                checkedItemsYouInto: customerYouInto
            });
        }
    }

    afterPerferenceChange = () => {
        const { handleUpdatePreferences } = this.props;
        const { checkedItemsShopFor, checkedItemsYouInto } = this.state;

        handleUpdatePreferences({ shopFors: checkedItemsShopFor, youIntos: checkedItemsYouInto });
    };

    handleChangeShopFor = (e) => {
        // eslint-disable-next-line no-var
        var shopFor = Array.from(this.state.checkedItemsShopFor);
        const item = e.target.value;
        // const isChecked = e.target.checked;
        const index = shopFor.findIndex((shop) => shop === item);
        if (index > -1) {
            shopFor = [...shopFor.slice(0, index), ...shopFor.slice(index + 1)];
        } else {
            shopFor.push(item);
        }
        this.setState({ checkedItemsShopFor: shopFor }, () => this.afterPerferenceChange());
    };

    handleChangeYouInto = (e) => {
        // eslint-disable-next-line no-var
        var youInto = Array.from(this.state.checkedItemsYouInto);
        const item = e.target.value;
        // const isChecked = e.target.checked;
        const index = youInto.findIndex((look) => look === item);
        if (index > -1) {
            youInto = [...youInto.slice(0, index), ...youInto.slice(index + 1)];
        } else {
            youInto.push(item);
        }
        this.setState({ checkedItemsYouInto: youInto }, () => this.afterPerferenceChange());
    };

    renderPerferenceShopForForm() {
        const { shopFors } = this.props;
        const { checkedItemsShopFor } = this.state;

        return (
            <div block="MyAccountPreferenceForm" elem="ShopFor">
            <h3>who do you shop for?</h3>
            <FieldGroup
              key="shop_for"
              attr={ {
                  name: 'shop_for'
              } }
              validationRule={ {
                  selector: '[type="checkbox"]'
              } }
              validateOn={ ['onChange'] }
            >
            { shopFors.map((data) => (

                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ data }
                  attr={ {
                      name: `shop_for[${data}]`,
                      value: data,
                      defaultValue: data,
                      checked: checkedItemsShopFor.includes(data)

                  } }
                  events={ { onChange: this.handleChangeShopFor } }
                  mix={ { block: 'MyPerferencShopForForm', elem: 'Checkbox' } }
                />
            )) }
            </FieldGroup>
            </div>
        );
    }

    renderPreferenceYouIntoForm() {
        const { youIntos } = this.props;
        const { checkedItemsYouInto } = this.state;

        return (
            <div block="MyAccountPreferenceForm" elem="YouInto">
                <h3>What are you into? </h3>
                <FieldGroup
                  attr={ {
                      name: 'you_into'
                  } }
                >
            { youIntos.map((data) => (
                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ data }
                  attr={ {
                      name: `you_into[${data}]`,
                      value: data,
                      checked: checkedItemsYouInto.includes(data)

                  } }
                  events={ { onChange: this.handleChangeYouInto } }
                  mix={ { block: 'MyPreferenceYouIntoForm', elem: 'Checkbox' } }
                />
            )) }
                </FieldGroup>

            </div>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div block="MyAccountPreferenceForm">
                <Loader isLoading={ isLoading } />
                <div block="FieldForm" elem="Section">
                    <legend
                      block="FieldForm"
                      elem="Legend"
                    >
                        { __('Preferences') }
                    </legend>
                    <div block="Preferences" elem="Form">
                        { this.renderPerferenceShopForForm() }
                        { this.renderPreferenceYouIntoForm() }
                    </div>
                </div>
            </div>
        );
    }
}

export default MyAccountPreferenceFormComponent;
