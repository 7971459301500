/* eslint-disable max-len */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DownArrow.style';

/** @namespace myApp/Component/DownArrow/Component */
export class DownArrowComponent extends PureComponent {
    static propTypes = {
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="DownArrow"
              mods={ { isPrimary } }
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M15.8361 0.165351C15.6155 -0.0551169 15.2572 -0.0551169
15.0366 0.165351L8.00777 7.2084L0.964719 0.165351C0.744252 -0.0551169 0.385964 -0.0551169 0.165351 0.165351C-0.0551169 0.385968 -0.0551169 0.744259 0.165351 0.964719L7.59426 8.39363C7.70449 8.50386 7.84232 8.55905 7.99394 8.55905C8.13177 8.55905 8.2834 8.50386 8.39363 8.39363L15.8225 0.964719C16.0568 0.744252 16.0568 0.385964 15.8363 0.165351H15.8361Z"
                  fill="#1D1D1D"
                />
            </svg>
        );
    }
}

export default DownArrowComponent;
