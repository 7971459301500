// import { CHECKOUT, MY_ACCOUNT } from 'Component/Header/Header.config';

// import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import OtpQuery from 'Query/OTP.query';
import WishlistQuery from 'Query/Wishlist.query';
import {
    CUSTOMER as SOURCE_CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS as SOURCE_ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken, isSignedIn, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
// TODO: implement CUSTOMER
export const CUSTOMER = SOURCE_CUSTOMER;

export const PRODUCTS_IN_GUEST_WISHLIST = 'guest_wishlist_products';

// TODO: implement ONE_MONTH_IN_SECONDS
export const ONE_MONTH_IN_SECONDS = SOURCE_ONE_MONTH_IN_SECONDS;
export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace myApp/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    async updateGuestWishlistToAuth() {
        const wishlistItem = BrowserDatabase.getItem(PRODUCTS_IN_GUEST_WISHLIST) || [];

        if (wishlistItem.length > 0) {
            await fetchMutation(WishlistQuery.addProductsToWishlist('', wishlistItem));
            BrowserDatabase.deleteItem(PRODUCTS_IN_GUEST_WISHLIST);
        }

        return true;
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        await this.updateGuestWishlistToAuth();

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        // dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    async createAccountVerify(options = {}, dispatch) {
        const { email, password } = options;
        // const mutation = MyAccountQuery.getCreateAccountMutation(options);
        const mutation = OtpQuery.getCreateCustomerAccount(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace myApp/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccountVerify/fetchMutation/then */
            (_data) => this.signIn({ email, password }, dispatch),

            /** @namespace myApp/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccountVerify/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    async signInOtp(options = {}, dispatch) {
        const mutation = OtpQuery.getLoginOTPQueryVerify(options);

        const result = await fetchQuery(mutation);
        const {
            loginOTPVerify: { token }
        } = result;

        setAuthorizationToken(token);

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);
        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        await this.requestCustomerData(dispatch);

        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        // dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        if (authTokenExpired) {
            if (isWithNotification) {
                // dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }

            this.handleForceRedirectToLoginPage();
        } else {
            // eslint-disable-next-line no-lonely-if
            if (isSignedIn()) {
                fetchMutation(MyAccountQuery.getRevokeAccountToken());
                deleteAuthorizationToken();
            }

            // if (isWithNotification) {
            //     dispatch(showNotification('success', __('You are successfully logged out!')));
            // }
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    }
}

export default new MyAccountDispatcher();
