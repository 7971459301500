/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import ContentWrapper from 'Component/ContentWrapper';
import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

import SlickSlider from '../SlickSlider';

import './ProductLinks.override.style';

/** @namespace myApp/Component/ProductLinks/Component */
export class ProductLinksComponent extends SourceProductLinks {
    renderItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        return items.map(this.renderProductCard);
    }

    render() {
        const {
            areDetailsLoaded, isMobile, numberOfProductsToDisplay, isMobileOnethird,
            numberOfProductsToDisplayInMobile
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="ProductLinks" elem="Slider" mods={ { isMobileOnethird } }>
                    <SlickSlider
                        // eslint-disable-next-line no-magic-numbers
                      slidesToShow={ isMobile ? numberOfProductsToDisplayInMobile > 0 ? numberOfProductsToDisplayInMobile : 1 : numberOfProductsToDisplay }
                      slidesToScroll={ isMobile ? 1 : numberOfProductsToDisplay }
                      isInfinite
                      dots={ false }
                      isCenterMode={ isMobile && isMobileOnethird }
                      centerPadding="15%"
                      isArrows={ !(isMobile && isMobileOnethird) }
                    >
                        { this.renderItems() }
                    </SlickSlider>
                </ul>
            </ContentWrapper>
        );
    }
}

export default ProductLinksComponent;
