import getStore from 'Util/Store';

import { CART_UPDATED } from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import { eventTrack } from '../helper/WebEngage/Event';

const changeItemQty = async (args, callback) => {
    try {
        await callback(...args);
        const state = getStore().getState();
        const cartData = state.CartReducer.cartTotals;
        const { items = [], subtotal } = cartData;

        eventTrack(CART_UPDATED, {
            'No. of Products': items.length,
            'Total Amount': subtotal,
            'Product Details': getCartProductsDetailsInArray(items)
        });
    // eslint-disable-next-line no-empty
    } catch (error) {

    }
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            changeItemQty
        }
    }
};
