/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class OrderQuery {
    /**
     * Get base order info fields
     * @returns [Field]
     * @private
     */
    _aroundGetBaseOrderInfoFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'status_can_be_returned',
        'exchange_order_status',
        'order_valid_forrma',
        'store_id',
        'order_type',
        'coupncode',
        'coupncodevalue',
        'giftcard_code',
        'gifrcardamount',
        'giftcard_used_status'
    ];

    /**
     * Get order products fields
     * @returns [Field]
     * @private
     */
    _aroundGetOrderProductsFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'order_item_id',
        'qty_available_to_return',
        'product_sku',
        'can_return'
    ];
}

export const { _aroundGetBaseOrderInfoFields, _aroundGetOrderProductsFields } = new OrderQuery();

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemsFields: [
                {
                    position: 100,
                    implementation: _aroundGetBaseOrderInfoFields
                }
            ],
            _getOrderItemProductsFieldsForListing: [
                {
                    position: 100,
                    implementation: _aroundGetOrderProductsFields
                }
            ]
        }
    }
};
