export const orderQtyTranslationMap = {
    quantity_ordered: __('Ordered'),
    quantity_canceled: __('Canceled'),
    quantity_invoiced: __('Invoiced'),
    quantity_refunded: __('Refunded'),
    quantity_returned: __('Returned'),
    quantity_shipped: __('Shipped')
};

export const REMOVE_CANCEL_ORDER_ITEM_POPUP_ID = 'remove-cancel-order-item-popup-id';
