import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { showPopup } from 'Store/Popup/Popup.action';
import ProductReducer from 'Store/Product/Product.reducer';
import { ProductType } from 'Type/ProductList.type';
import { withReducers } from 'Util/DynamicReducer';

import { PRODUCT_SIZE_CHART_POPUP_ID } from '../ProductSizeChartPopup/ProductSizeChartPopup.config';
import SizeGuideButton from './SizeGuideButton.component';

/** @namespace myApp/Component/SizeGuideButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // TODO extend mapStateToProps
    product: state.ProductReducer.product
});

/** @namespace myApp/Component/SizeGuideButton/Container/mapDispatchToProps */

export const mapDispatchToProps = (dispatch) => ({
    showProductSizeChartPopup: (payload) => dispatch(showPopup(PRODUCT_SIZE_CHART_POPUP_ID, payload))
});

/** @namespace myApp/Component/SizeGuideButton/Container */
export class SizeGuideButtonContainer extends PureComponent {
    static propTypes = {
        product: PropTypes.objectOf(ProductType).isRequired,
        showProductSizeChartPopup: PropTypes.func.isRequired,
        isSizeGuide: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        isReady: PropTypes.bool.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        showProductSizeChart: this.showProductSizeChart.bind(this)
        // isSizeGuide: this.isSizeGuide.bind(this)
    };

    showProductSizeChart() {
        const { showProductSizeChartPopup } = this.props;

        showProductSizeChartPopup();
    }

    containerProps = () => {
        const { product, isReady } = this.props;

        return {
            product,
            isReady
        };
    };

    render() {
        return <SizeGuideButton { ...this.containerFunctions } { ...this.containerProps() } />;
    }
}

export default withReducers({
    ProductReducer
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(SizeGuideButtonContainer)));
