import { PRODUCT_SEARCHED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidMount = (args, callback, instance) => {
    callback(...args);

    setTimeout(() => {
        const {
            match: { params: { query } }, totalItems
        } = instance.props;

        eventTrack(PRODUCT_SEARCHED, {
            'Search Keyword': query,
            'Item Count': totalItems
        });
    // eslint-disable-next-line no-magic-numbers
    }, 5000);
};

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    setTimeout(() => {
        const [prevProps] = args;

        const {
            match: { params: { query } }, totalItems
        } = instance.props;

        const {
            match: { params: { query: prevQuery } }
        } = prevProps;

        if (query !== prevQuery) {
            eventTrack(PRODUCT_SEARCHED, {
                'Search Keyword': query,
                'Item Count': totalItems
            });
        }
    // eslint-disable-next-line no-magic-numbers
    }, 5000);
};

export default {
    'Route/SearchPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};
