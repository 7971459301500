/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// import { customerInformationFields } from 'src/component/MyAccountCustomerForm/MyAccountCustomerForm.form.js';
import Breadcrumbs from 'Component/Breadcrumbs';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Form from 'Component/Form';
import TextPlaceholder from 'Component/TextPlaceholder';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';
import { isSignedIn } from 'Util/Auth';
import { formatPrice } from 'Util/Price';
import { VALIDATION_INPUT_TYPE, VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import GiftAddToCartPopup from '../GiftAddToCartPopup';

import './GiftCardAction.style';

/** @namespace GiftCard/Component/GiftCardAction/Component */
export class GiftCardActionComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        device: DeviceType,
        currenyCode: PropTypes.string,
        handleGiftCardSubmit: PropTypes.func.isRequired,
        isAdding: PropTypes.bool.isRequired,
        formfields: PropTypes.isRequired,
        isUpdateGiftCard: PropTypes.isRequired,
        payload: PropTypes.objectOf.isRequired

    };

    static defaultProps = {
        device: {},
        currenyCode: ''
    };

    state = {
        isOtherAmountChecked: false,
        selectedAmount: '',
        selectedOtherAmount: '',
        isUpdateLabel: false,
        quantity: 1,
        name: '',
        recipientName: '',
        recipientEmail: '',
        message: ''

    };

    componentDidMount() {
        const { payload } = this.props;
        const { product: { mageworx_gc_additional_price } } = this.props;

        if (payload) {
            const info = payload.cartItem.giftcardproductinfo[0];
            this.setState({ quantity: payload.quantity });
            this.setState({ name: info.mail_from });
            this.setState({ recipientName: info.mail_to });
            this.setState({ recipientEmail: info.mail_to_email });
            this.setState({ message: info.mail_message });
        }

        if (payload) {
            if (payload.cartItem.price && mageworx_gc_additional_price.find((elem) => Number(elem.value) === Number(payload.cartItem.price))) {
                this.setState({
                    selectedAmount: payload.cartItem.price,
                    isOtherAmountChecked: false,
                    selectedOtherAmount: ''
                });
            } else {
                this.setState({
                    selectedAmount: 'other_amount',
                    isOtherAmountChecked: true,
                    selectedOtherAmount: payload.cartItem.price
                });
            }
        }
    }

    componentDidUpdate() {
        const { formfields } = this.props;
        const {
            selectedAmount,
            selectedOtherAmount,
            name,
            recipientName,
            recipientEmail,
            message,
            quantity
        } = this.state;

        if ((selectedAmount === 'other_amount' && Number(selectedOtherAmount) !== Number(formfields.price)) || (selectedAmount !== 'other_amount' && Number(selectedAmount) !== Number(formfields.price))) {
            this.setState({ isUpdateLabel: true });
        }

        if (formfields.mail_from !== name || formfields.mail_to !== recipientName || formfields.mail_to_email !== recipientEmail || formfields.mail_message !== message || formfields.quantity !== quantity) {
            this.setState({ isUpdateLabel: true });
        }
    }

    renderPopup() {
        return (
            <GiftAddToCartPopup />
        );
    }

    renderBreadcrumbs() {
        return (
            <div block="Custom" elem="Breadcrumbs">
                <Breadcrumbs />
            </div>
        );
    }

    renderName() {
        const { product: { name } } = this.props;

        return (
            <h1 block="GiftCardAction" elem="Title" itemProp="name">
                <TextPlaceholder content={ name } length="medium" />
            </h1>
        );
    }

    renderPriceRange() {
        const { product: { mageworx_gc_open_amount_min, mageworx_gc_open_amount_max }, currenyCode } = this.props;

        return (
                <span block="GiftCardAction" elem="PriceRange">
                    { /* eslint-disable-next-line max-len */ }
                    { `(From ${formatPrice(mageworx_gc_open_amount_min, currenyCode)} to ${formatPrice(mageworx_gc_open_amount_max, currenyCode) })` }
                </span>
        );
    }

    renderGiftAmountList() {
        const { product: { mageworx_gc_additional_price } } = this.props;
        const { selectedAmount } = this.state;

        if (!mageworx_gc_additional_price) {
            return null;
        }

        return (
            mageworx_gc_additional_price.map((list) => (
               <Field
                 type={ FIELD_TYPE.radio }
                 label={ list.label }
                 attr={ {
                     value: list.value,
                     name: 'amountOption',
                     checked: Number(selectedAmount) === list.value
                 } }
                 events={ { onChange: this.uncheckOtherAmount } }

               />

            ))
        );
    }

    renderCustomGiftAmountInput() {
        const { isOtherAmountChecked, selectedOtherAmount } = this.state;
        const {
            currenyCode, product: { mageworx_gc_open_amount_max, mageworx_gc_open_amount_min }
        } = this.props;

        if (isOtherAmountChecked) {
            return (
            <div block="GiftCardAction" elem="CustomPrice">
            <Field
              type={ FIELD_TYPE.text }
              label="Desired Amount"
              attr={ {
                  name: 'otherAmount',
                  value: selectedOtherAmount,
                  // eslint-disable-next-line max-len
                  placeholder: `Enter Amount from ${formatPrice(mageworx_gc_open_amount_min, currenyCode)} to ${formatPrice(mageworx_gc_open_amount_max, currenyCode) }`
              } }
              events={ { onChange: this.onHandleDesiredAmountChange } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: true,
                  range: {
                      min: mageworx_gc_open_amount_min,
                      max: mageworx_gc_open_amount_max
                  }
              } }
              addRequiredTag
            />
            </div>
            );
        }

        return null;
    }

    checkOtherAmount = (e) => {
        this.setState({ isOtherAmountChecked: true, selectedAmount: e.target.value });
    };

    uncheckOtherAmount = (e) => {
        this.setState({ isOtherAmountChecked: false, selectedAmount: e.target.value });
    };

    onHandleDesiredAmountChange = (e) => {
        this.setState({
            selectedOtherAmount: e.target.value
        });
    };

    renderCustomGiftAmount() {
        const { selectedAmount } = this.state;

        return (
            <Field
              type={ FIELD_TYPE.radio }
              label="Desired Amount"
              events={ { onChange: this.checkOtherAmount } }
              attr={ {
                  name: 'amountOption',
                  placeholder: __('Desired Amount'),
                  value: 'other_amount',
                  checked: selectedAmount === 'other_amount'
              } }
            />
        );
    }

    renderGiftAmout() {
        return (
            <div block="GiftCardAction" elem="ListBox">
                <h4 block="GiftCardAction" elem="SubTitle">
                    Please select an amount&nbsp;
                    { this.renderPriceRange() }
                </h4>
                    <div block="GiftCardAction" elem="PriceList">
                    <FieldGroup
                      key="amountOption"
                      attr={ {
                          name: 'amountOption'
                      } }
                      validationRule={ {
                          isRequired: true
                      } }
                      validateOn={ ['onChange'] }
                    >
                        { this.renderGiftAmountList() }
                        { this.renderCustomGiftAmount() }
                    </FieldGroup>
                    </div>
                    { this.renderCustomGiftAmountInput() }
            </div>
        );
    }

    onHandleNameChange = (e) => {
        this.setState({ name: e.target.value });
    };

    onHandleRecipientNameChange = (e) => {
        this.setState({ recipientName: e.target.value });
    };

    onHandleRecipientEmailChange = (e) => {
        this.setState({ recipientEmail: e.target.value });
    };

    onHandleMessageChange = (e) => {
        this.setState({ message: e.target.value });
    };

    // get customerInformationFieldMap() {
    //     const {
    //         customer: {
    //             firstname = ''
    //         }
    //     } = this.props;

    //     return customerInformationFields({
    //         firstname
    //     });
    // }

    renderSendInformationFrom() {
        if (isSignedIn()) {
            const localStorageCustomer = JSON.parse(localStorage.getItem('customer'));
            const firstName = localStorageCustomer.data.firstname;
            return (
                <>
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Name') }
              attr={ {
                  id: 'fromName',
                  name: 'fromName',
                  //   autocomplete: 'given-name',
                  //   placeholder: __('Name'),
                  defaultValue: firstName || ''
                  //   value: this.state.name
              } }
              events={ { onChange: this.onHandleNameChange } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                  isRequired: true
              } }
              addRequiredTag
            />
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Recipient name') }
              attr={ {
                  id: 'toName',
                  name: 'toName',
                  placeholder: __('Recipient name'),
                  autocomplete: 'given-name',
                  value: this.state.recipientName
              } }
              events={ { onChange: this.onHandleRecipientNameChange } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                  isRequired: true
              } }
              addRequiredTag
            />
            <Field
              type={ FIELD_TYPE.email }
              label={ __('Recipient Email') }
              attr={ {
                  id: 'email',
                  name: 'email',
                  placeholder: __('Recipient Email'),
                  autocomplete: 'given-name',
                  value: this.state.recipientEmail
              } }
              events={ { onChange: this.onHandleRecipientEmailChange } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE.email,
                  isRequired: true
              } }
              addRequiredTag
            />
            <Field
              type={ FIELD_TYPE.textarea }
              label={ __('Message') }
              attr={ {
                  id: 'message',
                  name: 'message',
                  placeholder: __('Enter Message'),
                  autocomplete: 'given-name',
                  value: this.state.message
              } }
              events={ { onChange: this.onHandleMessageChange } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  isRequired: true
              } }
              addRequiredTag
            />
                </>
            );
        }

        return (
            <>
        <Field
          type={ FIELD_TYPE.text }
          label={ __('Name') }
          attr={ {
              id: 'fromName',
              name: 'fromName',
              autocomplete: 'given-name',
              placeholder: __('Name'),
              value: this.state.name
          } }
          events={ { onChange: this.onHandleNameChange } }
          validateOn={ ['onChange'] }
          validationRule={ {
              inputType: VALIDATION_INPUT_TYPE.alphaSpace,
              isRequired: true
          } }
          addRequiredTag
        />
        <Field
          type={ FIELD_TYPE.text }
          label={ __('Recipient name') }
          attr={ {
              id: 'toName',
              name: 'toName',
              placeholder: __('Recipient name'),
              autocomplete: 'given-name',
              value: this.state.recipientName
          } }
          events={ { onChange: this.onHandleRecipientNameChange } }
          validateOn={ ['onChange'] }
          validationRule={ {
              inputType: VALIDATION_INPUT_TYPE.alphaSpace,
              isRequired: true
          } }
          addRequiredTag
        />
        <Field
          type={ FIELD_TYPE.email }
          label={ __('Recipient Email') }
          attr={ {
              id: 'email',
              name: 'email',
              placeholder: __('Recipient Email'),
              autocomplete: 'given-name',
              value: this.state.recipientEmail
          } }
          events={ { onChange: this.onHandleRecipientEmailChange } }
          validateOn={ ['onChange'] }
          validationRule={ {
              inputType: VALIDATION_INPUT_TYPE.email,
              isRequired: true
          } }
          addRequiredTag
        />
        <Field
          type={ FIELD_TYPE.textarea }
          label={ __('Message') }
          attr={ {
              id: 'message',
              name: 'message',
              placeholder: __('Enter Message'),
              autocomplete: 'given-name',
              value: this.state.message
          } }
          events={ { onChange: this.onHandleMessageChange } }
          validateOn={ ['onChange'] }
          validationRule={ {
              isRequired: true
          } }
          addRequiredTag
        />
            </>
        );
    }

    renderSendInformation() {
        return (
            <div block="GiftCardAction" elem="SendInformation">
                <h4 block="GiftCardAction" elem="SubTitle">Send Information</h4>
                { this.renderSendInformationFrom() }
            </div>
        );
    }

    onHandleQuantityChanger = (value) => {
        this.setState({
            quantity: value
        });
    };

    renderQuantityChanger() {
        const {
            product: {
                stock_item,
                type_id
            }
        } = this.props;

        const stock = stock_item?.inStock;

        const maxQuantity = 9;
        const minQuantity = 1;

        const selectOptions = [];
        // eslint-disable-next-line fp/no-let
        for (let i = minQuantity; i <= maxQuantity; i++) {
            selectOptions.push({
                id: i,
                value: i,
                label: i.toString()
            });
        }

        if (type_id === 'mageworx_giftcards') {
            // return (
            //     <Field
            //       type={ FIELD_TYPE.text }
            //       label={ __('Qty') }
            //       attr={ {
            //           id: 'item_qty',
            //           name: 'item_qty',
            //           value: '1',
            //           defaultValue: '1'

            //       } }
            //       validationRule={ {
            //           inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
            //           isRequired: true
            //       } }
            //       isDisabled={ !stock }
            //       mix={ { block: this.className, elem: 'Qty' } }
            //     />
            // );
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Qty') }
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
                  value: this.state.quantity,
                  defaultValue: this.state.quantity

              } }
              events={ { onChange: this.onHandleQuantityChanger } }
              options={ selectOptions }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: true,
                  range: {
                      min: minQuantity,
                      max: maxQuantity
                  }
              } }
              isDisabled={ !stock }
              mix={ { block: this.className, elem: 'Qty' } }
              validateOn={ ['onChange'] }
            />
        );
    }

    renderAddToGiftCard() {
        const { isAdding } = this.props;

        return (
            <button block="Button AddToCart ProductActions" elem="AddToCart" disabled={ isAdding }>
                 <span>{ isAdding ? __('Adding...') : __('Add to Bag') }</span>
            </button>
        );
    }

    renderUpdateToGiftCard() {
        const { isAdding } = this.props;
        const { isUpdateLabel } = this.state;

        if (isUpdateLabel) {
            return (
            <button block="Button AddToCart ProductActions" elem="AddToCart" disabled={ isAdding }>
                 <span>{ isAdding ? __('Adding...') : __('Add to Bag') }</span>
            </button>
            );
        }

        return (
            <button block="Button AddToCart ProductActions" elem="AddToCart" disabled={ isAdding }>
                 <span>{ isAdding ? __('Updating...') : __('Update') }</span>
            </button>
        );
    }

    renderAddToGiftCartActionBlock() {
        const { isUpdateGiftCard } = this.props;
        return (
            <div block="ProductActions" elem="AddToCartWrapper">
                { this.renderQuantityChanger() }
                { isUpdateGiftCard ? this.renderUpdateToGiftCard() : this.renderAddToGiftCard() }
            </div>
        );
    }

    renderDesktop() {
        const { handleGiftCardSubmit } = this.props;
        return (
            <>
                { this.renderBreadcrumbs() }
                { this.renderName() }
                <Form
                  key="GiftCard"
                  onSubmit={ handleGiftCardSubmit }
                >
                    { this.renderGiftAmout() }
                    { this.renderSendInformation() }
                    { this.renderAddToGiftCartActionBlock() }
                </Form>

            </>
        );
    }

    renderMobile() {
        const { handleGiftCardSubmit } = this.props;
        return (
            <>
            { this.renderBreadcrumbs() }
            { this.renderName() }
            <Form
              key="GiftCard"
              onSubmit={ handleGiftCardSubmit }
            >
                { this.renderGiftAmout() }
                { this.renderSendInformation() }
                { this.renderAddToGiftCartActionBlock() }
            </Form>
            </>
        );
    }

    render() {
        const { device: { isMobile } = {} } = this.props;

        return (
            <article block="GiftCardAction">
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { isMobile ? this.renderMobile() : this.renderDesktop() }
                { this.renderPopup() }
            </article>
        );
    }
}

export default GiftCardActionComponent;
