/* eslint-disable dot-notation */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import AddToCartPopupComponent from './AddToCartPopup.component';
import { ADD_TO_CART_POPUP_ID } from './AddToCartPopup.config';

/** @namespace Ambab/AddtoCartPopup/Component/AddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productData: state.PopupReducer.popupPayload[ADD_TO_CART_POPUP_ID],
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Ambab/AddtoCartPopup/Component/AddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Ambab/AddtoCartPopup/Component/AddToCartPopup/Container */
export class AddToCartPopupContainer extends PureComponent {
    static propTypes = {
        productData: PropTypes.any,
        isMobile: PropTypes.bool.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        productData: {}
    };

    containerFunctions = {
        onClickViewBagAndCheckout: this.onClickViewBagAndCheckout.bind(this),
        onClickContinueShopping: this.onClickContinueShopping.bind(this)
    };

    containerProps() {
        const {
            hideActiveOverlay, productData, isMobile
        } = this.props;

        return {
            hideActiveOverlay,
            productData,
            isMobile
        };
    }

    onClickViewBagAndCheckout() {
        const { hideActiveOverlay } = this.props;
        history.push(appendWithStoreCode('/cart'));
        hideActiveOverlay();
    }

    onClickContinueShopping() {
        const { hideActiveOverlay } = this.props;
        // history.push(appendWithStoreCode('/'));
        hideActiveOverlay();
    }

    render() {
        // const { productData, isMobile } = this.props;
        return (
            <AddToCartPopupComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopupContainer);
