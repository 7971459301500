import { Field } from 'Util/Query';

/** @namespace myApp/Query/ThankyouPageOrder/Query */
export class ThankyouPageOrderQuery {
    getSalesOrderQuery(orderId) {
        return new Field('salesOrder')
            .addArgument('id', 'String!', orderId)
            .addFieldList([
                'increment_id',
                'store_id',
                'type',
                'customer_name',
                'subtotalincltax',
                'shippingamount',
                'grand_total',
                'is_guest_customer',
                'created_at',
                'shipping_method',
                'coupncode',
                'coupncodevalue',
                'giftcard_code',
                'gifrcardamount',
                'guest_giftcard_used_status',
                this.getShippingAddressQuery(),
                this.getBillingAddressQuery(),
                this.getSalesOrderItem()
            ]);
    }

    getShippingAddressQuery() {
        return new Field('shipping')
            .addFieldList([
                'firstname',
                'street',
                'city',
                'region',
                'country_id',
                'postcode',
                'telephone',
                'fax',
                'company'
            ]);
    }

    getBillingAddressQuery() {
        return new Field('billing')
            .addFieldList([
                'firstname',
                'street',
                'city',
                'region',
                'country_id',
                'postcode',
                'telephone',
                'fax',
                'company'
            ]);
    }

    getSalesOrderItem() {
        return new Field('items')
            .addFieldList([
                'title',
                'sku',
                'price',
                'qty_ordered',
                'product_image',
                'size',
                'color',
                'order_item_id',
                'guest_can_return',
                'guest_qty_available_to_return',
                'cross_sell_category'
            ]);
    }
}

export default new ThankyouPageOrderQuery();
