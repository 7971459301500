/* eslint-disable no-empty-pattern */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import {
    MyAccountOrderTotals as SourceMyAccountOrderTotals
} from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.component';

// import { formatPrice } from 'Util/Price';
import './MyAccountOrderTotals.override.style';

/** @namespace myApp/Component/MyAccountOrderTotals/Component */
export class MyAccountOrderTotalsComponent extends SourceMyAccountOrderTotals {
    renderPriceLine(title, price, currency, mix = {}, key) {
        const {
            // total: { grand_total: { currency: defaultCurrency } }
            // colSpanLabelCount,
            // colSpanPriceCount
        } = this.props;

        return (
            <div block="MyAccountOrderTotals" elem="Subtotal" mix={ mix } key={ key }>
                <span block="label">{ title }</span>
                <span>
₹
{ price }
                </span>
            </div>
        );
    }

    renderCouponcode() {
        const {
            order: {
                coupncode, coupncodevalue, giftcard_code, gifrcardamount
            }
        } = this.props;

        return (
            <div>
                { coupncode && coupncodevalue && (
                    <>
                    <div block="MyAccountOrderTotals" elem="Subtotal">
                        <span block="label">{ `${'Coupon Discount'}` }</span>
                        <span>{ coupncodevalue }</span>
                    </div>
                    <div block="MyAccountOrderTotals" elem="Subtotal">
                        <span block="label">{ `(${coupncode.toUpperCase()})` }</span>
                    </div>
                    </>
                ) }
                { giftcard_code && gifrcardamount && (
                    <>
                <div block="MyAccountOrderTotals" elem="Subtotal">
                    <span block="label">{ `${'Gift Card Discount'}` }</span>
                    <span>{ gifrcardamount }</span>
                </div>
                 <div block="MyAccountOrderTotals" elem="Subtotal">
                 <span block="label">{ `(${giftcard_code})` }</span>
                 </div>
                    </>
                ) }
            </div>
        );
    }

    renderContent() {
        const {
            total: {
                subtotal: {
                    value: subtotalPrice
                },
                // shipping_handling: {
                //     total_amount: {
                //         value: shippingHandlingPrice
                //     }
                // },
                // grand_total: {
                //     value: grandTotalPrice
                // },
                // total_tax: {
                //     value: totalTaxPrice
                // },
                base_grand_total: {
                    value: baseGrandTotalPrice,
                    currency: baseGrandTotalCurrency
                }
            }
        } = this.props;

        // const grandTotalMix = { block: 'MyAccountOrderTotals', elem: 'GrandTotal' };

        return (
            <>
                { this.renderPriceLine(__('Subtotal'), subtotalPrice) }
                { this.renderCouponcode() }
                <div block="MyAccountOrderTotals" elem="Subtotal">
                    <span block="label">Shipping</span>
                    <span>Free</span>
                </div>
                { /* { this.renderDiscounts() } */ }
                { /* { this.renderPriceLine(__('Shipping & Handling'), shippingHandlingPrice) } */ }
                { /* { this.renderPriceLine(
                    __('Grand Total (Excl.Tax)'),
                    grandTotalPrice - totalTaxPrice,
                    null,
                    grandTotalMix
                ) } */ }
                { /* { this.renderTaxes() } */ }
                { /* { this.renderPriceLine(__('Tax'), totalTaxPrice) } */ }
                { /* { this.renderPriceLine(__('Grand Total (Incl.Tax)'), grandTotalPrice, null, grandTotalMix) } */ }
                <div block="MyAccountOrderTotals" elem="TotalPrice">
                { this.renderPriceLine(__(' Total'), baseGrandTotalPrice, baseGrandTotalCurrency) }
                </div>
            </>
        );
    }

    render() {
        const { total } = this.props;

        if (!total) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderTotals"
              elem="Wrapper"
            >
                <h4 block="MyAccountOrderTotals" elem="Title">Order Summary </h4>
                { this.renderContent() }
            </div>
        );
    }
}

export default MyAccountOrderTotalsComponent;
