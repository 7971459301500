import { Field, Fragment } from 'Util/Query';

const _getMageworxGcAdditionalPriceField = () => new Field('mageworx_gc_additional_price').addFieldList([
    'label',
    'value'
]);

const _getMageWorxGiftCardsFragement = () => new Fragment('MageWorxGiftCards').addFieldList([
    'mageworx_gc_type',
    'mageworx_gc_allow_open_amount',
    'mageworx_gc_open_amount_min',
    'mageworx_gc_open_amount_max',
    'amount_display_mode',
    _getMageworxGcAdditionalPriceField()
]);

const _aroundGetProductInterfaceFields = (args, callback, _instance) => {
    const [isVariant] = args;
    const {
        isSingleProduct
    } = _instance.options;

    const fields = callback(...args);

    if (isSingleProduct && !isVariant) {
        fields.push(
            _getMageWorxGiftCardsFragement()
        );
    }

    return fields;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: {
                position: 100,
                implementation: _aroundGetProductInterfaceFields
            }
        }
    }
};
