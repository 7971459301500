import { ListIcon as SourceListIcon } from 'SourceComponent/ListIcon/ListIcon.component';

import './ListIcon.style';

/** @namespace myApp/Component/ListIcon/Component */
export class ListIconComponent extends SourceListIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="ListIcon"
              mods={ { isActive } }
              width="20"
              height="18"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <line y1="1" x2="16" y2="1" stroke="#fff" strokeWidth="2" />
                <line y1="6" x2="16" y2="6" stroke="#fff" strokeWidth="2" />
                <line y1="11" x2="16" y2="11" stroke="#fff" strokeWidth="2" />
            </svg>
        );
    }
}

export default ListIconComponent;
