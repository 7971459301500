/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace myApp/Util/Manipulations/Date/convertStringToDate */
export const convertStringToDate = (stringDate, options) => {
    const defaultOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const date = new Date(stringDate.replace(/\s/, 'T'));
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return date.toLocaleDateString(language, options || defaultOptions);
};

/** @namespace myApp/Util/Manipulations/Date/convertToDate */
export const convertToDate = (stringDate) => {
    const date = new Date(stringDate.replace(/\s/, '/'));

    return date.toLocaleDateString('en-GB');
};
/** @namespace myApp/Util/Manipulations/Date/toDateString */
export const toDateString = (rawDate = '', locale = true) => {
    if (locale) {
        return new Date(rawDate).toLocaleDateString();
    }

    return new Date(rawDate).toDateString();
};
