/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React from 'react';

import MyAccountCustomerTable from 'Component/MyAccountCustomerTable/MyAccountCustomerTable.component';
import { CustomerType } from 'Type/Account.type';

/** @namespace Rma/Component/MyAccountNewReturnCustomerTable/Component */
export class MyAccountNewReturnCustomerTableComponent extends MyAccountCustomerTable {
    static propTypes = {
        customer: CustomerType.isRequired
    };

    render() {
        const { customer } = this.props;

        return (
            <div
              block="MyAccountNewReturnCustomerTable"
              elem="MainWrapper"
            >
                <p>
                    Name:
                    { customer.firstname }
                    { /* { customer.lastname } */ }
                </p>

                <p>
                    Email:
                    { customer.email }
                </p>
            </div>
        );
    }
}

export default MyAccountNewReturnCustomerTableComponent;
