import { lazy, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import NoMatchHandler from 'Route/NoMatchHandler';

import GiftCardAction from '../component/GiftCardAction';

export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
));

export class ProductPageComponentPlugin {
    _aroundRenderProductPageContent = (args, callback, instance) => {
        const {
            dataSource,
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant
        } = instance.props;

        if (dataSource.type_id === 'mageworx_giftcards') {
            return (
                <Suspense fallback={ <Loader /> }>

                        <ProductGallery
                          product={ activeProduct }
                          areDetailsLoaded={ areDetailsLoaded }
                          isWithEmptySwitcher={ useEmptyGallerySwitcher }
                          showLoader={ isVariant }

                        />
                     <GiftCardAction
                       product={ dataSource }
                       areDetailsLoaded={ areDetailsLoaded }
                     />
                </Suspense>
            );
        }

        return callback(...args);
    };

    _aroundRender = (args, callback, instance) => {
        const { dataSource } = instance.props;

        if (dataSource.type_id === 'mageworx_giftcards') {
            return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="https://schema.org/Product"
                  mods={ { isGiftCardPage: dataSource.type_id === 'mageworx_giftcards' } }
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { instance.renderProductPageContent() }
                    </ContentWrapper>
                </main>
            </NoMatchHandler>
            );
        }

        return callback(...args);
    };
}

const { _aroundRender, _aroundRenderProductPageContent } = new ProductPageComponentPlugin();

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render: _aroundRender,
            renderProductPageContent: _aroundRenderProductPageContent
        }
    }
};
