/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import {
    MyAccountTabList as SourceMyAccountTabList
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';

import './MyAccountTabList.override.style';

/** @namespace myApp/Component/MyAccountTabList/Component */
export class MyAccountTabListComponent extends SourceMyAccountTabList {
    renderTabListItem(tabEntry, index, tabArray) {
        // eslint-disable-next-line fp/no-let
        let { activeTab } = this.props;
        const { onTabClick } = this.props;
        const [key, tab] = tabEntry;
        const { section } = tab;
        const nextTab = tabArray[index + 1] || [];
        const { section: nextTabSection = section } = nextTab[1] || {};

        if (activeTab === 'my-returns') {
            activeTab = 'my-orders';
        }

        return (
            <MyAccountTabListItem
              key={ key }
              isActive={ activeTab === key }
              changeActiveTab={ onTabClick }
              tabEntry={ tabEntry }
            >
                { nextTabSection !== section ? this.renderLine() : null }
            </MyAccountTabListItem>
        );
    }
}

export default MyAccountTabListComponent;
