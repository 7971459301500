import BrowserDatabase from 'Util/BrowserDatabase';

import { SET_CURRENT_ZIPCODE, SET_ZIPCODE_PRODUCT_SEVICEABILITY } from './ZipCodeServiceability.action';

export const ZIPCODE = 'zipcode';
export const ZIPCODE_PRODUCT_SERVICEABILITY = 'zipcode_product_serviceabiltiy';

/** @namespace myApp/Store/ZipCodeServiceability/Reducer/getInitialState */
export const getInitialState = () => ({
    zipCodeProductServiceabilityData: BrowserDatabase.getItem(ZIPCODE_PRODUCT_SERVICEABILITY) || {},
    zipCode: BrowserDatabase.getItem(ZIPCODE) || ''
});

/** @namespace myApp/Store/ZipCodeServiceability/Reducer/ZipCodeServiceabilityReducer */
export const ZipCodeServiceabilityReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_CURRENT_ZIPCODE:
        const { zipCode } = action;
        return {
            ...state,
            zipCode
        };
    case SET_ZIPCODE_PRODUCT_SEVICEABILITY:
        return {
            ...state
            // TODO implement payload handling
        };

    default:
        return state;
    }
};

export default ZipCodeServiceabilityReducer;
