import { lazy, Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';

import GiftCardAction from '../component/GiftCardAction';

export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
));
export class CartItemEditComponentPlugin {
    _aroundRenderProductPageContent(args, callback, instance) {
        const {
            dataSource,
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
            payload
        } = instance.props;

        if (dataSource.type_id === 'mageworx_giftcards') {
            return (
                <Suspense fallback={ <Loader /> }>
                        <ProductGallery
                          product={ activeProduct }
                          areDetailsLoaded={ areDetailsLoaded }
                          isWithEmptySwitcher={ useEmptyGallerySwitcher }
                          showLoader={ isVariant }

                        />
                     <GiftCardAction
                       product={ dataSource }
                       areDetailsLoaded={ areDetailsLoaded }
                       payload={ payload }
                       isUpdateGiftCard
                     />
                </Suspense>
            );
        }

        return callback(...args);
    }
}

const { _aroundRenderProductPageContent } = new CartItemEditComponentPlugin();

export default {
    'myApp/Component/CartItemEdit/Component': {
        'member-function': {
            renderProductPageContent: _aroundRenderProductPageContent
        }
    }
};
