import DataContainer from 'Util/Request/DataContainer';

import ProductSizeChartPopup from './ProductSizeChartPopup.component';

/** @namespace myApp/Component/ProductSizeChartPopup/Container */
export class ProductSizeChartPopupContainer extends DataContainer {
    containerProps = () => {
        const { product, isReady } = this.props;

        return {
            product,
            isReady
        };
    };

    render() {
        return <ProductSizeChartPopup { ...this.containerProps() } />;
    }
}

export default ProductSizeChartPopupContainer;
