import { handleGAEventsTracker } from 'Util/Helper';
import getStore from 'Util/Store';

import { CART_UPDATED, SAVE_FOR_LATER, WISHLIST_UPDATED } from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import { eventTrack } from '../helper/WebEngage/Event';
import { getWishlistProducts } from '../helper/Wishlist';

const addCartItemToWishlist = (args, callback, instance) => callback(...args).then(() => {
    const {
        item: {
            qty, sku, product: { name, gender, thumbnail: { url } }, price
        }
    } = instance.props;

    const state = getStore().getState();

    const { productsInWishlist } = state.WishlistReducer;

    const wishlistTotal = Object.entries(productsInWishlist).reduce((acc, [, val]) => {
        const { price_range: { minimum_price: { final_price: { value } } } } = val;

        return acc + value;
    }, 0);
    const wishlistProduct = Object.entries(productsInWishlist).map(getWishlistProducts);

    const [wishlistItem] = Object.values(productsInWishlist).filter((i) => i.wishlist.sku === sku);

    if (wishlistItem) {
        const { wishlist: { options = [] } } = wishlistItem;

        eventTrack(SAVE_FOR_LATER, {
            Quantity: qty,
            Color: options.length > 0 ? options.filter((i) => i.label === 'Color')[0].value : '',
            Size: options.length > 0 ? options.filter((i) => i.label === 'Size')[0].value : '',
            'Product Name': name,
            Price: price,
            'Product Image': [url],
            'Product SKU': sku,
            Gender: gender || ''
        });

        eventTrack(WISHLIST_UPDATED, {
            'Total Amount': wishlistTotal,
            'No. Of Products': wishlistProduct.length,
            'Product Details': wishlistProduct
        });

        handleGAEventsTracker('event', 'add_to_wishlist', {
            currency: 'INR',
            value: price,
            items: [{
                item_id: sku,
                item_name: name,
                quantity: qty,
                price
            }]
        });
    }
});

const removeItem = (args, callback) => {
    callback(...args).then(() => {
        const state = getStore().getState();
        const cartData = state.CartReducer.cartTotals;
        const { items = [], subtotal } = cartData;

        eventTrack(CART_UPDATED, {
            'No. of Products': items.length,
            'Total Amount': subtotal,
            'Product Details': getCartProductsDetailsInArray(items)
        });
    });
};

export default {
    'Component/CartItem/Container': {
        'member-function': {
            addCartItemToWishlist
        }
    },
    'myApp/Component/CartItemRemovePopup/Container': {
        'member-function': {
            removeItem
        }
    }
};
