import transformToNameValuePair from 'Util/Form/Transform';

import { ADD_TO_CART_GIFT_CARD } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const handleGiftCardSubmit = (args, callback) => {
    callback(...args).then(() => {
        const [, fields] = args;
        const giftFieldaddToCart = transformToNameValuePair(fields);

        eventTrack(ADD_TO_CART_GIFT_CARD, {
            Amount: giftFieldaddToCart.amountOption === 'other_amount'
                ? giftFieldaddToCart.otherAmount : giftFieldaddToCart.amountOption,
            Name: giftFieldaddToCart.fromName,
            'Recipient name': giftFieldaddToCart.toName,
            Quantity: giftFieldaddToCart.item_qty,
            'Recipient Email': giftFieldaddToCart.email,
            Message: giftFieldaddToCart.message
        });
    });
};

export default {
    'GiftCard/Component/GiftCardAction/Container': {
        'member-function': {
            handleGiftCardSubmit
        }
    }
};
