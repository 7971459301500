import { ContentWrapper as SourceContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

/** @namespace myApp/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapper {
    // TODO implement logic
}

export default ContentWrapperComponent;
