import history from 'Util/History';

import { PAGE_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export function componentDidMount(args, callback) {
    callback(...args);

    const { location: { pathname } } = history;

    eventTrack(PAGE_VIEWED, {
        'Page Name': 'Sitemap',
        'Page Path': pathname
    });
}

export default {
    'SiteMap/Route/SiteMap/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
