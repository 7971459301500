import {
    MyAccountContainer
} from 'Route/MyAccount/MyAccount.container';

import { PAGE_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [, prevState] = args;

    if (prevState.activeTab && prevState.activeTab !== instance.state.activeTab) {
        const { location: { pathname } } = instance.props;
        const { activeTab } = instance.state;

        const currentTab = MyAccountContainer.tabMap[activeTab];

        eventTrack(PAGE_VIEWED, {
            'Page Name': currentTab.tabName.value,
            'Page Path': pathname
        });
    }
};

const componentDidMount = (args, callback, instance) => {
    callback(...args);
    const { location: { pathname } } = instance.props;

    const { activeTab } = instance.state;

    const currentTab = MyAccountContainer.tabMap[activeTab];

    if (activeTab) {
        eventTrack(PAGE_VIEWED, {
            'Page Name': currentTab.tabName.value,
            'Page Path': pathname
        });
    }
};

export default {
    'Route/MyAccount/Container': {
        'member-function': {
            componentDidUpdate, componentDidMount
        }
    }
};
