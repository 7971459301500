/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    UPDATE_EMAIL_AVAILABLE
} from './EmailCheck.action';

/** @namespace myApp/Store/EmailCheck/Reducer/getInitialState */
export const getInitialState = () => ({
    isEmailAvailable: null
});

/** @namespace myApp/Store/EmailCheck/Reducer/EmailCheckReducer */
export const EmailCheckReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    default:
        return state;
    }
};

export default EmailCheckReducer;
