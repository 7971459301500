/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';
import { ProductType } from 'Type/ProductList.type';

import { PRODUCT_SIZE_CHART_POPUP_ID } from './ProductSizeChartPopup.config';

import './ProductSizeChartPopup.style';

/** @namespace myApp/Component/ProductSizeChartPopup/Component */
export class ProductSizeChartPopupComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        product: PropTypes.objectOf(ProductType).isRequired,
        isReady: PropTypes.bool.isRequired
    };

    renderContent() {
        const { product: { size_chart_guide } = {}, isReady } = this.props;

        if (isReady && size_chart_guide) {
            return <CmsBlock identifier={ size_chart_guide } />;
        }

        return null;
    }

    render() {
        return (
            <div block="ProductSizeChartPopup">
                { /* TODO: Implement render method */ }
                <Popup id={ PRODUCT_SIZE_CHART_POPUP_ID } clickOutside={ false } mix={ { block: 'ProductSizeChartPopup' } }>
                    { this.renderContent() }
{ ' ' }
                </Popup>
            </div>
        );
    }
}

export default ProductSizeChartPopupComponent;
