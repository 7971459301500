import Link from 'Component/Link';

export class MyOrderTabComponentPlugin {
    tabMap = (originalMember, instance) => [
        ...originalMember,
        this.renderRmaButton.bind(this, instance)
    ];

    renderRmaButton(instance) {
        const { activeTab, totalRmaCount } = instance.props;
        return (
            <li block="ProductTab" elem="Item" mods={ { isActive: activeTab === 'rma' } }>
                <Link block="ProductTab-Button" to="/my-returns">
                    { `${__('Track Returns')} (${totalRmaCount}) ` }
                </Link>
            </li>
        );
    }
}

const { tabMap } = new MyOrderTabComponentPlugin();

export default {
    'myApp/Component/MyOrderTab/Component': {
        'member-property': {
            tabMap
        }
    }
};
