import {
    getAddressAdditionalTableFields as sourceGetAddressAdditionalTableFields,
    getAddressTablePairArray as sourceGetAddressTablePairArray
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.table';

// TODO: implement getAddressAdditionalTableFields
export const getAddressAdditionalTableFields = sourceGetAddressAdditionalTableFields;

// TODO: implement getAddressTablePairArray
export const getAddressTablePairArray = sourceGetAddressTablePairArray;
