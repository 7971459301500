/* eslint-disable radix */
import ThankyouPageOrderQuery from 'Query/ThankyouPageOrder.query';
import { fetchQuery } from 'Util/Request';

import { CHECKOUT_COMPLETED } from '../config';
import {
    getCartProductsColorsString,
    getCartProductsImagesArray,
    getCartProductsNameString,
    getCartProductsQtysString, getCartProductsSizesString, getCartProductsSkuString, getProductsGenderString
} from '../helper/Checkout';
import { eventTrack } from '../helper/WebEngage/Event';

const productDetails = (items) => items.map((product) => {
    // Ensure categories are always an array
    const categories = Array.isArray(product.cross_sell_category)
        ? product.cross_sell_category
        : [product.cross_sell_category].filter(Boolean); // Filter out null/undefined

    const categoryString = categories.join(', '); // Convert array to string

    return {
        Quantity: product.qty_ordered,
        Color: product.color,
        Size: product.size,
        'Product Name': product.title,
        'Product Image': product.product_image,
        'Product SKU': product.sku,
        'Category String': categoryString,
        'Category Array': categories
    };
});

// const getOrderLevelCategories = (items) => {
//     const allCategories = items.flatMap((product) => product.cross_sell_category || []);
//     const uniqueCategories = Array.from(new Set(allCategories));
//     return {
//         'Order Category String': uniqueCategories.join(', '),
//         'Order Category Array': uniqueCategories
//     };
// };

// Function to compute order-level categories
const getOrderLevelCategories = (items) => {
    // Collect all categories (allow duplicates)
    const allCategories = items.flatMap((product) => product.cross_sell_category || []);

    // Convert to a comma-separated string
    const categoryString = allCategories.join(', ');

    return {
        'Order Category String': categoryString,
        'Order Category Array': allCategories
    };
};

export const getThankyouPagheOrder = (args, callback, instance) => {
    const { orderID } = instance.props;
    fetchQuery(ThankyouPageOrderQuery.getSalesOrderQuery(orderID))
        .then(
            /** @namespace myApp/Component/CheckoutSuccess/Container/CheckoutSuccessContainer/getThankyouPagheOrder/fetchQuery/then */
            (data) => {
                instance.setState({ orderDetail: data });

                const {
                    salesOrder: {
                        items = [], increment_id, shippingamount, grand_total, payment_method
                    }
                } = data;

                const grandTotal = grand_total.replace(/[₹,]/g, '');
                const shippingAmount = shippingamount.replace(/[₹,]/g, '');

                const orderLevelCategories = getOrderLevelCategories(items);

                eventTrack(CHECKOUT_COMPLETED, {
                    'Order ID': increment_id,
                    'Product Name': getCartProductsNameString(items),
                    'Product SKU': getCartProductsSkuString(items),
                    'Product Image': getCartProductsImagesArray(items),
                    Color: getCartProductsColorsString(items),
                    Size: getCartProductsSizesString(items),
                    Quantity: getCartProductsQtysString(items),
                    'Product Details': productDetails(items),
                    'Payment Mode': payment_method,
                    Gender: getProductsGenderString(items),
                    'Estimated Shipping': parseInt(shippingAmount),
                    'Sub Total': parseInt(grandTotal),
                    ...orderLevelCategories
                });
            }
        );
};

export default {
    'myApp/Component/CheckoutSuccess/Container': {
        'member-function': {
            getThankyouPagheOrder
        }
    }
};
