import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import GiftAddToCartPopup from './GiftAddToCartPopup.component';
import { GIFT_CART_POPUP_ID } from './GiftAddToCartPopup.config';

/** @namespace GiftCard/Component/GiftAddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
    payload: state.PopupReducer.popupPayload[GIFT_CART_POPUP_ID] || null
});

/** @namespace GiftCard/Component/GiftAddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace GiftCard/Component/GiftAddToCartPopup/Container */
export class GiftAddToCartPopupContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        const { payload, hideActiveOverlay } = this.props;

        return {
            payload,
            hideActiveOverlay
        };
    }

    render() {
        return (
            <GiftAddToCartPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftAddToCartPopupContainer);
