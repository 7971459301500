import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace myApp/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    // getOptions() {
    //     const {
    //         options
    //     } = this.props;

    //     return Array.from(options);
    // }
}

export default FieldSelectContainer;
