/* eslint-disable eqeqeq */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { CustomerType } from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import CustomerRmaQuery from '../../query/CustomerRma.query';
import MyAccountNewReturn from './MyAccountNewReturn.component';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

/** @namespace Rma/Component/MyAccountNewReturn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Rma/Component/MyAccountNewReturn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    getOrderById: (orderId) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)
    )
});

/** @namespace Rma/Component/MyAccountNewReturn/Container */
export class MyAccountNewReturnContainer extends PureComponent {
    static propTypes = {
        customer: CustomerType.isRequired,
        showNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                orderID: PropTypes.string
            })
        }).isRequired,
        getOrderById: PropTypes.func.isRequired
    };

    state = {
        reasonData: {},
        isLoading: false,
        orderId: '',
        itemid: '',
        baseOrderInfo: {},
        items: [],
        filterItems: [],
        createdAt: '',
        shippingAddress: {}
    };

    containerFunctions = {
        onNewRequestSubmit: this.onNewRequestSubmit.bind(this),
        updateReturnAddress: this.updateReturnAddress.bind(this)
    };

    componentDidMount() {
        const {
            match: {
                params: {
                    orderId
                }
            }
        } = this.props;

        if (orderId) {
            this.requestData();
            this.requestOrderDetails();
        }
    }

    updateReturnAddress(rmaAddress) {
        const newAddress = {
            firstname: rmaAddress.firstname,
            lastname: rmaAddress.lastname,
            street: [rmaAddress.street],
            city: rmaAddress.city,
            country_id: rmaAddress.country_id,
            region_id: rmaAddress.region.region_id,
            region: rmaAddress.region.region,
            telephone: rmaAddress.telephone,
            postcode: rmaAddress.postcode
        };

        this.setState({
            shippingAddress: newAddress
        });
    }

    onError = (e) => {
        const { showNotification } = this.props;

        this.setState({ isLoading: false }, () => {
            showNotification('error', __(e[0].message));
        });
    };

    async onNewRequestSubmit(options) {
        const { showSuccessNotification, history } = this.props;

        this.setState({ isLoading: true });

        const mutation = CustomerRmaQuery.createRmaMutation(this.formatReturnRequestOption(options));
        fetchMutation(mutation).then(
            /** @namespace Rma/Component/MyAccountNewReturn/Container/MyAccountNewReturnContainer/onNewRequestSubmit/fetchMutation/then */
            ({ createRma: { rma } }) => {
                this.setState({ isLoading: false }, () => {
                    showSuccessNotification(__('Return successfully made'));
                });

                history.goBack();
            },
            this.onError
        );
    }

    formatReturnRequestOption(options) {
        const { baseOrderInfo } = this.state;
        return {
            rma_orders: [{
                order_id: options.order_id,
                store_id: baseOrderInfo.store_id,
                type: baseOrderInfo.order_type,
                items: options.items
            }],
            // rma_address: [{
            //     firstname: options.rmaAddress.firstname,
            //     lastname: options.rmaAddress.lastname,
            //     street: options.rmaAddress.street.toString(),
            //     city: options.rmaAddress.city,
            //     // company: '',
            //     country_id: options.rmaAddress.country_id,
            //     region: options.rmaAddress.region,
            //     region_id: options.rmaAddress.region_id,
            //     telephone: options.rmaAddress.telephone,
            //     postcode: options.rmaAddress.postcode
            // }],
            agree_box: true
        };
    }

    requestData() {
        const { showNotification } = this.props;

        const {
            match: {
                params: {
                    orderId
                }
            }
        } = this.props;

        const {
            location: {
                state: {
                    itemid
                }
            }
        } = this.props;

        return fetchQuery([
            CustomerRmaQuery.getRmaDictionary(orderId)
        ]).then(
            /** @namespace Rma/Component/MyAccountNewReturn/Container/MyAccountNewReturnContainer/requestData/fetchQuery/then */
            ({
                rmaDictionary: {
                    reasons,
                    resolutions,
                    conditions
                }
            }) => {
                const reasonData = { reasons, resolutions, conditions };

                this.setState({
                    reasonData,
                    orderId,
                    itemid
                });
            },
            /** @namespace Rma/Component/MyAccountNewReturn/Container/MyAccountNewReturnContainer/requestData/fetchQuery/then/showNotification/catch */
            (e) => showNotification('error', __('Error fetching New Return!'), e)
        );
    }

    async requestOrderDetails() {
        const {
            match: {
                params: {
                    orderId
                }
            },
            getOrderById
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(orderId);
        const { items, shipping_address: shippingAddress, order_date } = order;
        const { itemid } = this.state;

        const filterItems = items.filter((filterItems) => (
            filterItems.order_item_id == itemid
        ));

        if (!order) {
            return;
        }

        if (!Array.isArray(shippingAddress.street)) {
            // eslint-disable-next-line no-param-reassign
            shippingAddress.street = [shippingAddress.street];
        }

        this.setState({
            items,
            filterItems,
            shippingAddress,
            createdAt: order_date,
            baseOrderInfo: order

        });
    }

    render() {
        return (
            <MyAccountNewReturn
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewReturnContainer);
