/* eslint-disable fp/no-let */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import ProductLinks from 'Component/ProductLinks';
import { CROSS_SELL } from 'Store/LinkedProducts/LinkedProducts.reducer';

// import { ProductType } from 'Type/ProductList';
import { ADD_TO_CART_POPUP_ID } from './AddToCartPopup.config';

import './AddToCartPopup.style';

/** @namespace Ambab/AddtoCartPopup/Component/AddToCartPopup/Component */
export class AddToCartPopupComponent extends PureComponent {
  static propTypes = {
      productData: PropTypes.any,
      quantity: PropTypes.number,
      onClickViewBagAndCheckout: PropTypes.func.isRequired
  };

  static defaultProps = {
      productData: {},
      quantity: 0
  };

  renderConfigurableAttributes() {
      const {
          productData: { parameters },
          productData: { product: { attributes } }
      } = this.props;

      return Object.values(attributes).map((option) => {
          const {
              attribute_code, attribute_options, attribute_id
          } = option;

          const selectedOption = parameters[attribute_code];
          const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

          return (
            <div key={ attribute_id }>
                { attribute_code === 'size' && (
                    <p block="ProductConfigurableAttributes" elem="Size">
                      Size:
                    <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                            { selectedOptionLabel }
                    </span>
                    </p>
                ) }
            </div>
          );
      });
  }

  renderProductCard(selectProduct, _quantity) {
      return (
      <div block="Main">
        <img block="Img" src={ selectProduct.image.url } alt={ selectProduct.name } />
        <div block="ProductInfo">
          <h2 block="Main" elem="ProductName">
            { selectProduct.name }
          </h2>
          { this.renderConfigurableAttributes() }
        </div>
      </div>
      );
  }

  render() {
      const {
          productData: {
              selectProduct, quantity, parameters, product
          }, productData, onClickViewBagAndCheckout, onClickContinueShopping
      } = this.props;

      const variants = product?.variants;

      let productToRender = product;

      if (variants && Array.isArray(variants)) {
          const selectedVariant = variants.find((variant) => variant.attributes.color.attribute_value === parameters.color
        && variant.attributes.size.attribute_value === parameters.size);

          if (selectedVariant) {
              productToRender = selectedVariant;
          }
      }

      return (
      <div block="AddToCartPopup">
        <Popup
          id={ ADD_TO_CART_POPUP_ID }
          clickOutside={ false }
          mix={ { block: 'AddToCartPopup' } }
        >
          { productToRender ? this.renderProductCard(productToRender, quantity, product) : '' }
          <div block="RelatedProduct">
            <div block="InnerDiv">
              <ProductLinks
                linkType={ CROSS_SELL }
                title={ __('You might also like') }
                areDetailsLoaded
                numberOfProductsToDisplay={ 3 }
                numberOfProductsToDisplayInMobile={ 2 }
              />
            </div>
          </div>
          <div block="PopupButtons">
            <button
              onClick={ onClickContinueShopping }
              className="PopupButtons-ShoppingBtn"
            >
              Continue Shopping
            </button>
            <button
              onClick={ onClickViewBagAndCheckout }
              className="CartBtn"
              style={ { textDecoration: 'none' } }
            >
              View Bag & Checkout
            </button>
          </div>
        </Popup>
      </div>
      );
  }
}

export default AddToCartPopupComponent;
