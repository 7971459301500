// import Notification from 'Component/Notification';
import {
    NotificationList as SourceNotificationList
} from 'SourceComponent/NotificationList/NotificationList.component';

import './NotificationList.override.style';

/** @namespace myApp/Component/NotificationList/Component */
export class NotificationListComponent extends SourceNotificationList {

}

export default NotificationListComponent;
