import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NoMatchContainer as SourceNoMatchContainer
} from 'SourceRoute/NoMatch/NoMatch.container';

/** @namespace myApp/Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Route/NoMatch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Route/NoMatch/Container */
export class NoMatchContainer extends SourceNoMatchContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
