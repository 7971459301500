import '../style/_module.scss';

export class CmsBlockContainer {
  componentDidMount = (args, callback, _instance) => {
      // Id = 'html-body' is required by the Dynamic CSS selector of Magento 2 page-builder
      // Ref: https://devdocs.magento.com/page-builder/docs/styles/introduction.html#dynamic-css-selectors
      document.querySelector('body').setAttribute('id', 'html-body');
      callback(...args);
  };
}

const { componentDidMount } = new CmsBlockContainer();
export default {
    'Component/CmsBlock/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
