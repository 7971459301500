import {
    ORDER_INVOICES as SOURCE_ORDER_INVOICES,
    ORDER_ITEMS as SOURCE_ORDER_ITEMS,
    ORDER_REFUNDS as SOURCE_ORDER_REFUNDS,
    ORDER_SHIPMENTS as SOURCE_ORDER_SHIPMENTS
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.config';

// TODO: implement ORDER_ITEMS
export const ORDER_ITEMS = SOURCE_ORDER_ITEMS;

// TODO: implement ORDER_INVOICES
export const ORDER_INVOICES = SOURCE_ORDER_INVOICES;

// TODO: implement ORDER_SHIPMENTS
export const ORDER_SHIPMENTS = SOURCE_ORDER_SHIPMENTS;

// TODO: implement ORDER_REFUNDS
export const ORDER_REFUNDS = SOURCE_ORDER_REFUNDS;
