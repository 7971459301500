import HeartIcon from 'Component/HeartIcon';
import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace myApp/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    renderButton() {
        const {
            isInWishlist, mix, isLoading
        } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled: isLoading } }
              mix={ { block: 'Button', mix } }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
            </button>
        );
    }

    renderContent() {
        return (
            <div block="ProductWishlistButton">
                { this.renderButton() }
                { /* { this.renderLoader() } */ }
            </div>
        );
    }
}

export default ProductWishlistButtonComponent;
