import getStore from 'Util/Store';

import { SORT_BY } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const { sortKey: preSortKey } = prevProps;
    const { sortKey, sortDirection } = instance.props;

    if (preSortKey !== sortKey) {
        const selectOptions = instance._prepareOptions();
        const state = getStore().getState();
        const {
            category: {
                name, product_count
            }
        } = state.CategoryReducer;

        const selectedLabel = selectOptions.filter((item) => (item.value === `${sortDirection} ${sortKey}`
            ? item : null));
        const option = selectedLabel.reduce((acc, a) => (a.label ? acc + a.label : acc), []);
        eventTrack(SORT_BY, {
            'Option Selected': option,
            'Category Page': name,
            'Item Count': product_count
        });
    }
};

export default {
    'Component/CategorySort/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
