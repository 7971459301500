import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace myApp/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    renderOption(option) {
        const {
            id, label, subLabel, isPlaceholder = false, isHovered, value, isAvailable = true
        } = option;

        const {
            isExpanded,
            attr: { defaultValue = null },
            handleSelectListOptionClick
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let isSelected = false;

        if (value && defaultValue && value === defaultValue) {
            isSelected = true;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
                  isSelected
              } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${id}` }
              role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && <strong>{ ` ${subLabel}` }</strong> }
            </li>
        );
    }
}

export default FieldSelectComponent;
