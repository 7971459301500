/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import TimerButton from 'Component/TimerButton';
import { MyAccountCreateAccount as SourceMyAccountCreateAccount }
    from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import history from 'Util/History';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { CREATE_ACCOUNT, CREATE_ACCOUNT_VERIFY_OTP } from './MyAccountCreateAccount.config';

import './MyAccountCreateAccount.override.style';
/** @namespace myApp/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
    static propTypes = {
        newsletterActive: PropTypes.bool.isRequired,
        prepone: PropTypes.string,
        handleCreateAccountOTP: PropTypes.func.isRequired,
        currentCreateRenderForm: PropTypes.func.isRequired,
        onCreateAccountAttempt: PropTypes.func.isRequired,
        handleCreateAccountOTPVerify: PropTypes.func.isRequired,
        handleResendOTP: PropTypes.func.isRequired,
        formFields: PropTypes.func.isRequired,
        // isRetryForm: PropTypes.func.isRequired,
        handleRetry: PropTypes.func.isRequired
    };

    state = {
        type: 'password'
    };

    renderMap = {
        [CREATE_ACCOUNT]: this.renderSubmitButton.bind(this),
        [CREATE_ACCOUNT_VERIFY_OTP]: this.renderCreateAccountVerifyButton.bind(this)
    };

    renderFormButton() {
        const { currentCreateRenderForm } = this.props;

        return this.renderMap[currentCreateRenderForm]();
    }

    showHidePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        });
    };

    renderForm() {
        const {
            currentCreateRenderForm,
            onCreateAccountAttempt,
            handleCreateAccountOTP,
            handleCreateAccountOTPVerify
        } = this.props;

        if (currentCreateRenderForm === CREATE_ACCOUNT) {
            return (
                <Form
                  key="create-account"
                  onSubmit={ handleCreateAccountOTP }
                    // onSubmitSuccess={ handleCreateAccountOTP }
                  onError={ onCreateAccountAttempt }
                >
                    { this.renderCreateAccountPersonalInfoFields() }
                    { /* { this.renderCreateAccountSignUpInfoFields() } */ }
                    { this.renderFormButton() }
                </Form>
            );
        }

        return (
            <Form
              key="create-account-verify"
              onSubmit={ handleCreateAccountOTPVerify }
                // onSubmitSuccess={ handleCreateAccountOTPVerify }
              onError={ onCreateAccountAttempt }
            >
                { this.renderCreateAccountPersonalInfoFields() }
                { /* { this.renderCreateAccountSignUpInfoFields() } */ }
                { this.renderFormButton() }
            </Form>
        );
    }

    renderRetryButton() {
        const { handleResendOTP, isRetryForm, handleRetry } = this.props;

        if (isRetryForm) {
            return (
          <button
            block="Button"
            mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
            onClick={ handleRetry }
          >
            { __('Retry') }
          </button>
            );
        }

        return (
            <div block="ResendOtp" elem="Wrapper">
                <span>
                { __('Not received your code?') }

                </span>
          <TimerButton onClick={ handleResendOTP }>{ __('Resend OTP') }</TimerButton>
            </div>
        );
    }

    renderCreateAccountVerifyButton() {
        const { isSubmitted, formFields, newsletterActive } = this.props;

        return (
            <>
                <Field
                  type="text"
                  label={ __('Enter OTP') }
                  attr={ {
                      id: 'otp',
                      name: 'otp',
                      value: formFields.otp,
                      //   defaultValue: otp,
                      placeholder: __('Enter Otp')
                  } }
                    // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  autocomplete="given-name"
                  validation={ ['notEmpty'] }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                  isSubmitted={ isSubmitted }
                />
                { newsletterActive && this.renderSubscribeToNewsletterSecond() }
                <div block="MyAccountOverlay" elem="Buttons">
                    { this.renderRetryButton() }
                    <button block="Button" type="submit" mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }>
                        { __('Verify') }
                    </button>
                </div>
            </>
        );
    }

    static defaultProps = {
        prepone: ''
    };

    renderSubscribeToNewsletter() {
        const { currentCreateRenderForm } = this.props;
        return (
            <div block="Subscribe" elem="Wrapper" mods={ { isActive: currentCreateRenderForm !== CREATE_ACCOUNT } }>
                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ __("I'd like to receive the latest news and promotions.") }
                  attr={ {
                      id: 'is_subscribed',
                      name: 'is_subscribed',
                      placeholder: __('Your Tax/VAT Number')
                  } }
                  mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                />
            </div>
        );
    }

    renderSubscribeToNewsletterSecond() {
        const { currentCreateRenderForm } = this.props;
        return (
            <div
              block="Subscribe"
              elem="WrapperSecond"
              mods={ { isAcive: currentCreateRenderForm === CREATE_ACCOUNT_VERIFY_OTP } }
            >
                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ __("I'd like to receive the latest news and promotions.") }
                  attr={ {
                      id: 'is_subscribed',
                      name: 'is_subscribed',
                      placeholder: __('Subscribed')
                  } }
                  mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                />
            </div>
        );
    }

    renderCreateAccountPersonalInfoFields() {
        const {
            newsletterActive, isSubmitted, formFields, prefieldemail, prefieldmobile
        } = this.props;

        const {
            location: {
                state: {
                    firstname = '',
                    // dob = '',
                    // gender = '',
                    email = '',
                    mobileNumber = ''
                } = {}
            }
        } = history;
        const { type } = this.state;

        // const selectOptions = [
        //     { label: 'Male', id: 'male', value: '0' },
        //     { label: 'Female', id: 'female', value: '1' },
        //     { label: 'Others', id: 'others', value: '2' }
        // ];

        // const {
        //     location: {
        //         state: {
        //             firstname = '', lastname = '', email = '', mobileNumber = ''
        //         } = {}
        //     }
        // } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                { /* <legend>{ __('Personal Information') }</legend> */ }
                <CmsBlock identifier="register-top-desc" />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Full Name') }
                  isSubmitted={ isSubmitted }
                  attr={ {
                      id: 'firstname',
                      name: 'firstname',
                      defaultValue: firstname,
                      value: formFields.firstname,
                      placeholder: __('Your full name'),
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter valid user name!')
                      }

                  } }
                  addRequiredTag
                />
                { /* <Field
                  type={ FIELD_TYPE.date }
                  label={ __('Date of Birth') }
                  isSubmitted={ isSubmitted }
                  attr={ {
                      name: 'dob',
                      defaultValue: dob,
                      value: formFields.dob,
                      max: new Date().toISOString().split('T')[0]
                  } }
                  validationRule={ {
                      isRequired: true,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter Date of Birth')
                      }
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.select }
                  label={ __('Gender') }
                  attr={ {
                      id: 'gender',
                      name: 'gender',
                      placeholder: __('Your gender'),
                      defaultValue: gender,
                      value: formFields.gender
                  } }
                  options={ selectOptions }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter Gender')
                      }
                  } }
                  addRequiredTag
                /> */ }
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email Address') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      defaultValue: prefieldemail || email,
                      value: formFields.email,
                      placeholder: __('Your email name'),
                      autocomplete: 'email',
                      disabled: !!prefieldemail
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter valid Email!')
                      }
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Mobile Number') }
                  attr={ {
                      id: 'mobileNumber',
                      name: 'mobileNumber',
                      defaultValue: prefieldmobile || mobileNumber,
                      value: formFields.mobileNumber,
                      placeholder: __('Mobile Number'),
                      autocomplete: 'number',
                      prepone: '+91',
                      maxlength: '10',
                      disabled: !!prefieldmobile
                  } }
                    //   prepone="+91"
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.mobile,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter mobile number!')
                      }
                  } }
                  addRequiredTag
                />
                <div block="Password" elem="Wrapper">
                    <Field
                      type={ this.state.type }
                      label={ __('Password') }
                      attr={ {
                          id: 'password',
                          name: 'password',
                          placeholder: __('Enter your password'),
                          autocomplete: 'new-password',
                          value: formFields.password,
                          maxlength: '15'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,

                          match: (value) => {
                              const email = document.getElementById('email');
                              return value && email.value !== value;
                          },
                          customErrorMessages: {
                              onMatchFail: __("Passwords can't be the same as email!")
                          }
                      } }
                      addRequiredTag
                    />
                    <span
                      block="showHide"
                      elem="btn"
                        // eslint-disable-next-line react/jsx-props-no-multi-spaces
                      onClick={ this.showHidePassword }
                    >
                        { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional  */ }
                        { type === 'text' ? 'Hide' : 'Show' }
{ ' ' }
                    </span>
                </div>
                { this.renderVatNumberField() }
                { newsletterActive && this.renderSubscribeToNewsletter() }
            </fieldset>
        );
    }

    // renderCreateAccountForm() {
    //     const { onError, onSuccess } = this.props;

    //     return (
    //         <Form
    //           key="create-account"
    //           onSubmit={ onSuccess }
    //           onError={ onError }
    //         >
    //             { this.renderCreateAccountPersonalInfoFields() }
    //             { /* { this.renderCreateAccountSignUpInfoFields() } */ }
    //             { this.renderSubmitButton() }
    //         </Form>
    //     );
    // }

    renderCreateAccountForm() {
        return this.renderForm();
    }

    renderSubmitButton() {
        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button block="Button" type="submit" mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }>
                    { __('Create An Account') }
                </button>
            </div>
        );
    }

    renderAdditionalField() {
        const {
            state, handleSignIn, prefieldemail, prefieldmobile
        } = this.props;

        return (
            <>
                <div block="Terms" elem="condition">
                    { __('By creating an account, you agree to Under Armour’s ') }
                    &nbsp;
                    <Link
                      to="/customer-service/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="Privacy"
                      elem="Policy"
                    >
                        { __('Privacy Policy') }
                        &nbsp;
                    </Link>
                    and &nbsp;
                    <Link
                      to="/customer-service/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="terms"
                    >
                        { __('Terms & Conditions') }
                    </Link>
                    .
                </div>
                { !prefieldemail && !prefieldmobile && (
                <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                    <section>
                        <h4>{ __('Already have an account?') }</h4>
                        <button
                          block="Button"
                          mods={ { likeLink: true } }
                          mix={ { block: 'MyAccountOverlay', elem: 'SignInLink' } }
                          onClick={ handleSignIn }
                        >
                            { __('Log In') }
                        </button>
                    </section>
                </article>
                ) }
            </>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <>
                <Loader isLoading={ isLoading } />
                { this.renderCreateAccountForm() }
                { this.renderAdditionalField() }
            </>
        );
    }
}

export default MyAccountCreateAccountComponent;
