/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import ApplyGiftCart from '../component/ApplyGiftCart';

export class CheckoutOrderSummaryComponentPlugin {
    _aroundRenderApplyGiftCard = (args, callback, instance) => {
        const { checkoutStep, getPaymentMethods, totals } = instance.props;
        const typeId = totals?.items?.[0]?.product?.type_id;
        if (typeId === 'mageworx_giftcards') {
            return (
                <>
                { callback(...args) }

                </>
            );
        }

        return (
            <>
            { callback(...args) }
            { checkoutStep === 'BILLING_STEP' && <ApplyGiftCart getPaymentMethods={ getPaymentMethods } /> }
            </>
        );
    };
}

const { _aroundRenderApplyGiftCard } = new CheckoutOrderSummaryComponentPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTotals: _aroundRenderApplyGiftCard
        }
    }
};
