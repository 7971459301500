/* eslint-disable no-magic-numbers */
import ReactGA from 'react-ga4';

/** @namespace myApp/Util/Helper/Index/getMobileWithISD */
export const getMobileWithISD = (mobileNumber) => `91${mobileNumber}`;

/** @namespace myApp/Util/Helper/Index/handleGAEventsTracker */
export const handleGAEventsTracker = (event, view_type, data) => ReactGA.gtag(
    event, view_type, data
);

/** @namespace myApp/Util/Helper/Index/priceFormatter */
export const priceFormatter = (price) => Number(price.replace(/[^0-9.-]+/g, ''));
