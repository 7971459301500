function _getCustomerFields(args, callback, instance) {
    return [
        'created_at',
        'confirmation_required',
        'group_id',
        'prefix',
        'firstname',
        'middlename',
        'lastname',
        'suffix',
        'email',
        'default_billing',
        'default_shipping',
        'dob',
        'gender',
        'taxvat',
        'id',
        'is_subscribed',
        'mobilenumber',
        instance._getAddressesField()
    ];
}

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields
        }
    }
};
