import React from 'react';

import MyAccountDropdown from '../component/MyAccountDropdown';

export const STATE_LOGGED_IN = 'loggedIn';

export class MyAccountOverlayComponentPlugin {
    renderMap = (originalMember) => ({
        ...originalMember,
        [STATE_LOGGED_IN]: {
            render: () => this.renderMyAccountDropdown()
        }
    });

    renderMyAccountDropdown() {
        return (
            <MyAccountDropdown />
        );
    }
}

const { renderMap, renderMyAccountDropdown } = new MyAccountOverlayComponentPlugin();

export default {
    'Component/MyAccountOverlay/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderMyAccountDropdown
        }
    }
};
