/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';

import MyAccountNewReturnAddressTable from '../MyAccountNewReturnAddressTable';
// import MyAccountNewReturnCustomerTable from '../MyAccountNewReturnCustomerTable';
// import MyAccountReturnDetailsChat from '../MyAccountReturnDetailsChat';
import MyAccountReturnDetailsItems from '../MyAccountReturnDetailsItems';
import MyAccountReturnDetailsTracking from '../MyAccountReturnDetailsTracking';

import './MyAccountReturnDetails.style';

/** @namespace Rma/Component/MyAccountReturnDetails/Component */
export class MyAccountReturnDetailsComponent extends PureComponent {
    static propTypes = {
        // comments: PropTypes.arrayOf().isRequired,
        tracking: PropTypes.arrayOf().isRequired,
        // addCommentToState: PropTypes.func.isRequired,
        details: PropTypes.objectOf().isRequired
    };

    render() {
        const {
            // addCommentToState,
            // comments,
            tracking,
            details: {
                increment_id = '',
                shipping_address = {},
                orders = [],
                status_label
            }
        } = this.props;

        return (
          <ContentWrapper
            wrapperMix={ { block: 'MyAccountReturnDetails', elem: 'Wrapper' } }
          >
            <div block="MyAccountReturnDetails">
               <h2
                 block="MyAccountReturnDetails"
                 elem="Heading"
               >
                { __('RETURN #') }
                { increment_id }
               </h2>
               <div
                 block="MyAccountReturnDetails"
                 elem="Status"
               >
                    <h4>{ __('Status: ') }</h4>
                    { status_label }
               </div>
                <div
                  block="MyAccountReturnDetails"
                  elem="CustomerAndAddressBlocks"
                >
                    { /* <MyAccountNewReturnCustomerTable /> */ }
                    <MyAccountNewReturnAddressTable address={ shipping_address } />
                </div>
                <MyAccountReturnDetailsTracking tracking={ tracking } />
                { orders && orders.map((order) => <MyAccountReturnDetailsItems items={ order.items } />) }
                { /* { (() => (
                        <MyAccountReturnDetailsChat
                          addCommentToState={ addCommentToState }
                          comments={ comments }
                          requestId={ rma_id }
                        />
                ))() } */ }

            </div>
          </ContentWrapper>
        );
    }
}

export default MyAccountReturnDetailsComponent;
