/* eslint-disable react/destructuring-assignment */
import history from 'Util/History';

import GoogleAdwords from '../component/GoogleAdwords';
import GoogleAnalytics from '../component/GoogleAnalytics';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

const BEFORE_ITEMS_TYPE = (originalMember) => {
    const maxPosition = Math.max(
        ...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <GoogleAnalytics history={ history } />,
            position: maxPosition + INCREASED_POSITION
        },
        {
            component: <GoogleAdwords history={ history } />,
            position: maxPosition + INCREASED_POSITION + INCREASED_POSITION
        }
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};

export default config;
