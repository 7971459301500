import {
    MyAccountOrderTabContainer as SourceMyAccountOrderTabContainer
} from 'SourceComponent/MyAccountOrderTab/MyAccountOrderTab.container';

/** @namespace myApp/Component/MyAccountOrderTab/Container */
export class MyAccountOrderTabContainer extends SourceMyAccountOrderTabContainer {
    // TODO implement logic
}

export default MyAccountOrderTabContainer;
