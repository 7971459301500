import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.override.style';

/** @namespace myApp/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    // TODO implement logic
}

export default OverlayComponent;
