import OrderQuery from 'Query/Order.query';
import {
    OrderDispatcher as SourceOrderDispatcher
} from 'SourceStore/Order/Order.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { getOrderList, setLoadingStatus } from 'Store/Order/Order.action';
import { formatOrders } from 'Util/Orders';
import { fetchQuery, getErrorMessage } from 'Util/Request';
/** @namespace myApp/Store/Order/Dispatcher */
export class OrderDispatcher extends SourceOrderDispatcher {
    requestOrders(dispatch, page = 1, orderId = null) {
        const query = OrderQuery.getOrderListQuery({ page, orderId });
        dispatch(setLoadingStatus(true));

        return fetchQuery(query).then(
            /** @namespace myApp/Store/Order/Dispatcher/OrderDispatcher/requestOrders/fetchQuery/then */
            ({ customer: { orders, ordersHistory } }) => {
                const data = orderId ? orders : ordersHistory;
                dispatch(getOrderList(data, false));
            },
            /** @namespace myApp/Store/Order/Dispatcher/OrderDispatcher/requestOrders/fetchQuery/then/catch */
            (error) => {
                dispatch(showNotification('error', getErrorMessage(error)));
                dispatch(setLoadingStatus(false));
            }
        );
    }

    async getOrderById(dispatch, orderId) {
        try {
            const {
                customer: {
                    orders: {
                        items
                    }
                }
            } = await fetchQuery(OrderQuery.getOrderListQuery({ orderId }));
            const formattedOrders = formatOrders(items);

            return formattedOrders[0];
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }
}

export default new OrderDispatcher();
