/* eslint-disable fp/no-let */

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsDetailsInArray */
export const getCartProductsDetailsInArray = (items) => items.map(({ product, qty }) => {
    const { attributes, variants } = product;

    const COLOR = 'color';
    const colorValue = variants[0]?.attributes[COLOR].attribute_value;
    const color = attributes[COLOR]?.attribute_options[colorValue];

    const SIZE = 'size';
    const sizeValue = variants[0]?.attributes[SIZE].attribute_value;
    const size = attributes[SIZE]?.attribute_options[sizeValue];

    return {
        Quantity: qty,
        Color: color ? color.label : '',
        Size: size ? size.label : '',
        'Product Name': product.name,
        'Product Image': variants[0]
            ? variants[0].thumbnail.url : product.thumbnail.url,
        'Product SKU': product.sku
    };
});

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsNameString */
export const getCartProductsNameString = (items) => {
    let productsName = '';
    items.forEach((product) => {
        productsName = productsName.concat(productsName.length > 0 ? ', ' : '', product.title);
    });

    return productsName;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsSkuString */
export const getCartProductsSkuString = (items) => {
    let sku = '';
    items.forEach((product) => {
        sku = sku.concat(sku.length > 0 ? ', ' : '', product.sku);
    });

    return sku;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsImagesArray */
export const getCartProductsImagesArray = (items) => {
    const img = [];
    items.forEach((product) => {
        img.push(product.product_image);
    });

    return img;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsQtysString */
export const getCartProductsQtysString = (items) => {
    let qtys = '';
    items.forEach(({ qty_ordered }) => {
        qtys = qtys.concat(qtys.length > 0 ? ', ' : '', qty_ordered);
    });

    return qtys;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsColorsString */
export const getCartProductsColorsString = (items) => {
    let colors = '';
    items.forEach(({ color }) => {
        colors = colors.concat(colors.length > 0 ? ', ' : '', color);
    });

    return colors;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getCartProductsSizesString */
export const getCartProductsSizesString = (items) => {
    let sizes = '';

    items.forEach(({ size }) => {
        sizes = sizes.concat(sizes.length > 0 ? ', ' : '', size);
    });

    return sizes;
};

/** @namespace Ambab/Webengage/Helper/Checkout/Index/getProductsGenderString */
export const getProductsGenderString = (items) => {
    let genders = '';

    items.forEach(({ gender }) => {
        genders = genders.concat(genders.length > 0 ? ', ' : '', gender);
    });

    return genders;
};
