import {
    MyAccountInformation as SourceMyAccountInformation
} from 'SourceComponent/MyAccountInformation/MyAccountInformation.component';

import './MyAccountInformation.override.style';

/** @namespace myApp/Component/MyAccountInformation/Component */
export class MyAccountInformationComponent extends SourceMyAccountInformation {

}

export default MyAccountInformationComponent;
