import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Popup from 'Component/Popup';
import ProductLinks from 'Component/ProductLinks';
import { CROSS_SELL } from 'Store/LinkedProducts/LinkedProducts.reducer';

import { GIFT_CART_POPUP_ID } from './GiftAddToCartPopup.config';

import './GiftAddToCartPopup.style';

/** @namespace GiftCard/Component/GiftAddToCartPopup/Component */
export class GiftAddToCartPopupComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.isRequired
    };

    renderContent() {
        const { payload } = this.props;

        if (payload) {
            const { payload: { product, amount } } = this.props;
            return (
                <>
                <div block="Main">
                    <img src={ product.image.url } alt="alt" block="Img" />
                    <div block="ProductInfo">
                    <h3 block="Main" elem="ProductName">{ product.meta_title }</h3>
                    <p block="ProductConfigurableAttributes-Size">
                        Amount:
                        { amount }
                    </p>
                    </div>

                </div>
                <div block="InnerDiv">
                <ProductLinks
                  linkType={ CROSS_SELL }
                  title={ __('You might also like') }
                  areDetailsLoaded
                  numberOfProductsToDisplay={ 3 }
                />
                </div>
                <div block="PopupButtons">
                <Link
                  className="PopupButtons-ShoppingBtn"
                  to="/"
                >
                Continue Shopping
                </Link>
                <Link
                  to="/cart"
                  className="CartBtn"
                  style={ { textDecoration: 'none' } }
                >
                View Bag & Checkout
                </Link>
                </div>
                </>
            );
        }

        return null;
    }

    render() {
        return (
            <div block="GiftAddToCartPopup">
                <Popup
                  id={ GIFT_CART_POPUP_ID }
                  clickOutside={ false }
                  mix={ { block: 'AddToCartPopup' } }
                >
                <div block="GiftAddToCartPopup" elem="Content">
                   { this.renderContent() }
                </div>
                </Popup>
            </div>
        );
    }
}

export default GiftAddToCartPopupComponent;
