/* eslint-disable max-lines */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OtpQuery from 'Query/OTP.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { showPopup } from 'Store/Popup/Popup.action';
import transformToNameValuePair from 'Util/Form/Transform';
import { handleGAEventsTracker } from 'Util/Helper';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import {
    MY_ACCOUNT_POPUP_ID,
    STATE_CREATE_ACCOUNT
} from '../MyAccountPopup/MyAccountPopup.config';
import MyAccountSignIn from './MyAccountSignIn.component';
import {
    RESEND_OTP, SIGNIN, SIGNIN_SEND_OTP, SIGNIN_VERIFY_OTP
} from './MyAccountSignIn.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace myApp/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    // showNotification: (type, message) => dispatch(showNotification(type, message)),
    signInOtp: (options) => MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.signInOtp(options, dispatch)),
    hidePopup: () => dispatch(showPopup('', {})),
    showPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_POPUP_ID, payload))
});

/** @namespace myApp/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        showPopup: PropTypes.func.isRequired
    };

    // TODO implement logic
    state = {
        currentRenderForm: SIGNIN,
        formFields: {},
        count: 0,
        resendCount: 0,
        isLoading: false
    };

    handleRenderForm(key) {
        this.setState({
            currentRenderForm: key
        });
    }

    containerFunctions = {
        handleRenderForm: this.handleRenderForm.bind(this),
        onSignInSuccess: this.onSignInSuccess.bind(this),
        // onSignInAttempt: this.onSignInAttempt.bind(this),
        handleSignInSendOTPSuccess: this.handleSignInSendOTPSuccess.bind(this),
        handleSignInVerifyOTPSuccess: this.handleSignInVerifyOTPSuccess.bind(this),
        handleResendOTP: this.handleResendOTP.bind(this),
        handleBackToPrevious: this.handleBackToPrevious.bind(this)
    };

    containerProps = () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            prepone,
            hidePopup,
            prefieldemail,
            prefieldmobile,
            description
        } = this.props;

        const {
            currentRenderForm, formFields, isLoading, leftAttempt
        } = this.state;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            currentRenderForm,
            formFields,
            prepone,
            hidePopup,
            isLoading,
            leftAttempt,
            prefieldemail,
            prefieldmobile,
            description
        };
    };

    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        const emailValue = fields[0].value;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        try {
            await signIn(fieldPairs);
            onSignIn();

            handleGAEventsTracker('event', 'sign-in-with-email', {
                email: emailValue
            });
        } catch (error) {
            showNotification('error',
                'Invalid login or password. Remember that password is case‑sensitive. Please try again.');
        }

        setLoadingState(false);
    }

    async handleSignInVerifyOTPSuccess(fields) {
        const {
            signInOtp, showNotification, onSignIn
        } = this.props;

        this.setState({ isLoading: true });

        const transformFieldsLoginVerify = transformToNameValuePair(fields);

        const mobileValue = transformFieldsLoginVerify.mobileNumber;

        try {
            // const mutation = OtpQuery.getLoginOTPQueryVerify(transformFieldsLoginVerify);
            // await fetchMutation(mutation);
            await signInOtp(transformFieldsLoginVerify);
            onSignIn();
            handleGAEventsTracker('event', 'sign-in-with-otp', {
                mobile_number: mobileValue
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));

            this.setState(
                (prevState) => ({
                    count: prevState.count + 1,
                    leftAttempt: error[0].leftAttempt
                }),
                () => {
                    const { count } = this.state;
                    // eslint-disable-next-line no-magic-numbers
                    if (count >= 3) {
                        this.setState({
                            currentRenderForm: SIGNIN_SEND_OTP,
                            formFields: {},
                            count: 0
                        });
                    }
                }
            );
        }

        this.setState({ isLoading: false });
    }

    async handleSignInSendOTPSuccess(fields) {
        const { showNotification, hidePopup } = this.props;
        const { showPopup } = this.props;

        this.setState({ isLoading: true });

        const transformFieldsLogin = transformToNameValuePair(fields);

        try {
            // const mutation = OtpQuery.getLoginOTPQuery(fields.mobileNumber);
            const mutation = OtpQuery.getLoginOTPQuery(transformFieldsLogin);
            await fetchMutation(mutation);

            this.setState({
                currentRenderForm: SIGNIN_VERIFY_OTP,
                // formFields: fields
                formFields: transformFieldsLogin
            });
        } catch (error) {
            if (error[0].message === 'Incorrect Log in. Please try again later') {
                hidePopup();
                // showNotification('error', 'Unregistered user. Please sign up to enable OTP login.');
                showPopup({
                    title: 'My Account',
                    action: STATE_CREATE_ACCOUNT,
                    ...(transformFieldsLogin.mobileNumber && { prefieldmobile: transformFieldsLogin.mobileNumber }) // Assuming email is part of transformFieldsLogin
                });
            } else {
                showNotification('error', getErrorMessage(error));
            }
        }

        this.setState({ isLoading: false });
    }

    async handleResendOTP() {
        const { showNotification } = this.props;

        this.setState({ isLoading: true });
        const fields = this.state.formFields;
        try {
            const mutation = OtpQuery.getLoginOTPQuery(fields);
            await fetchMutation(mutation);

            this.setState({ count: 0 });

            this.setState(
                (prevState) => ({
                    resendCount: prevState.resendCount + 1
                }),
                () => {
                    const { resendCount } = this.state;
                    // eslint-disable-next-line no-magic-numbers
                    if (resendCount >= 3) {
                        this.setState({
                            currentRenderForm: RESEND_OTP,
                            formFields: {},
                            resendCount: 0
                        });
                    }
                }
            );
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        this.setState({ isLoading: false });
    }

    handleBackToPrevious(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({
            currentRenderForm: SIGNIN_SEND_OTP,
            formFields: {},
            resendCount: 0,
            count: 0
        });
    }

    render() {
        return <MyAccountSignIn { ...this.containerFunctions } { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
