/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import CustomerRmaQuery from '../../query/CustomerRma.query';
import MyAccountReturnDetails from './MyAccountReturnDetails.component';

/** @namespace Rma/Component/MyAccountReturnDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message))
});

/** @namespace Rma/Component/MyAccountReturnDetails/Container */
export class MyAccountReturnDetailsContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        tracking: [],
        isLoading: false,
        details: {},
        comments: [],
        shippingAddress: null
    };

    componentDidMount() {
        this.requestData();
    }

    requestData() {
        const { showNotification } = this.props;

        const {
            match: {
                params: {
                    entityId
                }
            }
        } = this.props;

        // const returnId = pathname
        //     .split('/')[3] // eslint-disable-line no-magic-numbers
        //     .split('&')[1]
        //     .split('=')[1];

        this.setState({
            isLoading: true
        });

        return fetchQuery([
            CustomerRmaQuery.getRmaById(entityId)
        ])
            .then(
                /** @namespace Rma/Component/MyAccountReturnDetails/Container/MyAccountReturnDetailsContainer/requestData/fetchQuery/then */
                ({
                    // getReturnDetailsById: {
                    //     shipping_info: { shipping_address: shippingAddress, tracking },
                    //     comments
                    // },
                    customerRma: { rma: getReturnDetailsById }
                }) => {
                    this.setState({
                        isLoading: false,
                        details: getReturnDetailsById
                    });
                },
                /** @namespace Rma/Component/MyAccountReturnDetails/Container/MyAccountReturnDetailsContainer/requestData/fetchQuery/then/showNotification/catch */
                (e) => showNotification('error', 'Error fetching Return Details!', e)
            );
    }

    addCommentToState = (comment) => {
        this.setState((previousState) => ({
            comments: [...previousState.comments, comment]
        }));
    };

    render() {
        const { isLoading } = this.state;

        console.log(this.state);
        console.log(this.props);

        return (
            <div>
                <Loader isLoading={ isLoading } />
                <MyAccountReturnDetails
                  { ...this.state }
                  { ...this.props }
                  addCommentToState={ this.addCommentToState }
                />
            </div>
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(null, mapDispatchToProps)(MyAccountReturnDetailsContainer);
