import { ReturnDispatcher } from '../store/Return';

/** @namespace Rma/Component/MyAccountMyReturns/Container/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        returnList: state.ReturnReducer.returnList,
        totalCount: state.ReturnReducer.totalCount
    };
};

/** @namespace Rma/Component/MyAccountMyReturns/Container/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        getReturnList: () => ReturnDispatcher.requestReturns(dispatch)
    };
};

export class MyOrderTabContainerPlugin {
    componentDidMount= (args, callback, instance) => {
        if (instance.props.activeTab !== 'rma') {
            instance.props.getReturnList();
        }
    };

    containerProps = (args, callback, instance) => {
        const { returnList, totalCount } = instance.props;

        return {
            ...callback.apply(instance, args),
            rmaCount: returnList ? returnList.length : 0,
            totalRmaCount: totalCount
        };
    };
}

const { containerProps, componentDidMount } = new MyOrderTabContainerPlugin();

export default {
    'myApp/Component/MyOrderTab/Container': {
        'member-function': {
            containerProps,
            componentDidMount
        }
    },
    'myApp/Component/MyOrderTab/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'myApp/Component/MyOrderTab/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
