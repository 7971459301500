// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PreferencesQuery from 'Query/Preferences.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCustomerFormContainer as SourceMyAccountCustomerFormContainer
} from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import MyAccountCustomerForm from './MyAccountCustomerForm.component';

/** @namespace myApp/Component/MyAccountCustomerForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace myApp/Component/MyAccountCustomerForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error)))
});

/** @namespace myApp/Component/MyAccountCustomerForm/Container */
export class MyAccountCustomerFormContainer extends SourceMyAccountCustomerFormContainer {
    containerFunctions = {
        ...this.containerFunctions,
        savePreferenceFor: this.savePreferenceFor.bind(this)
    };

    async savePreferenceFor(input) {
        const { showErrorNotification } = this.props;
        try {
            const mutation = PreferencesQuery.getMutationsavePreferenceFor(input);
            await fetchMutation(mutation);
        } catch (error) {
            showErrorNotification(error);
        }
    }

    containerProps() {
        const { isMobile } = this.props;

        return {
            ...super.containerProps(),
            isMobile
        };
    }

    render() {
        return (
            <MyAccountCustomerForm
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerFormContainer);
