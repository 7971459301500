import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountInformationContainer as SourceMyAccountInformationContainer
} from 'SourceComponent/MyAccountInformation/MyAccountInformation.container';
import { isSignedIn } from 'Util/Auth';

/** @namespace myApp/Component/MyAccountInformation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountInformation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/MyAccountInformation/Container */
export class MyAccountInformationContainer extends SourceMyAccountInformationContainer {
    async onCustomerSave(fields) {
        const { updateCustomerLoadingStatus } = this.props;
        const { showPasswordChangeField, showEmailChangeField } = this.state;
        const {
            firstname = '',
            lastname = '',
            taxvat = '',
            password = '',
            newPassword = '',
            email = '',
            dob = '',
            gender = ''
        } = fields;

        if (!isSignedIn()) {
            return;
        }

        updateCustomerLoadingStatus(true);

        await this.handleInformationChange({
            firstname, lastname, taxvat, dob, gender
        });

        if (showPasswordChangeField) {
            await this.handlePasswordChange({ password, newPassword });
        }

        if (showEmailChangeField) {
            await this.handleEmailChange({ email, password });
        }

        this.afterSubmit();
    }

    afterSubmit() {
        const { showSuccessNotification, updateCustomerLoadingStatus } = this.props;
        const { isErrorShow } = this.state;

        if (!isErrorShow) {
            updateCustomerLoadingStatus(false);

            // if (showEmailChangeField || showPasswordChangeField) {
            //     this.handleLogout();
            // }
            //  else {
            //     history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
            // }

            showSuccessNotification('You saved the account information.');
            this.setState({ showEmailChangeField: false, showPasswordChangeField: false });
        } else {
            this.setState({ isErrorShow: false });
        }
    }

    handleChangeEmailCheckbox() {
        this.setState(({ showEmailChangeField }) => ({ showEmailChangeField: !showEmailChangeField }));
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountInformationContainer));
