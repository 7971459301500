import { Field } from 'Util/Query';

/** @namespace myApp/Query/Preferences/Query */
export class PreferencesQuery {
    getQuerypreferenceFor() {
        return new Field('preferenceFor')
            .addFieldList(['shop_for', 'you_into']);
    }

    getMutationsavePreferenceFor(input) {
        return new Field('savePreferenceFor')
            .addArgument('input', 'SavepreferenceInput!', input)
            .addFieldList(['message', 'success']);
    }

    getQuerycustomerPreferenceFor() {
        return new Field('customerPreferenceFor')
            .addFieldList(['shop_for', 'you_into']);
    }
}

export default new PreferencesQuery();
