/* eslint-disable no-console */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { handleGAEventsTracker } from 'Util/Helper';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import {
    GIFT_CARD,
    PRODUCT_VIEWED,
    RECENTLY_VIEWED
} from '../config';
import { getAttribute, getProductImg } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        product,
        product: {
            sku, attributes
        }
    } = instance.props;

    const {
        product: {
            sku: prevSku
        }
    } = prevProps;

    if (sku !== prevSku) {
        const {
            name, type_id, gender, sport, product_type_filter, product_category, collection_name,
            price_range: {
                minimum_price: {
                    final_price: { value, currency }
                }
            },
            media_gallery_entries,
            categories
        } = product;

        const categories_name = categories.reduce((acc, curr) => `${acc}${curr.name}, `, '');
        const categories_url = categories.reduce((acc, curr) => `${acc}${curr.url}, `, '');

        const COLOR = 'color';
        const colorValue = getQueryParam(COLOR, history.location);
        const [colorData] = Object.values(attributes).filter((item) => item.attribute_code === COLOR);
        const color = (colorData && colorValue) ? colorData.attribute_options[colorValue]?.label : '';

        // const img = Object.values(media_gallery_entries).map(({ base: { url } }) => url);

        if (type_id === 'mageworx_giftcards') {
            eventTrack(GIFT_CARD, {
                'Card Name': name
            });
            handleGAEventsTracker('event', 'view_item', {
                currency: 'INR',
                value,
                items: [{
                    item_id: sku,
                    item_name: name,
                    price: value,
                    quantity: 1
                }]
            });
        } else {
            eventTrack(PRODUCT_VIEWED, {
                'Product Name': name,
                Price: value,
                Color: color,
                Gender: gender || '',
                'Product Image': getProductImg(media_gallery_entries),
                'Product SKU': sku,
                'Category Name': categories_name,
                'Category URL': categories_url
            });
            handleGAEventsTracker('event', 'view_item', {
                currency,
                value,
                items: [{
                    item_id: sku,
                    item_name: name,
                    item_category: collection_name,
                    item_category2: sport,
                    item_category3: product_category,
                    item_category4: product_type_filter,
                    item_category5: gender,
                    index: 1,
                    currency,
                    price: value,
                    quantity: 1
                }]
            });
        }

        // Store SKU in session storage
        let storedSKUs = [];
try {
    // Retrieve the stored SKUs from session storage, or initialize an empty string if not found
    storedSKUs = sessionStorage.getItem('viewedSKUs') || '';
    
    // Split the stored SKUs string into an array, filtering out any empty values
    let skuList = storedSKUs.split(',').filter(Boolean);

    // Check if the current SKU is not already in the list
    if (!skuList.includes(sku)) {
        // Add the current SKU to the list
        skuList.push(sku);
        
        // Store the updated SKU list back into session storage as a comma-separated string
        sessionStorage.setItem('viewedSKUs', skuList.join(','));
    }

    // Trigger WebEngage event with the SKU list as an array
    eventTrack(RECENTLY_VIEWED, {
        'Product SKU': skuList
    });
} catch (error) {
    console.error('Error accessing session storage:', error);
}

    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
