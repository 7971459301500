import {
    MyAccountOrderTab as SourceMyAccountOrderTab
} from 'SourceComponent/MyAccountOrderTab/MyAccountOrderTab.component';

import './MyAccountOrderTab.override.style';

/** @namespace myApp/Component/MyAccountOrderTab/Component */
export class MyAccountOrderTabComponent extends SourceMyAccountOrderTab {
    render() {
        const { title, isActive, handleClickOnTab } = this.props;
        return (
            <li
              block="MyAccountOrderTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'MyAccountOrderTab', elem: 'Button' } }
                  onClick={ handleClickOnTab }
                >
                    { title }
                </button>
            </li>
        );
    }
}

export default MyAccountOrderTabComponent;
