/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { GET_RETURN_LIST, SET_LOADING, SET_PAGE_INFO } from './Return.action';

export const initialState = {
    isLoading: false,
    returnList: [],
    totalCount: 0,
    pageInfo: {
        total_pages: 0,
        current_page: 1,
        page_size: 5
    }
};

/** @namespace Rma/Store/Return/Reducer/ReturnReducer */
export const ReturnReducer = (state = initialState, action) => {
    const {
        type, data, is, pageInfo
    } = action;

    switch (type) {
    case GET_RETURN_LIST:
        return {
            ...state,
            returnList: data.items,
            totalCount: data.total_count
            // returnList: { data, pageInfo }
        };
    case SET_LOADING:
        return {
            ...state,
            isLoading: is
        };
    case SET_PAGE_INFO:
        return {
            ...state,
            pageInfo
        };
    default:
        return state;
    }
};

export default ReturnReducer;
