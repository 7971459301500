import { connect } from 'react-redux';

import { STATE_FORGOT_PASSWORD_SUCCESS } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import OtpQuery from 'Query/OTP.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountForgotPasswordContainer as SourceMyAccountForgotPasswordContainer
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.container';
import { showNotification } from 'Store/Notification/Notification.action';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchQuery } from 'Util/Request';

import MyAccountForgotPassword from './MyAccountForgotPassword.component';

/** @namespace myApp/Component/MyAccountForgotPassword/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))

});

/** @namespace myApp/Component/MyAccountForgotPassword/Container */
export class MyAccountForgotPasswordContainer extends SourceMyAccountForgotPasswordContainer {
    state = {
        errorMessage: '',
        isEmailExist: false
    };

    containerFunctions = {
        onForgotIsEmailExists: this.onForgotIsEmailExists.bind(this),
        onHandleChange: this.onHandleChange.bind(this)
    };

    onHandleChange(e) {
        if (e.target.value.length >= 1) {
            this.setState({ errorMessage: '' });
        }
    }

    async onForgotIsEmailExists(form, fields) {
        const { setLoadingState } = this.props;
        setLoadingState(true);

        try {
            const emailField = transformToNameValuePair(fields);

            const query = await fetchQuery(OtpQuery.getIsEmailExist(emailField.email));
            setLoadingState(false);

            const { isEmailExist: { error, is_email_exist } } = query;

            this.setState({ isEmailExist: is_email_exist });

            if (error === null || is_email_exist) {
                return await this.onForgotPasswordSuccess(form, fields);
            }
        } catch {
            setLoadingState(false);
        }

        // eslint-disable-next-line max-len
        return this.setState({ errorMessage: 'There is no user registered with this email id. Register Now or log in with a different email id.' });
    }

    async onForgotPasswordSuccess(form, fields) {
        const { forgotPassword, setSignInState, setLoadingState } = this.props;
        setLoadingState(true);

        try {
            await forgotPassword(transformToNameValuePair(fields));
            setSignInState(STATE_FORGOT_PASSWORD_SUCCESS);
            setLoadingState(false);
        } catch {
            setLoadingState(false);
        }
    }

    containerProps() {
        const { errorMessage, isEmailExist } = this.state;

        return {
            ...super.containerProps(),
            errorMessage,
            isEmailExist
        };
    }

    render() {
        return (
            <MyAccountForgotPassword
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordContainer);
