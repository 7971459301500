/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { MY_ACCOUNT_NEW_RETURN_ADDRESS_POPUP }
    from
    '../MyAccountNewReturnAddressPopup/MyAccountNewReturnAddressPopup.config';
import MyAccountNewReturnAddressTable from './MyAccountNewReturnAddressTable.component';

/** @namespace Rma/Component/MyAccountNewReturnAddressTable/Container/mapStateToProps */
export const mapStateToProps = () => ({
    // TODO extend mapStateToProps
});

/** @namespace Rma/Component/MyAccountNewReturnAddressTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNewReturnAddressPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_NEW_RETURN_ADDRESS_POPUP, payload))
});

/** @namespace Rma/Component/MyAccountNewReturnAddressTable/Container */
export class MyAccountNewReturnAddressTableContainer extends PureComponent {
    static propTypes = {
        address: PropTypes.objectOf(),
        showNewReturnAddressPopup: PropTypes.func.isRequired,
        updateReturnAddress: PropTypes.func,
        isEditShow: PropTypes.bool
    };

    static defaultProps = {
        isEditShow: false,
        address: {},
        updateReturnAddress: () => {
            // This is intentional
        }
    };

    containerFunctions = {
        onViewAccountReturnAddress: this.onViewAccountReturnAddress.bind(this)
    };

    containerProps() {
        const { address, updateReturnAddress, isEditShow } = this.props;

        return {
            isEditShow,
            address,
            updateReturnAddress
        };
    }

    onViewAccountReturnAddress() {
        const { showNewReturnAddressPopup } = this.props;

        showNewReturnAddressPopup({
            title: __('My Order Return Address')
        });
    }

    render() {
        return (
            <MyAccountNewReturnAddressTable
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewReturnAddressTableContainer);
