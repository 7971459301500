import { setAttribute } from '../helper/WebEngage/Event';

const savePreferenceFor = (args, callback) => {
    callback(...args).then(() => {
        const [input] = args;

        if (input.shop_for !== '' || input.you_into !== '') {
            setAttribute('Preferences', input.shop_for.concat(',', input.you_into));
        }
    });
};

export default {
    'Component/MyAccountCustomerForm/Container': {
        'member-function': {
            savePreferenceFor
        }
    }
};
