import { setAttribute } from '../helper/WebEngage/Event';

const onCustomerSave = (args, callback, instance) => {
    callback(...args).then(() => {
        const { isErrorShow } = instance.state;
        if (!isErrorShow) {
            const [fields] = args;
            const {
                firstname = '',
                lastname = '',
                dob = '',
                gender = ''
            } = fields;

            const genderArr = [{
                id: '0',
                label: 'male'
            }, {
                id: '1',
                label: 'female'
            },
            {
                id: '2',
                label: 'others'
            }];

            const [genderFiltered] = genderArr.filter((i) => i.id === gender);

            setAttribute('first_name', firstname);
            setAttribute('last_name', lastname);
            setAttribute('birth_date', dob);
            setAttribute('gender', genderFiltered.label);
        }
    });
};

export default {
    'Component/MyAccountInformation/Container': {
        'member-function': {
            onCustomerSave
        }
    }
};
