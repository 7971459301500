import getStore from 'Util/Store';

const render = (args, callback) => {
    const state = getStore().getState();
    // const webengageLicense = state.ConfigReducer.webengage_license_key;
    // window.webengage.init(webengageLicense);
    return callback(...args);
};

export default {
    'Component/App/Component': {
        'member-function': {
            render
        }
    }
};
