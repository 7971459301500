/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const TrackOrder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "track-order" */ '../route/TrackOrder'));
export const TrackOrderStatus = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "track-order" */ '../route/TrackOrderStatus'));

export const TRACK_ORDER_PAGE = 'TRACK_ORDER_PAGE';
export const TRACK_ORDER_STATUS = 'TRACK_ORDER_STATUS';

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/track-order/tracking-number/:entityId') } render={ (props) => <TrackOrderStatus { ...props } /> } />,
            position: 165,
            name: TRACK_ORDER_STATUS
        },
        {
            component: <Route exact path={ withStoreRegex('/track-order') } render={ (props) => <TrackOrder { ...props } /> } />,
            position: 155,
            name: TRACK_ORDER_PAGE
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
