/* eslint-disable max-len */
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { isSignedIn } from 'Util/Auth';

const addMyAccountDropdown = (args, callback, instance) => {
    if (isSignedIn()) {
        instance.setState({ showMyAccountLogin: true }, () => {
            instance.props.showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            instance.props.setNavigationState({
                name: 'checkout_account',
                title: 'Sign In',
                onCloseClick: instance.closeOverlay
            });
        });

        return;
    }
    callback.apply(instance, args);
};

export default {
    'Component/Header/Container': {
        'member-function': {
            onMyAccountButtonClick: addMyAccountDropdown
        }
    }
};
