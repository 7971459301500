/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { OrdersListType } from 'Type/Order.type';
import { toDateString } from 'Util/Manipulations/Date';

import MyAccountMyReturnsDropdown from './MyAccountMyReturnsDropdown.component';

/** @namespace Rma/Component/MyAccountMyReturnsDropdown/Container */
export class MyAccountMyReturnsDropdownContainer extends PureComponent {
    static propTypes = {
        orderList: OrdersListType.isRequired,
        areOrdersLoading: PropTypes.bool.isRequired
    };

    containerProps = () => ({
        selectOptions: this._getSelectOptions()
    });

    _getSelectOptions() {
        const { orderList } = this.props;
        if (!(orderList.items && orderList.items.length > 0)) {
            return [];
        }

        return orderList.items.reduce(
            (list, order) => {
                const {
                    id,
                    increment_id,
                    order_date,
                    status_can_be_returned,
                    giftcard_used_status
                } = order;

                if (!giftcard_used_status) {
                    if (!status_can_be_returned) {
                        return list;
                    }

                    list.push({
                        id,
                        value: id,
                        label: `Order #${ increment_id } - ${ toDateString(order_date) }`
                    });

                    return list;
                }

                return list;
            }, []
        );
    }

    render() {
        return (
            <MyAccountMyReturnsDropdown
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default MyAccountMyReturnsDropdownContainer;
