import { showPopup } from 'Store/Popup/Popup.action';

import { ADD_TO_CART_POPUP_ID } from '../component/AddToCartPopup/AddToCartPopup.config';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showPopup: (payload) => dispatch(showPopup(ADD_TO_CART_POPUP_ID, payload))
    };
};

export default {
    'Component/Product/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
