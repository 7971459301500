/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

const _getStoreConfigFields = (args, callback) => Array.from(callback(...args));

// const _getStoreConfigFields = (args, callback) => [
//     ...callback(...args),
//     new Field('zendesk_key')
// ];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
