/* eslint-disable react/jsx-no-bind */
import Image from 'Component/Image';

import { GIFT_CARDS_PAGE, SHIPPING_POLICY, SOCIAL_MEDIA } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const handleWebengageEvent = (platform) => {
    const socialMediaList = ['Instagram', 'Facebook', 'YouTube'];
    const footerPages = ['Shipping & Delivery', 'Gift Cards'];

    const isPageAvailable = footerPages.includes(platform);
    if (isPageAvailable) {
        // eslint-disable-next-line default-case
        switch (platform) {
        case 'Shipping & Delivery':
            eventTrack(SHIPPING_POLICY, {});
            break;

        case 'Gift Cards':
            eventTrack(GIFT_CARDS_PAGE, {});
            break;
        }
    }

    const isAvailable = socialMediaList.includes(platform);
    if (isAvailable) {
        eventTrack(SOCIAL_MEDIA, {
            'Platform clicked': platform
        });
    }
};

function renderFooterColumnItem(args) {
    const [item] = args;
    /* eslint-disable-next-line max-len */
    const url_key = typeof item.url === 'object' && Object.prototype.hasOwnProperty.call(item.url, 'pathname')
        ? item.url.pathname
        : item.url;
    const icon = item.icon !== null ? <Image src={ item.icon } /> : ' ';
    return (
        // eslint-disable-next-line max-len
        <a onClick={ () => handleWebengageEvent(item.title) } aria-label={ item.title } className="Footer-ColumnItem" href={ url_key }>
            <span block="Menu" elem="Link" mods={ { isIcon: item.icon !== null } }>
                { icon }
                { item.title }
            </span>
        </a>
    );
}

export default {
    'Component/Footer/Component': {
        'member-function': {
            renderFooterColumnItem
        }
    }
};
