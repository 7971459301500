import {
    CmsPage as SourceCmsPage
} from 'SourceRoute/CmsPage/CmsPage.component';

import './CmsPage.override.style';

/** @namespace myApp/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPage {
    // TODO implement logic
}

export default CmsPageComponent;
