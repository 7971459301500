/**
 * Example ::
 * import TimerButton from 'Component/TimerButton';

    <TimerButton timeout=30000 onClick={ () => {
        console.log('1234');
    } }
    >
    { __('Resend OTP') }
    </TimerButton>
 */

/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// import { MixType } from 'Type/Common';
import './TimerButton.style';

/** @namespace myApp/Component/TimerButton/Component */
export class TimerButtonComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        // mix: MixType,
        timeout: PropTypes.number,
        onClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        children: null,
        // mix: {},
        timeout: 30000
    };

    state = {
        isDisabled: false,
        currentCount: 0
    };

    interval = null;

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    startTimer() {
        const { timeout } = this.props;
        this.setState({
            currentCount: timeout / 1000,
            isDisabled: true
        });

        this.interval = setInterval(() => {
            this.tick();
        }, 1000);
    }

    clearTimer() {
        this.setState({
            isDisabled: false
        });
        clearInterval(this.interval);
    }

    tick() {
        this.setState(
            (state) => ({
                currentCount: state.currentCount - 1
            }),
            () => {
                const { currentCount } = this.state;
                if (currentCount <= 0) {
                    this.clearTimer();
                }
            }
        );
    }

    handleClick() {
        const { onClick } = this.props;

        this.startTimer();
        onClick();
    }

    renderTimer() {
        const { currentCount } = this.state;

        if (currentCount > 0) {
            return ` in ${currentCount} Sec`;
        }

        return null;
    }

    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            children
            // mix
        } = this.props;
        const { isDisabled } = this.state;

        return (
            <button
              type="button"
              block="TimerButton"
                //   mix={ { block: 'Button', mods: { isHollow: true } } }
              disabled={ isDisabled }
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => this.handleClick() }
            >
                { children }
                { this.renderTimer() }
            </button>
        );
    }
}

export default TimerButtonComponent;
