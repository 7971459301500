// import ChevronIcon from 'Component/ChevronIcon';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';
/** @namespace myApp/Component/Breadcrumb/Component */
export class BreadcrumbComponent extends SourceBreadcrumb {
    renderName() {
        const { name } = this.props;
        const cleanName = name.replace(/([+])/g, ' ');
        return <TextPlaceholder content={ cleanName } />;
    }

    renderLink() {
        const { index, isDisabled } = this.props;

        const url = this.getLinkUrl() || {};

        return (
            <Link block="Breadcrumb" elem="Link" to={ url } tabIndex={ isDisabled ? '-1' : '0' }>
                <meta itemProp="item" content={ window.location.origin + url.pathname } />
                <span itemProp="name">{ this.renderName() }</span>
                <span className="ChevronIcon">/</span>
                <meta itemProp="position" content={ index } />
            </Link>
        );
    }
}

export default BreadcrumbComponent;
