import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.override.style';

/** @namespace myApp/Component/Breadcrumbs/Component */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    renderBreadcrumbList(breadcrumbs) {
        const breadcrumbsWithHome = Array.from(breadcrumbs);

        return breadcrumbsWithHome.map((_, i) => this.renderBreadcrumb(
            breadcrumbsWithHome[breadcrumbsWithHome.length - 1 - i],
            i
        ));
    }
}

export default BreadcrumbsComponent;
