/* eslint-disable no-magic-numbers */
import {
    CATEGORY_VIEW, COLLECTION_CATEGORY, FEATURED_CATEGORY, PAGE_VIEWED, SPORTS_CATEGORY
} from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        categoryIds, category: {
            id, name, breadcrumbs = [], url
        }
    } = instance.props;

    const { breadcrumbsWereUpdated } = instance.state;
    const { category: { id: prevId } } = prevProps;

    const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);

    if (categoryChange) {
        eventTrack(PAGE_VIEWED, {
            'Page Name': name,
            'Page Path': url
        });

        if (!breadcrumbs) {
            return;
        }

        switch (true) {
        case breadcrumbs.length === 2 && breadcrumbs[1].category_name === 'Shop by Sport':
            eventTrack(SPORTS_CATEGORY, {
                'Sport Name': name,
                'Menu Clicked': breadcrumbs[0].category_name
            });
            break;

        case breadcrumbs.length === 2 && breadcrumbs[1].category_name === 'Shop by Collection':
            eventTrack(COLLECTION_CATEGORY, {
                'Collection Name': name,
                'Menu Clicked': breadcrumbs[0].category_name
            });
            break;

        case breadcrumbs.length === 2 && breadcrumbs[1].category_name === 'Featured':
            eventTrack(FEATURED_CATEGORY, {
                'Collection Name': name,
                'Menu Clicked': breadcrumbs[0].category_name
            });
            break;

        case breadcrumbs.length === 2:
            eventTrack(CATEGORY_VIEW, {
                'Category Name': name,
                'Menu Clicked': breadcrumbs[0].category_name
            });
            break;

        default:
            break;
        }
    }
};

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
