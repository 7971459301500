/* eslint-disable react/jsx-no-bind */
import Link from 'Component/Link';

import { RETURN_POLICY } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

const handleWebengageEvent = (tabName) => {
    const page = ['Returns'];

    const isPageAvailable = page.includes(tabName.value);
    if (isPageAvailable) {
        eventTrack(RETURN_POLICY, {});
    }
};

function renderTabMapMenuItem(args, callback, instance) {
    const [tabMap] = args;
    const { activeTab } = instance.props;

    return (
        <li block="ServiceItem" mods={ { isActive: (activeTab && activeTab.name === tabMap.name) } }>
            <Link
              to={ tabMap.url }
              isOpenInNewTab={ tabMap.isFullUrl }
              onClick={ () => handleWebengageEvent(tabMap.tabName) }
            >
                { tabMap.tabName }

            </Link>
        </li>
    );
}

export default {
    'CustomerServices/Route/CustomerServicePage/Component': {
        'member-function': {
            renderTabMapMenuItem
        }
    }
};
