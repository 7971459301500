import {
    FieldForm as SourceFieldForm
} from 'SourceComponent/FieldForm/FieldForm.component';

import './FieldForm.override.style';

/** @namespace myApp/Component/FieldForm/Component */
export class FieldFormComponent extends SourceFieldForm {
    // TODO implement logic
}

export default FieldFormComponent;
