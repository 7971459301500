/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderContainer as SourceMyAccountOrderContainer
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation, getErrorMessage } from 'Util/Request';
/** @namespace myApp/Component/MyAccountOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace myApp/Component/MyAccountOrder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error)))
});

/** @namespace myApp/Component/MyAccountOrder/Container */
export class MyAccountOrderContainer extends SourceMyAccountOrderContainer {
    async cancelOrder() {
        const { showErrorNotification } = this.props;
        const { order: { increment_id } } = this.state;

        this.setState({ isLoading: true });
        try {
            const mutation = OrderQuery.getOrderCancelQuery(increment_id);
            await fetchMutation(mutation);
            this.setState({ isLoading: false });
            this.requestOrderDetails();
        } catch (error) {
            showErrorNotification(error);
            this.setState({ isLoading: false });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        cancelOrder: this.cancelOrder.bind(this),
        afterCancelOrderItem: this.afterCancelOrderItem.bind(this)
    };

    afterCancelOrderItem() {
        this.requestOrderDetails();
    }

    async requestOrderDetails() {
        const {
            match: {
                params: {
                    orderId
                }
            },
            isMobile,
            getOrderById,
            changeTabName,
            setTabSubheading
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(orderId);

        if (!order) {
            return;
        }
        const { status } = order;
        { !isMobile && changeTabName((__('Order Details'))); }
        setTabSubheading(status);
        this.handleChangeHeaderState();
        this.setState({ order, isLoading: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderContainer);
