/** @namespace Ambab/Webengage/Helper/WebEngage/Event/eventTrack */
export const eventTrack = (eventName, eventData) => {
    window.webengage.track(eventName, eventData);
};

/** @namespace Ambab/Webengage/Helper/WebEngage/Event/trackUserLogin */
export const trackUserLogin = (uniqueIdentifier) => {
    // uniqueIdentifier must be string
    window.webengage.user.login(uniqueIdentifier);
};

/** @namespace Ambab/Webengage/Helper/WebEngage/Event/trackUserLogout */
export const trackUserLogout = () => {
    window.webengage.user.logout();
};

/** @namespace Ambab/Webengage/Helper/WebEngage/Event/setAttribute */
export const setAttribute = (key, value) => {
    window.webengage.user.setAttribute(key, value);
};
