/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { createRef } from 'react';

import {
    MyAccountCustomerForm as SourceMyAccountCustomerForm
} from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';
import transformToNameValuePair from 'Util/Form/Transform';

import MyAccountPreferenceForm from '../MyAccountPreferenceForm';
import { customerEmailAndPasswordFields, customerInformationFields } from './MyAccountCustomerForm.form';

import './MyAccountCustomerForm.override.style';

/** @namespace myApp/Component/MyAccountCustomerForm/Component */
export class MyAccountCustomerFormComponent extends SourceMyAccountCustomerForm {
    state = {
        updatePreferences: {
            shopFors: [],
            youIntos: []
        },
        selectedGender: null
    };

    emailChangeRef = createRef();

    passwordChangeRef = createRef();

    handleUpdatePreferences = (preferences) => {
        this.setState({ updatePreferences: preferences });
    };

    handleSelectedGender = (key) => {
        this.setState({ selectedGender: key });
    };

    renderPreferences() {
        return (
            <MyAccountPreferenceForm handleUpdatePreferences={ this.handleUpdatePreferences } />
        );
    }

    onFormSuccess(form, fields) {
        const { onSave, savePreferenceFor } = this.props;
        const { updatePreferences: { shopFors, youIntos } } = this.state;
        const shopFor = shopFors.toString();
        const youInto = youIntos.toString();
        onSave(transformToNameValuePair(fields));
        savePreferenceFor({ shop_for: shopFor, you_into: youInto });
    }

    get customerInformationFieldMap() {
        const {
            showTaxVatNumber,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired,
            customer: {
                firstname = '',
                lastname = '',
                taxvat = '',
                email = '',
                dob = '',
                gender = ''
            }
        } = this.props;

        const { selectedGender } = this.state;

        return customerInformationFields({
            showTaxVatNumber,
            firstname,
            lastname,
            taxvat,
            email,
            dob,
            gender,
            selectedGender,
            passwordChangeRef: this.passwordChangeRef,
            emailChangeRef: this.emailChangeRef,
            handleSelectedGender: this.handleSelectedGender,
            handleChangePasswordCheckbox,
            handleChangeEmailCheckbox,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired
        });
    }

    get emailAndPasswordFieldMap() {
        const {
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email
        } = this.props;

        return customerEmailAndPasswordFields({
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email
        });
    }

    renderEmailAndPasswordFields() {
        const { showEmailChangeField, showPasswordChangeField } = this.props;

        if (!showEmailChangeField && !showPasswordChangeField) {
            return null;
        }

        return (
            <>
                { this.emailAndPasswordFieldMap.map(this.renderSection) }
            </>
        );
    }

    handleEmailRef = () => {
        const { handleChangeEmailCheckbox } = this.props;
        this.emailChangeRef.current.checked = false;
        handleChangeEmailCheckbox();
    };

    handlePasswordRef = () => {
        const { handleChangePasswordCheckbox } = this.props;
        this.passwordChangeRef.current.checked = false;
        handleChangePasswordCheckbox();
    };

    renderFormBody() {
        const {
            showEmailChangeField, showPasswordChangeField, isMobile
        } = this.props;

        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                { isMobile && (
                    <div block="InformationForMobile" elem="Svg">
                            <svg
                              width="43"
                              height="24"
                              viewBox="0 0 48 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                  opacity="0.9"
                                  d="M30.808 13.9312C30.6343 14.0618 30.4297 14.2112 30.1794 14.3908C28.5886 15.5435 26.2743 16.3333 23.2686 16.3333H23.0686C20.0629 16.3333 17.7497 15.5447 16.1554 14.392C15.9086 14.2135 15.704 14.063 15.5303 13.9323C15.704 13.8005 15.9086 13.65 16.1554 13.4715C17.7497 12.32 20.064 11.529 23.0697 11.529H23.2686C26.2754 11.529 28.5897 12.32 30.1817 13.4715C30.4309 13.65 30.6354 13.8005 30.8091 13.9323L30.808 13.9312ZM46.3429 4.43333C46.3429 4.43333 44.9749 3.36583 40.6469 1.74417C36.8457 0.319667 33.9806 0 33.9806 0L33.992 8.365C33.992 9.54217 33.6777 10.6167 32.7977 11.8428C29.5691 10.6412 26.5154 9.90383 23.1794 9.90383C19.8423 9.90383 16.792 10.6412 13.5634 11.8428C12.6811 10.6237 12.368 9.54217 12.368 8.365L12.3829 0C12.3829 0 9.496 0.315 5.69371 1.743C1.368 3.36933 0 4.43217 0 4.43217C0.179429 8.2285 3.43543 11.6037 8.56457 13.9312C3.432 16.2575 0.176 19.6245 0 23.4302C0 23.4302 1.368 24.4965 5.69371 26.1205C9.49486 27.5438 12.3623 27.8623 12.3623 27.8623L12.3486 19.4973C12.3486 18.319 12.6651 17.2433 13.544 16.0195C16.7726 17.2188 19.8229 17.9562 23.16 17.9562C26.496 17.9562 29.5486 17.2188 32.7771 16.0195C33.6594 17.2398 33.9726 18.319 33.9726 19.4962L33.9611 27.8623C33.9611 27.8623 36.8263 27.5462 40.6263 26.1205C44.9543 24.4942 46.3234 23.4302 46.3234 23.4302C46.144 19.6327 42.8869 16.2575 37.7589 13.9312C42.8903 11.6037 46.1474 8.23783 46.3234 4.43217L46.3429 4.43333ZM47.8686 22.4175C47.8686 22.617 47.752 22.7967 47.5714 22.8737C47.392 22.9507 47.1851 22.9087 47.0457 22.7687C46.9086 22.6287 46.8663 22.4163 46.9406 22.232C47.016 22.0477 47.192 21.9287 47.3863 21.9287C47.5143 21.9275 47.6377 21.9788 47.7269 22.071C47.8183 22.1632 47.8686 22.288 47.8686 22.4175ZM47.9703 22.4175C47.9703 22.1772 47.8297 21.959 47.6114 21.8657C47.3943 21.7723 47.1429 21.8237 46.9749 21.994C46.8069 22.1643 46.7577 22.421 46.8469 22.6438C46.9383 22.8655 47.1509 23.0113 47.3863 23.0113C47.7086 23.0113 47.9691 22.7465 47.9691 22.4175"
                                  fill="#0C0D0D"
                                />

                            </svg>
                    </div>
                ) }
                    <div block="FieldForm" elem="Section">
                    <legend
                      block="FieldForm"
                      elem="Legend"
                    >
                        { __('Athlete Profile') }
                    </legend>
                    { !isMobile

                    && (
                        <div block="Information" elem="Svg">
                            <svg
                              width="43"
                              height="24"
                              viewBox="0 0 48 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                  opacity="0.9"
                                  d="M30.808 13.9312C30.6343 14.0618 30.4297 14.2112 30.1794 14.3908C28.5886 15.5435 26.2743 16.3333 23.2686 16.3333H23.0686C20.0629 16.3333 17.7497 15.5447 16.1554 14.392C15.9086 14.2135 15.704 14.063 15.5303 13.9323C15.704 13.8005 15.9086 13.65 16.1554 13.4715C17.7497 12.32 20.064 11.529 23.0697 11.529H23.2686C26.2754 11.529 28.5897 12.32 30.1817 13.4715C30.4309 13.65 30.6354 13.8005 30.8091 13.9323L30.808 13.9312ZM46.3429 4.43333C46.3429 4.43333 44.9749 3.36583 40.6469 1.74417C36.8457 0.319667 33.9806 0 33.9806 0L33.992 8.365C33.992 9.54217 33.6777 10.6167 32.7977 11.8428C29.5691 10.6412 26.5154 9.90383 23.1794 9.90383C19.8423 9.90383 16.792 10.6412 13.5634 11.8428C12.6811 10.6237 12.368 9.54217 12.368 8.365L12.3829 0C12.3829 0 9.496 0.315 5.69371 1.743C1.368 3.36933 0 4.43217 0 4.43217C0.179429 8.2285 3.43543 11.6037 8.56457 13.9312C3.432 16.2575 0.176 19.6245 0 23.4302C0 23.4302 1.368 24.4965 5.69371 26.1205C9.49486 27.5438 12.3623 27.8623 12.3623 27.8623L12.3486 19.4973C12.3486 18.319 12.6651 17.2433 13.544 16.0195C16.7726 17.2188 19.8229 17.9562 23.16 17.9562C26.496 17.9562 29.5486 17.2188 32.7771 16.0195C33.6594 17.2398 33.9726 18.319 33.9726 19.4962L33.9611 27.8623C33.9611 27.8623 36.8263 27.5462 40.6263 26.1205C44.9543 24.4942 46.3234 23.4302 46.3234 23.4302C46.144 19.6327 42.8869 16.2575 37.7589 13.9312C42.8903 11.6037 46.1474 8.23783 46.3234 4.43217L46.3429 4.43333ZM47.8686 22.4175C47.8686 22.617 47.752 22.7967 47.5714 22.8737C47.392 22.9507 47.1851 22.9087 47.0457 22.7687C46.9086 22.6287 46.8663 22.4163 46.9406 22.232C47.016 22.0477 47.192 21.9287 47.3863 21.9287C47.5143 21.9275 47.6377 21.9788 47.7269 22.071C47.8183 22.1632 47.8686 22.288 47.8686 22.4175ZM47.9703 22.4175C47.9703 22.1772 47.8297 21.959 47.6114 21.8657C47.3943 21.7723 47.1429 21.8237 46.9749 21.994C46.8069 22.1643 46.7577 22.421 46.8469 22.6438C46.9383 22.8655 47.1509 23.0113 47.3863 23.0113C47.7086 23.0113 47.9691 22.7465 47.9691 22.4175"
                                  fill="#0C0D0D"
                                />

                            </svg>
                        </div>
                    ) }
                    <div block="Information" elem="Customer">
                    <h3 block="Information" elem="Title">Information</h3>
                        { this.customerInformationFieldMap.map(this.renderSection) }
                        { this.renderEmailAndPasswordFields() }
                    </div>
                    </div>
                    { /* <div
                      block="FieldForm"
                      elem="Section"
                      mix={ { block: 'FieldForm', elem: 'SectionWithSpace' } }
                    >
                        { this.renderEmailAndPasswordFields() }
                    </div> */ }
                    { showEmailChangeField
                    && (
                        <div block="ChangeEmailBtn">
                            <button onClick={ this.handleEmailRef }>Cancel Change Email</button>
                        </div>
                    ) }
                    { showPasswordChangeField
                    && <div block="ChangePassBtn"><button onClick={ this.handlePasswordRef }>Cancel Change Password</button></div> }
                    <div
                      block="FieldForm"
                      elem="Section"
                      mix={ { block: 'FieldForm', elem: 'SectionWithSpace' } }
                    >
                        { this.renderPreferences() }
                    </div>
                </div>
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountCustomerFormComponent;
