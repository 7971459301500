import {
    MyAccountForgotPasswordSuccess as SourceMyAccountForgotPasswordSuccess
} from 'SourceComponent/MyAccountForgotPasswordSuccess/MyAccountForgotPasswordSuccess.component';

import './MyAccountForgotPasswordSuccess.override.style';

/** @namespace myApp/Component/MyAccountForgotPasswordSuccess/Component */
export class MyAccountForgotPasswordSuccessComponent extends SourceMyAccountForgotPasswordSuccess {
    render() {
        const { state, handleSignIn } = this.props;

        return (
            <article
              aria-labelledby="forgot-password-success"
              block="MyAccountOverlay"
              elem="Additional"
              mods={ { state } }
            >
                <h3 className="Popup-Heading">Password Reset Sent!</h3>
                <p id="forgot-password-success">
                    { /* eslint-disable-next-line max-len */ }
                    { __('If there is an account associated with the provided address you will receive an email with a link to reset your password') }
                </p>
                <button
                  block="Button"
                  onClick={ handleSignIn }
                >
                    { __('Return to Login') }
                </button>
            </article>
        );
    }
}

export default MyAccountForgotPasswordSuccessComponent;
