import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountTabListContainer as SourceMyAccountTabListContainer
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.container';

/** @namespace myApp/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountTabList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends SourceMyAccountTabListContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
