/* eslint-disable max-len */
// import { Field } from 'Util/Query';

// export const _genderField = () => {
//     const gender = new Field('gender')
//         .addArgument('fields', '[String]', 'gender')
//         .setAlias('gender');

//     return gender;
// };

// export const _sportField = () => {
//     const sport = new Field('sport')
//         .addArgument('fields', '[String]', 'sport')
//         .setAlias('sport');

//     return sport;
// };

// export const _producttypefilterField = () => {
//     const product_type_filter = new Field('product_type_filter')
//         .addArgument('fields', '[String]', 'product_type_filter')
//         .setAlias('product_type_filter');

//     return product_type_filter;
// };

// export const _productcategoryField = () => {
//     const product_type_filter = new Field('product_category')
//         .addArgument('fields', '[String]', 'product_category')
//         .setAlias('product_category');

//     return product_type_filter;
// };

// export const _collectionnameField = () => {
//     const product_type_filter = new Field('collection_name')
//         .addArgument('fields', '[String]', 'collection_name')
//         .setAlias('collection_name');

//     return product_type_filter;
// };

function _getCartProductInterfaceFields(args, callback, instance) {
    return [
        'uid',
        'id',
        'sku',
        'name',
        'type_id',
        'stock_status',
        'url',
        'salable_qty',
        'pdp_content',
        'size_chart_guide',
        instance._getStockItemField(),
        instance._getPriceRangeField(),
        instance._getProductThumbnailField(),
        instance._getCartConfigurableProductFragment(),
        instance._getAttributesField(false, true),
        instance._getProductLinksField()
    ];
}

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getCartProductInterfaceFields
        }
    }
};
