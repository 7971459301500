/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

/** @namespace Ambab/Webengage/Helper/Product/Index/getProductImg */
export const getProductImg = (imgObj) => Object.values(imgObj).map(({ base: { url } }) => url);

/** @namespace Ambab/Webengage/Helper/Product/Index/getSelectedAttributeAfterAddToCart */
export const getSelectedAttributeAfterAddToCart = (attributes, parameters) => Object.values(attributes)
    .map(({ attribute_code, attribute_options }) => {
        const selectedOption = parameters[attribute_code];
        const selectedOptionLabel = selectedOption
            ? { [attribute_code]: attribute_options[selectedOption]?.label } : '';

        return (attribute_code === 'size' || 'color') && selectedOptionLabel;
    }).filter((attribute) => attribute !== '');

/** @namespace Ambab/Webengage/Helper/Product/Index/getAttribute */
export const getAttribute = (allAttribsWithValues, attribute_key) => Object.entries(allAttribsWithValues)
    .reduce((acc, [key, val]) => {
        const {
            attribute_value, attribute_code, attribute_options
        } = val;

        if (attribute_code === attribute_key) {
            return { label: attribute_options[attribute_value].label };
        }

        return acc;
    });

/** @namespace Ambab/Webengage/Helper/Product/Index/getProductColorSize */
export const getProductColorSize = (items, product) => {
    // const cartProduct = Object.entries(items).reduce((acc, [key, val]) => {
    //     if (val.product.id === product.id) {
    //         return { product: val.product.variants[0] };
    //     }

    //     return acc;
    // });
    const [cartProduct] = Object.values(items).filter((i) => i.product.id === product.id);

    const { attributes: cartAttr } = cartProduct.product.variants[0];

    const COLOR = 'color';
    const SIZE = 'size';

    const { attribute_value: color_value } = cartAttr[COLOR];
    const { attribute_value: size_value } = cartAttr[SIZE];

    const {
        [COLOR]: {
            attribute_options: {
                [color_value]: { label: color }
            }
        }, [SIZE]: {
            attribute_options: {
                [size_value]: { label: size }
            }
        }
    } = product.attributes;

    return { color, size };
};

/** @namespace Ambab/Webengage/Helper/Product/Index/getCartProductColorSize */
export const getCartProductColorSize = (item) => {
    const { attributes: cartAttr } = item.product.variants[0];

    const COLOR = 'color';
    const SIZE = 'size';

    const { attribute_value: color_value } = cartAttr[COLOR];
    const { attribute_value: size_value } = cartAttr[SIZE];

    const {
        [COLOR]: {
            attribute_options: {
                [color_value]: { label: color }
            }
        }, [SIZE]: {
            attribute_options: {
                [size_value]: { label: size }
            }
        }
    } = item.product.attributes;

    return { color, size };
};

/** @namespace Ambab/Webengage/Helper/Product/Index/getWishlistProductColorSize */
export const getWishlistProductColorSize = (product) => {
    const { attributes: cartAttr } = product.variants[0];

    const COLOR = 'color';
    const SIZE = 'size';

    const { attribute_value: color_value } = cartAttr[COLOR];
    const { attribute_value: size_value } = cartAttr[SIZE];

    const {
        [COLOR]: {
            attribute_options: {
                [color_value]: { label: color }
            }
        }, [SIZE]: {
            attribute_options: {
                [size_value]: { label: size }
            }
        }
    } = product.attributes;

    return { color, size };
};
