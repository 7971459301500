import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace myApp/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Wrapper">
                <ul className="b-loader">
                    <li className="b-loader-icon" />
                    <li className="b-loader-icon" />
                    <li className="b-loader-icon" />
                    { /* <li className="b-loader-hidden_text">Loading</li> */ }
                </ul>
            </div>
        );
    }
}

export default LoaderComponent;
